import { Grid } from "@material-ui/core";
import TitleDiv from "components/Common/TitleDiv";
import "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ErrorHandler, ProjectApi } from "system/ApiService";
import { MainProjectAtom, SubProjectAtom, TranslateProjectAtom } from "system/atom";
import { MainProject, SubProject } from "system/types";
import TranslatorComponent from "./TranslatorComponent";
import TranslatorManualComponent from "./TranslatorManualComponent";
import TranslatorVoiceComponent from "./TranslatorVoiceComponent";

interface Params {
  translate_project_code: string;
}

interface TranslatorProjectComponentProps {
  _sub?: SubProject;
  _main?: MainProject;
  _finished?: Boolean;
}

export function TranslatorProjectComponent({ _sub, _main, _finished }: TranslatorProjectComponentProps) {
  const [translateProject, setTranslateProject] = useRecoilState(TranslateProjectAtom);

  const location = useLocation();
  const state: any = location.state;
  const [type, setType] = useState<String>(state ? state.type : _sub?.project_type);
  const closed = state ? state.closed : translateProject?.is_closed;

  const [mainProject, setMainProject] = useRecoilState(MainProjectAtom);
  const [subProject, setSubProject] = useRecoilState(SubProjectAtom);
  const { t } = useTranslation();

  // useEffect(() => {
  //   ProjectApi.GetTranslateProjectDetail(translate_project_code)
  //     .then((resTrans) => {
  //       setTranslateProject(resTrans.data);
  //       console.log(resTrans, "resTrans");
  //       ProjectApi.GetSubProjectDetail(resTrans.sub_project_id + "").then((resSub) => {
  //         setSubProject(resSub.data);
  //         console.log(resSub.data, "resSub");
  //       });
  //     })
  //     .catch((e) => {
  //       let msg = ErrorHandler(e);
  //       console.log(msg);
  //     });
  // }, []);
  // useEffect(() => {
  //   ProjectApi.GetSubProjectDetail(translateProject.sub_project_id + "")
  //     .then((resSub?) => {
  //       setSubProject(resSub.data);
  //       setType(resSub.project_type);
  //       console.log(resSub.project_type, "type");
  //       console.log(resSub.data, "resSub");
  //     })
  //     .catch((e) => {
  //       let msg = ErrorHandler(e);
  //       console.log(msg);
  //     });
  // }, [translateProject]);

  useEffect(() => {
    // setMainProject(_main ? _main : mainProject);
    // setSubProject(_sub ? _sub : subProject);

    ProjectApi.GetSubProjectDetail(translateProject.sub_project_id + "")
      .then((res) => {
        setSubProject(res.data);
        if (res.data.main_project_id) {
          ProjectApi.GetMainProjectDetail(res.data.main_project_id + "")
            .then((subRes) => {
              setMainProject(subRes.data);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, [translateProject]);

  function TranslatorProject() {
    switch (subProject?.project_type) {
      case 1:
      case 4:
        return <TranslatorManualComponent></TranslatorManualComponent>;
      case 5:
        if (closed) {
          return <></>;
        } else {
          return <TranslatorVoiceComponent _trans={translateProject}></TranslatorVoiceComponent>;
        }
      default:
        return <TranslatorComponent type={type} _trans={translateProject}></TranslatorComponent>;
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        {_finished ? (
          <></>
        ) : (
          <>
            <Grid item xs={12} container>
              <TitleDiv title={"" + t("assignment")}></TitleDiv>
            </Grid>
            <Grid item xs={12} container>
              <div>
                <b>{mainProject && mainProject.project_name ? mainProject.project_name : "Project"}</b> ＞{" "}
                <b>{subProject && subProject.project_name ? subProject.project_name : "Category"}</b> ＞{" "}
                <b>
                  {translateProject && translateProject.target_lang_code ? translateProject.target_lang_code : "LANG"}
                </b>
              </div>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {TranslatorProject()}
        </Grid>
      </Grid>
    </>
  );
}
export default TranslatorProjectComponent;
