import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { CancelButton } from "components/Common/Button";
import TitleDiv from "components/Common/TitleDiv";
import { useEffect } from "react";
import {
  INIT_MATERIALTABLEOPTIONS,
  INIT_SUB_PROJECT,
  MainProject,
  MaterialTableOptions,
  SubProject,
} from "system/types";
import SubProjectTable from "./SubProjectTable";
import SubProjectTemplate from "./SubProjectTemplate";

interface SubProjectSelectDialogProps {
  type: string;
  open: boolean;
  main: MainProject;
  _sub: SubProject;
  onRowClickSubProject: (param: SubProject) => void;
  onClose: () => void;
  saveCallback: () => void;
}
function SubProjectDialog({
  type,
  open,
  main,
  _sub,
  onRowClickSubProject,
  onClose,
  saveCallback,
}: SubProjectSelectDialogProps) {
  let options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    onRowClick: onRowClickSubProject,
  };

  useEffect(() => {}, []);

  const getDialogContent = (type: string) => {
    switch (type) {
      case "select":
        options = { ...options, select: true };
        return (
          <>
            <SubProjectTable options={options}></SubProjectTable>
            <DialogActions>
              <CancelButton onClick={onClose}>CANCEL</CancelButton>
            </DialogActions>
          </>
        );
      case "edit":
        return (
          <SubProjectTemplate
            onClose={onClose}
            saveCallback={saveCallback}
            _main={main}
            _sub={_sub}
            templateType={"edit"}
          ></SubProjectTemplate>
        );
      case "add":
        return (
          <SubProjectTemplate
            onClose={onClose}
            saveCallback={saveCallback}
            _main={main}
            _sub={INIT_SUB_PROJECT}
            templateType={"add"}
          ></SubProjectTemplate>
        );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          {type === "select" ? <TitleDiv title={`카테고리 과제 선택`}></TitleDiv> : <></>}
          {type === "add" ? <TitleDiv title={`카테고리 과제 추가`}></TitleDiv> : <></>}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>{getDialogContent(type)}</DialogContent>
      </Dialog>
    </>
  );
}

export default SubProjectDialog;
