import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Product } from "system/types";
import { createStyles, Grid, makeStyles, TextField, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    input: {
      width: "100%",
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    button: {
      width: "100%",
      padding: theme.spacing(1.5),
      fontSize: "1.0rem",
    },
  })
);

interface BaseProductDialogTemplateProps {
  data: Product;
  add?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function BaseProductDialogTemplate({ data: product, add, onChange }: BaseProductDialogTemplateProps): ReactElement {
  const [state, setState] = useState<Product>(product);
  const classes = useStyles();

  const onChangeText = function (event: ChangeEvent<HTMLInputElement>) {
    onChange(event);
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {}, []);

  return (
    <>
      {product ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="product_code"
              name="product_code"
              label="Code"
              value={product.product_code || ""}
              className={classes.input}
              onChange={onChangeText}
              inputProps={{
                title: "product code",
              }}
              disabled={!add}
            ></TextField>
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              id="product_name"
              name="product_name"
              label="Name"
              value={product.product_name || ""}
              className={classes.bottom}
              onChange={onChangeText}
              inputProps={{
                title: "product_name",
              }}
            ></TextField>
          </Grid> */}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default BaseProductDialogTemplate;
