import { createStyles, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Baselanguage } from "system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    input: {
      width: "100%",
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    button: {
      width: "100%",
      padding: theme.spacing(1.5),
      fontSize: "1.0rem",
    },
  })
);

interface BaseLanguageDialogTemplateProps {
  data: Baselanguage;
  add?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function BaseLanguageDialogTemplate({
  data: baselanguage,
  add,
  onChange,
}: BaseLanguageDialogTemplateProps): ReactElement {
  const [state, setState] = useState<Baselanguage>(baselanguage);
  const classes = useStyles();

  const onChangeText = function (event: ChangeEvent<HTMLInputElement>) {
    onChange(event);
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {}, [baselanguage]);

  return (
    <>
      {baselanguage ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="lang_code"
              name="lang_code"
              label="Language Code (ex. KO, JA, EN, etc...)"
              value={baselanguage.lang_code || ""}
              className={classes.input}
              onChange={onChangeText}
              inputProps={{
                title: "lang code",
              }}
              disabled={!add}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="lang_name_english"
              name="lang_name_english"
              label="English Name"
              value={baselanguage.lang_name_english || ""}
              className={classes.input}
              onChange={onChangeText}
              inputProps={{
                title: "lang name english",
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="lang_name_korean"
              name="lang_name_korean"
              label="Korean Name"
              value={baselanguage.lang_name_korean || ""}
              className={classes.input}
              onChange={onChangeText}
              inputProps={{
                title: "lang_name_korean",
              }}
            ></TextField>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default BaseLanguageDialogTemplate;
