/* eslint-disable array-callback-return */
import { createStyles, Grid, makeStyles, MenuItem, Theme } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { CancelButton, SaveButton } from "components/Common/Button";
import SimpleSelect from "components/Common/Custom/SimpleSelect";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { BaseInfoApi, ErrorHandler, GroupApi, ProjectApi } from "system/ApiService";
import { Baselanguage, Group, SubProject, TemplateType, TranslateProject } from "system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    bottom: {
      width: "100%",
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    button: {
      width: "100%",
      padding: theme.spacing(1.5),
      fontSize: "1.0rem",
    },
    hidden: {
      display: "none",
    },
  })
);

interface TranslateProjectProps {
  _sub: SubProject;
  _setSub: any;
  _translate: TranslateProject;
  templateType: TemplateType;
  onClose?: () => void;
  saveCallback?: () => void;
}

function TranslateProjectTemplate({
  _sub,
  _setSub,
  _translate,
  templateType,
  onClose,
  saveCallback,
}: TranslateProjectProps) {
  const classes = useStyles();
  const [translate, setTranslate] = useState<TranslateProject>(_translate);
  const [groupList, setGroupList] = useState<Group[]>([]);
  const [baseLanguageList, setBaseLanguageList] = useState<Baselanguage[]>([]);

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaseLanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });

    GroupApi.GetGroupList()
      .then((res) => {
        let translateGroupListData = res.data.filter((item: Group) => {
          return item.is_admin === 0;
        });
        setGroupList(translateGroupListData);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  // 숫자로 받아야하는 데이터에 대해서 분기해주기!
  const onChange = (args: any) => {
    var name = (args.target as HTMLInputElement).name;
    var value = args.target.value;
    if (name === "translator_group_id") {
      value = parseInt(value);
    }
    setTranslate({ ...translate, [name]: value });
  };

  const onChangeDate = (date: MaterialUiPickersDate) => {
    if (date) {
      let year = date.toDate().getFullYear();
      let month = date.toDate().getMonth() + 1;
      let day = date.toDate().getDate();
      let dateString = year + "." + (month < 10 ? "0" + month : month) + "." + (day < 10 ? "0" + day : day);
      setTranslate({ ...translate, project_duedate: dateString });
    }
  };

  const onLoad = React.useCallback(() => {
    if (_sub.id) {
      setTranslate({ ...translate, sub_project_id: _sub.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_sub.id]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onSave = () => {
    let flag = false;

    if (
      translate.source_lang_code !== "" &&
      translate.target_lang_code !== "" &&
      translate.project_duedate !== "" &&
      translate.translator_group_id !== 0 &&
      translate.is_chatgpt_only !== null
    ) {
      flag = true;
    }
    if (translate.project_duedate === "") {
      onChangeDate(moment());
      flag = true;
    }

    if (flag && translate.sub_project_id !== 0) {
      if (translate.id === 0) {
        ProjectApi.AddTranslateProject(translate)
          .then((res) => {
            saveCallback && saveCallback();
            onClose && onClose();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
          });
      } else {
        ProjectApi.UpdateTranslateProject(translate)
          .then((res) => {
            saveCallback && saveCallback();
            onClose && onClose();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
          });
      }
    } else {
      alert("모든 값을 입력해주세요.");
    }
  };

  return (
    <>
      {templateType === "show" ? (
        <>
          <h5>
            <b>Translate Project Info</b>
          </h5>
          <h6>blabla</h6>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              id="project_duedate"
              name="project_duedate"
              format="yyyy.MM.DD"
              label="번역 과제 마감기한 입력"
              value={translate.project_duedate}
              onChange={onChangeDate}
              className={classes.bottom}
            />
          </Grid>
          <Grid className="text-right" item xs={12}>
            <CancelButton onClick={onClose}>CANCEL</CancelButton>
            <SaveButton onClick={onSave}>SAVE</SaveButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TranslateProjectTemplate;
