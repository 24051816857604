/*

LCD, 결과지의 큰 페이지별로 뿌리는 Data들이 담기는 Component
todo : 추후에 User Manual도 추가시켜줄 필요성이 있음

*/

import {
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import "date-fns";
import React, { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { ErrorHandler, ProjectApi } from "system/ApiService";
import { ImageText, MainProject, SubProject, TranslateProject } from "system/types";

import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { SubProjectAtom } from "system/atom";
import { useLoadingDispatch } from "system/LoadingContext";
import { getTitleByField } from "system/util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputClass: {
      marginTop: "10px",
      marginLeft: "15%",
    },
    imageSize: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      // maxHeight: "70vh",
      height: "100%",
      "& > *": {
        backgroundColor: "#DCDFE3",
        heigth: "auto",
      },
    },
    imageSizeIndd: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      maxHeight: "70vh",
      "& > *": {
        backgroundColor: "#DCDFE3",
        heigth: "auto",
      },
    },
    pictureLeft: {
      width: "100%",
    },
    pictureRight: {
      overflowY: "auto",
      // maxHeight: "70vh",
      padding: theme.spacing(1),
    },
    textArea: {
      width: "100%",
    },
    info: {
      marginTop: "10%",
      marginBottom: "10%",
      textAlign: "center",
    },
    gridBorder: {
      border: "1px solid black",
    },

    toolBar: {
      top: "40%",
      position: "sticky",
    },
  })
);

interface TranslateProjectProps {
  _main: MainProject;
  _sub: SubProject;
  _translate?: TranslateProject;
}

interface Size {
  width: number;
  height: number;
}
export function TranslateComponent({ _main, _sub }: TranslateProjectProps): ReactElement {
  const classes = useStyles();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [pageNum, setPageNum] = useState<number>(1);
  const [wholePageNum, setWholePageNum] = useState<number>(0);
  // 관리자의 원문 리소스를 언어별로 조회시, 해당 언어정보 저장하는 state
  const [sourceLang, setSourceLang] = useState<string>(_sub.source_lang_code);

  // todo: subPage 및 ImageInfo 배열로 받는 경우 확인
  const [subPageNum, setSubPageNum] = useState<number>(1);
  const [imageTextList, setImageTextList] = useState<ImageText[]>([]);
  const [sector_image, setSector_image] = useState<string>("");

  const [wrapperSize, setWrapperSize] = useState<Size>({ width: 0, height: 0 });

  const [imageSize, setImageSize] = useState<Size>({ width: 0, height: 0 });

  const [subProject] = useRecoilState<SubProject>(SubProjectAtom);

  const { t } = useTranslation();

  const loading = useLoadingDispatch();

  const onLoad = React.useCallback(
    (page: number) => {
      if (!_sub) return;
      if (!_sub.id) return;
      loading({ type: "LOADING" });

      const api = subProject.project_type === 2 ? ProjectApi.GetListSourceForPhotoshop : ProjectApi.GetListSource;

      api(subProject.id, sourceLang, page)
        .then((res) => {
          res.data.a_image_and_multi_texts.sort(function (prev: ImageText, next: ImageText) {
            return prev.dir_order_no - next.dir_order_no;
          });
          setImageTextList(res.data.a_image_and_multi_texts);

          if (res.data.sector_image) {
            // 구역 이미지 취득시도 (관리자 LCD/결과지 원본 조회 화면)
            setSector_image(res.data.sector_image);
          }
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(e, msg);
        })
        .finally(() => loading({ type: "COMPLETE" }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageNum, sourceLang]
  );

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        setWrapperSize({
          width: wrapperRef.current.offsetWidth,
          height: wrapperRef.current.offsetHeight,
        });
      }
    };
    console.log(wrapperSize.width, wrapperSize.height);
    // 초기 렌더링 후에 크기를 계산합니다.
    window.setTimeout(handleResize, 0);

    // 창 크기가 변경될 때마다 크기를 다시 계산합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [wrapperRef]);

  useMemo(() => {
    if (_sub.id) {
      ProjectApi.getMaxSourcePageNumber(_sub.id + "", sourceLang)
        .then((res) => {
          setWholePageNum(res.data.max_page_no);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  }, [_sub.id, onLoad]);

  useEffect(() => {
    onLoad(pageNum);
  }, [onLoad, pageNum]);

  const onChangeLang = (e: any) => {
    setSourceLang(e.target.value);
  };

  const onPageNumChangePagination = (event: any, page: number) => {
    setPageNum(page);
    onLoad(page);
  };

  const onSubPageNumChangePagination = (event: any, page: number) => {
    setSubPageNum(page);
  };

  const onClickAreaImage = (event: any) => {
    let imageUrl = event.target.src;
    if (imageUrl) {
      // Base64 데이터를 Blob으로 변환
      fetch(imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const newImageUrl = URL.createObjectURL(blob);
          window.open(newImageUrl, "_blank");
        })
        .catch((error) => {
          console.error("Error converting image to Blob:", error);
        });
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          {/* <MainProjectTemplate templateType="show" main={_main}></MainProjectTemplate>
          <SubProjectTemplate templateType="show" _main={_main} _sub={_sub}></SubProjectTemplate> */}
          <FormControl>
            <Select value={sourceLang} onChange={onChangeLang} displayEmpty>
              <MenuItem value={sourceLang}>{getTitleByField(sourceLang)}</MenuItem>
              {/* <MenuItem value={"KO"}>Korea</MenuItem>
              <MenuItem value={"EN_EN"}>English(Common)</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} container justifyContent="space-around">
          <Pagination
            style={{ marginBottom: "5px" }}
            count={wholePageNum}
            defaultValue={1}
            page={pageNum}
            showFirstButton
            showLastButton
            size={"large"}
            color="primary"
            onChange={onPageNumChangePagination}
          ></Pagination>
        </Grid>
        {/* <Grid item xs={12} container justifyContent="space-around">
          <Pagination
            style={{ marginBottom: "10px" }}
            count={wholeSubPageNum}
            defaultValue={1}
            page={subPageNum}
            showFirstButton
            showLastButton
            onChange={onSubPageNumChangePagination}
            size={"small"}
            variant={"outlined"}
            shape={"rounded"}
          ></Pagination>
        </Grid> */}
      </Grid>
      <Grid item xs={12} container justifyContent="space-around">
        {_sub.project_type === 2 ? (
          <Grid item xs={2}>
            <div className={classes.toolBar}>
              {sector_image ? (
                <button onClick={onClickAreaImage}>
                  <img
                    src={"data:image/png;base64," + sector_image}
                    width="200px"
                    height="320px"
                    max-height="600px"
                    id="click_image"
                    alt="No_Image"
                  ></img>
                </button>
              ) : (
                <button style={{ cursor: "default" }}>
                  <img
                    src={"/no-sector-image.png"}
                    width="200px"
                    height="320px"
                    max-height="600px"
                    id="click_image"
                    alt="No_Image"
                  ></img>
                </button>
              )}
            </div>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={_sub.project_type === 2 ? 10 : 12} container justifyContent="space-around">
          {/* 이전 Image 불러오던 API */}
          {/* {imageTextList.slice((subPageNum - 1) * 10, subPageNum * 10).map((item: ImageText, index: number) => { */}
          {imageTextList.map((item: ImageText, index: number) => {
            // 표시하려는 image가 Png인지 여부 check
            let isPng = false;
            if (item) {
              isPng = atob(item.encoded_image_string).slice(0, 5).indexOf("PNG") >= 0;
            }
            return (
              <>
                <Grid
                  key={index}
                  item
                  container
                  xs={12}
                  className={classes.gridBorder + " " + classes.pictureLeft}
                  justifyContent="space-around"
                >
                  <Grid item xs={7} className={(index - (index % 10)) / 10 + ""}>
                    {/* <Grid item xs={7} className={(index - (index % 10)) / 10 + ""}> */}
                    {item && (
                      <>
                        <div
                          className={
                            _sub.project_type === 1 || _sub.project_type === 4
                              ? classes.imageSizeIndd
                              : classes.imageSize
                          }
                          // style={{ height: "40vh" }}
                        >
                          <TransformWrapper
                            centerOnInit={true}
                            initialScale={0.3}
                            minScale={0.3}
                            maxScale={1.5}
                            limitToBounds={false}
                            // doubleClick={{ mode: "reset" }}
                            initialPositionX={(wrapperSize.width - imageSize.width * 2.5) / 2}
                            initialPositionY={(wrapperSize.height - imageSize.height * 2.5) / 2}
                          >
                            <TransformComponent>
                              {/* todo: 현재 Png와 Svg 형식만 존재하므로 boolean값으로 구분 */}
                              {isPng ? (
                                <>
                                  <img src={"data:image/png;base64," + item.encoded_image_string} alt={"image"}></img>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={"data:image/svg+xml;base64," + item.encoded_image_string}
                                    alt={"image"}
                                  ></img>
                                </>
                              )}
                            </TransformComponent>
                          </TransformWrapper>
                        </div>
                      </>
                    )}
                    {!item && <div>Image loading..</div>}
                  </Grid>
                  <Grid item xs={5} className={(index - (index % 10)) / 10 + " " + classes.pictureRight}>
                    {/* // className={classes.pictureRight}> */}
                    {item.image_text_data.map((data, index) => (
                      <>
                        <div>
                          <TextareaAutosize
                            id="source-text"
                            name="sourceText"
                            value={data.source_sentence}
                            className={classes.textArea}
                            required
                            disabled
                          ></TextareaAutosize>
                        </div>
                        <br />
                      </>
                    ))}
                    {item.image_text_data.length === 0 ? (
                      <>
                        <div className={classes.info}>
                          <Typography variant="h6">{"<" + t("guide-no-sentence") + ">"}</Typography>
                          <Typography variant="h6">{t("guide-go-next")}</Typography>
                          {/* 한글 문장으로 일단 표시 */}
                          {/* <Typography variant="h5">
                            {"<"}The blank page with no extracted text{">"}
                          </Typography>
                          <Typography variant="h6">Please go to the next page!</Typography> */}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <Grid xs={12}></Grid>
                <br />
              </>
            );
          })}
          {imageTextList && imageTextList.length == 0 ? (
            <div className={classes.info}>
              <Typography variant="h5">
                The resources for the corresponding source language have not been uploaded.
              </Typography>
              <br />
              <Typography variant="h5">Please upload the resources or change the source language.</Typography>
            </div>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default TranslateComponent;
