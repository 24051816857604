import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { CancelButton } from "components/Common/Button";
import TitleDiv from "components/Common/TitleDiv";
import { INIT_MATERIALTABLEOPTIONS, MaterialTableOptions, SubProject, TranslateProject } from "system/types";
import TranslateProjectTable from "./TranslateProjectTable";
import TranslateProjectTemplate from "./TranslateProjectTemplate";

interface TranslateProjectSelectDialogProps {
  sub: SubProject;
  setSub: any;
  _translate: TranslateProject;
  type: string;
  open: boolean;
  onRowClickTranslateProject: (param: TranslateProject) => void;
  onClose: () => void;
  saveCallback: () => void;
}
function TranslateProjectDialog({
  type,
  open,
  sub,
  setSub,
  _translate,
  onRowClickTranslateProject,
  onClose,
  saveCallback,
}: TranslateProjectSelectDialogProps) {
  let options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    onRowClick: onRowClickTranslateProject,
  };

  const getDialogContent = (type: string) => {
    switch (type) {
      case "select":
        options = { ...options, select: true };
        return (
          <>
            <TranslateProjectTable options={options} _sub={sub} _setSub={setSub}></TranslateProjectTable>
            <DialogActions>
              <CancelButton onClick={onClose}>CANCEL</CancelButton>
            </DialogActions>
          </>
        );
      case "edit":
        return (
          <>
            <TranslateProjectTemplate
              onClose={onClose}
              saveCallback={saveCallback}
              _sub={sub}
              _setSub={setSub}
              _translate={_translate}
              templateType={"edit"}
            ></TranslateProjectTemplate>
          </>
        );
      case "add":
        return (
          <>
            <TranslateProjectTemplate
              onClose={onClose}
              saveCallback={saveCallback}
              _sub={sub}
              _setSub={setSub}
              _translate={_translate}
              templateType={"add"}
            ></TranslateProjectTemplate>
          </>
        );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          {type === "select" ? <TitleDiv title={`번역가 선택`}></TitleDiv> : <></>}
          {type === "add" ? <TitleDiv title={`번역가 추가`}></TitleDiv> : <></>}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>{getDialogContent(type)}</DialogContent>
      </Dialog>
    </>
  );
}

export default TranslateProjectDialog;
