import { createStyles, IconButton, makeStyles, Theme, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { ActionButton } from "components/Common/Button";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorHandler, ProjectApi } from "system/ApiService";
import { PROJECT_TYPE, TABLEICONS } from "system/Constants";
import {
  INIT_MAIN_PROJECT,
  INIT_SUB_PROJECT,
  MainProject,
  MaterialTableOptions,
  ModalType,
  SubProject,
} from "system/types";
import SubProjectDialog from "./SubProjectDialog";

interface SubProjectTableProps {
  options: MaterialTableOptions;
  _main?: MainProject;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: "right",
    },
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
  })
);

function SubProjectTable({ options, _main }: SubProjectTableProps) {
  const classes = useStyles();
  const [data, setData] = useState<SubProject[]>([]);
  const [sub, setSub] = useState<SubProject>(INIT_SUB_PROJECT);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<ModalType>("edit");
  const { t } = useTranslation();

  const onLoad = React.useCallback(() => {
    let subProjectAPI;
    if (_main && _main.id) {
      subProjectAPI = ProjectApi.GetSubProjectListByMainProjectId(_main.id);
    } else {
      subProjectAPI = ProjectApi.GetSubProjectList();
    }
    subProjectAPI
      .then((res) => {
        setData(res.data.sort((a: SubProject, b: SubProject) => b.id - a.id));
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, [_main]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const addSubProject = () => {
    setType("add");
    setOpen(true);
  };

  const onDeleteSubProject = (id: number | undefined) => {
    if (id && id !== 0) {
      if (window.confirm("" + t("dialogue-confirm-delete"))) {
        ProjectApi.DeleteSubProject(id)
          .then(() => {
            onLoad();
          })
          .catch((e) => {
            let msg = ErrorHandler(e);
            console.log(msg);
          });
      }
    }
  };

  const onOpenModal = (sub: SubProject) => {
    setSub(sub);
    setType("edit");
    setOpen(true);
  };

  const onClose = () => {
    setSub(INIT_SUB_PROJECT);
    setOpen(false);
  };

  return (
    <div>
      <MaterialTable
        title=""
        icons={TABLEICONS}
        onRowClick={(event, rowData) => {
          if (!event || !rowData) return;
          let target = event.target + "";
          if (target.includes("HTMLSpanElement") && options.rowClick) {
            return;
          } else if (target.includes("HTMLTableCellElement") && options.rowClick) {
            if (rowData) {
              options.onRowClickSubProject(rowData);
            }
          } else if (!target.includes("SVG") && options.onRowClickSubProject) {
            options.onRowClickSubProject(rowData);
          }
        }}
        columns={[
          {
            title: "" + t("project-name"),
            field: "project_name",
            cellStyle: { width: "200px" },
            disableClick: !options.rowClick,
          },
          {
            title: "" + t("project-type"),
            field: "project_type",
            cellStyle: { width: "100px" },
            disableClick: !options.rowClick,
            lookup: PROJECT_TYPE,
          },
          {
            title: "" + t("number-of-target-language"),
            field: "target_lang_code",
            cellStyle: { width: "200px" },
            disableClick: !options.rowClick,
            render: (rowData) => {
              const langCodes = rowData.target_lang_code.join(", ");
              return (
                <Tooltip title={langCodes}>
                  <div>
                    {rowData.target_lang_code.length > 0 ? (
                      rowData.target_lang_code.length + " " + t("languages")
                    ) : (
                      <b>Empty</b>
                    )}
                  </div>
                </Tooltip>
              );
            },
          },
          {
            title: "",
            field: "",
            cellStyle: { width: "100px" },
            disableClick: !options.rowClick,
            render: (rowData) => {
              return rowData.is_closed ? (
                <></>
              ) : (
                <div className={classes.buttonGroup}>
                  <IconButton className={classes.iconButton} onClick={() => onOpenModal(rowData)}>
                    <CreateOutlinedIcon />
                  </IconButton>

                  <IconButton className={classes.iconButton} onClick={() => onDeleteSubProject(rowData.id)}>
                    <DeleteForeverOutlinedIcon />
                  </IconButton>
                </div>
              );
            },
          },
        ]}
        data={data || []}
        options={{
          filtering: true,
          pageSize: 10,
          tableLayout: "auto",
        }}
        actions={[
          {
            icon: "add",
            tooltip: "",
            isFreeAction: true,
            onClick: (event) => {},
          },
        ]}
        components={{
          Action: (props) =>
            options.add ? (
              <ActionButton onClick={addSubProject} variant="contained" style={{ marginLeft: "10px" }}>
                <AddIcon />
                {t("add-category")}
              </ActionButton>
            ) : (
              <></>
            ),
        }}
      />

      <SubProjectDialog
        main={_main || INIT_MAIN_PROJECT}
        _sub={sub || INIT_SUB_PROJECT}
        type={type}
        open={open}
        onRowClickSubProject={options.onRowClickSubProject}
        onClose={onClose}
        saveCallback={onLoad}
      ></SubProjectDialog>
    </div>
  );
}

export default SubProjectTable;
