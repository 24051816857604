import StepByStep from "components/Project/StepByStep/StepByStep";
import { ReactElement } from "react";

function ProjectManagementMain(): ReactElement {
  return (
    <div>
      <StepByStep></StepByStep>
    </div>
  );
}
export default ProjectManagementMain;
