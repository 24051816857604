import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { enableRipple } from "@syncfusion/ej2-base";
import ScrollToTop from "components/Common/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import Routes from "routes/Routes";
import "style/main.css";
import { theme } from "style/theme";
import { LoadingContextProvider } from "system/LoadingContext";
import { ToastContextProvider } from "system/ToastContext";
import { UserContextProvider } from "system/UserContext";
import { RecoilRoot } from "recoil";
enableRipple(true);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <UserContextProvider>
          <LoadingContextProvider>
            <ToastContextProvider>
              <BrowserRouter>
                <RecoilRoot>
                  <ScrollToTop />
                  <Routes></Routes>
                </RecoilRoot>
              </BrowserRouter>
            </ToastContextProvider>
          </LoadingContextProvider>
        </UserContextProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
