import { Button, ButtonProps } from "@material-ui/core";
import React, { ReactElement } from "react";

function ActionButton(props: ButtonProps): ReactElement {
  return (
    <Button color="primary" variant="text" {...props}>
      {props.children}
    </Button>
  );
}

export default ActionButton;
