import { createStyles, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { ActionButton } from "components/Common/Button";
import MaterialTable from "material-table";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseInfoApi, ErrorHandler } from "system/ApiService";
import { TABLEICONS } from "system/Constants";
import { ModalType, Product } from "system/types";
import BaseProductDialog from "./BaseProductDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: "right",
    },
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
  })
);

function BaseProductTable(): ReactElement {
  const classes = useStyles();
  const [data, setData] = useState<Product[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState<string>("");
  const [type, setType] = useState<ModalType>("edit");
  const { t } = useTranslation();

  const onLoad = React.useCallback(() => {
    BaseInfoApi.GetProductList()
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  const onDeleteProduct = (product_code: string) => {
    if (window.confirm("Are you sure to delete '" + product_code + "' ?")) {
      BaseInfoApi.DeleteProduct(product_code)
        .then((res) => {
          console.log(res);
          onLoad();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onCloseModal = () => setIsModalOpen(false);

  const goToAddProduct = () => {
    setId("");
    setType("add");
    setIsModalOpen(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <MaterialTable
              title=""
              icons={TABLEICONS}
              columns={[
                { title: "" + t("product-code"), field: "product_code", cellStyle: { width: "400px" } },
                {
                  title: "",
                  field: "",
                  cellStyle: { width: "200px" },
                  render: (rowData) => {
                    return (
                      <div className={classes.buttonGroup}>
                        <IconButton
                          className={classes.iconButton}
                          aria-label="delete-baselanguage"
                          onClick={() => onDeleteProduct(rowData.product_code)}
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      </div>
                    );
                  },
                },
              ]}
              data={data || []}
              actions={[
                {
                  icon: "add",
                  tooltip: "",
                  isFreeAction: true,
                  onClick: (event) => {},
                },
              ]}
              components={{
                Action: (props) => (
                  <ActionButton onClick={goToAddProduct} variant="contained" className={classes.button}>
                    <AddIcon />
                    {t("add-product")}
                  </ActionButton>
                ),
              }}
              options={{
                filtering: true,
                padding: "dense",
                pageSize: 10,
              }}
            />
            <BaseProductDialog
              open={isModalOpen}
              id={id}
              type={type}
              saveCallback={onLoad}
              onClose={onCloseModal}
            ></BaseProductDialog>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default BaseProductTable;
