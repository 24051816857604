import { createStyles, Grid, IconButton, makeStyles, Paper, TableContainer, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { Chip } from "@mui/material";
import { ActionButton } from "components/Common/Button";
import TitleDiv from "components/Common/TitleDiv";
import MaterialTable from "material-table";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseInfoApi, ErrorHandler, GroupApi, UserApi } from "system/ApiService";
import { ADMIN_TYPE, TABLEICONS } from "system/Constants";
import { Baselanguage, Group, ModalType, UserInfo } from "system/types";
import UserManagementDialog from "./UserManagementDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
  })
);

export interface UserList extends UserInfo {
  group_info: Group;
}

function UserManagementTable(): ReactElement {
  const classes = useStyles();
  const [data, setData] = useState<UserList[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [modalType, setModalType] = useState<ModalType>("");
  const [baselanguageList, setBaselanguageList] = useState<Baselanguage[]>([]);
  const baseLangLookup: Object = baselanguageList.reduce((x, y) => ({ ...x, [y.lang_code]: y.lang_name_english }), {});
  const { t } = useTranslation();

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaselanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
      });
  }, []);

  const onOpenModal = (id: string) => {
    setUserId(id);
    setModalType("edit"); //default
    setIsModalOpen(true);
  };
  const onCloseModal = () => {
    setUserId("");
    setModalType("edit");
    setIsModalOpen(false);
  };

  const onLoad = React.useCallback(() => {
    UserApi.GetUserList()
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const goToAddUser = () => {
    setUserId("");
    setModalType("add");
    setIsModalOpen(true);
  };

  const onDeleteUser = (user_id: string) => {
    if (window.confirm("" + t("dialogue-confirm-delete"))) {
      UserApi.DeleteUser(user_id)
        .then((res) => {
          onLoad();
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  };

  const [groupList, setGroupList] = useState<Group[]>([]);
  useMemo(() => {
    GroupApi.GetGroupList()
      .then((res) => {
        setGroupList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <TitleDiv title={"" + t("user-management")}></TitleDiv>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} style={{ minWidth: "900px" }}>
            <MaterialTable
              title=""
              icons={TABLEICONS}
              columns={[
                {
                  title: "" + t("user-id"),
                  field: "user_id",
                  cellStyle: { width: "25%" },
                  render: (rowData) => {
                    switch (rowData.group_info.group_name) {
                      case "sys_admin":
                        return (
                          <>
                            <Chip label="SA" color="error" size="small"></Chip>
                            <span> </span>
                            {rowData.user_id}
                          </>
                        );
                      case "hq_manager":
                        return (
                          <>
                            <Chip label="HQ" color="info" size="small" />
                            <span> </span>
                            {rowData.user_id}
                          </>
                        );
                      case "br_manager":
                        return (
                          <>
                            <Chip label="BR" color="success" size="small" />
                            <span> </span>
                            {rowData.user_id}
                          </>
                        );
                      case "translator":
                        return (
                          <>
                            <Chip label="TR" color="secondary" size="small" />
                            <span> </span>
                            {rowData.user_id}
                          </>
                        );
                    }
                    return rowData.user_id;
                  },
                },
                { title: "" + t("name"), field: "user_name", cellStyle: { width: "15%" } },
                {
                  title: "" + t("language-code"),
                  field: "group_info.trans_lang_code",
                  lookup: baseLangLookup,
                  cellStyle: { width: "12%" },
                },
                {
                  title: "" + t("group-name"),
                  field: "group_info.group_name",
                  lookup: ADMIN_TYPE,
                  cellStyle: { width: "15%" },
                },
                {
                  title: "" + t("active-status"),
                  field: "active_account",
                  lookup: { true: "O", false: "-" },
                  cellStyle: { width: "11%" },
                },
                {
                  cellStyle: { width: "15%" },
                  render: (rowData) => {
                    return (
                      <div className={classes.buttonGroup}>
                        <IconButton
                          className={classes.iconButton}
                          aria-label="update-user"
                          onClick={() => onOpenModal(rowData.user_id)}
                        >
                          <CreateOutlinedIcon />
                        </IconButton>

                        <IconButton
                          className={classes.iconButton}
                          aria-label="delete-user"
                          onClick={() => onDeleteUser(rowData.user_id)}
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      </div>
                    );
                  },
                },
              ]}
              data={data || []}
              options={{ filtering: true, padding: "dense", pageSize: 10 }}
              actions={[
                {
                  icon: "add",
                  tooltip: "",
                  isFreeAction: true,
                  onClick: (event) => {},
                },
              ]}
              components={{
                Action: (props) => (
                  <ActionButton onClick={goToAddUser} variant="contained" style={{ marginLeft: "10px" }}>
                    <AddIcon />
                    {t("add-user")}
                  </ActionButton>
                ),
              }}
            />
            <UserManagementDialog
              open={isModalOpen}
              userId={userId}
              type={modalType}
              saveCallback={onLoad}
              groupList={groupList}
              onClose={onCloseModal}
            ></UserManagementDialog>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default UserManagementTable;
