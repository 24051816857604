import { Grid, TextField, createStyles, makeStyles } from "@material-ui/core";
import { Theme } from "@mui/material";
import { ActionButton } from "components/Common/Button";
import CancelOkButtonSet from "components/Common/Button/CancelOkButtonSet";
import TitleDiv from "components/Common/TitleDiv";
import jwt_decode from "jwt-decode";
import React, { ReactElement, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ErrorHandler, UserApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { useUserDispatch } from "system/UserContext";
import { INIT_USER_INFO, UserInfo } from "system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignButton: {
      // width: "100%",
      // display: "flex",
      // justifyContent: "center",
    },
    input: {
      width: "100%",
    },
    detail: {
      "&:hover": {
        cursor: "pointer",
      },
      color: "#a24642",
    },
    formControl: {
      minWidth: 130,
    },
    bottom: {
      width: "100%",
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
    },
    profileTable: {
      justifyContent: "center",
    },
  })
);

function UserProfileTable(): ReactElement {
  const [state, setState] = useState<UserInfo>(INIT_USER_INFO);
  const [isSelect, setIsSelect] = useState<boolean>(true);
  const [targetLanguage, setTargetLanguage] = useState<string>("");
  const classes = useStyles();

  const loading = useLoadingDispatch();

  const userDispatch = useUserDispatch();

  const onLoad = React.useCallback(() => {
    const decoded: any = jwt_decode(UserApi.GetToken());

    UserApi.GetUserDetail(decoded.sub.user_id)
      .then((res) => {
        setState(res.data);
        UserApi.GetGroupListByUserId(res.data.user_id)
          .then((res) => {
            setTargetLanguage(res.data[0].trans_lang_code);
          })
          .catch((e) => {
            let msg = ErrorHandler(e);
            console.log(msg);
          });
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        alert(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChangeText = (event: any) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  const onClickCancel = () => {
    setIsSelect(true);
  };

  const onClickUpdate = () => {
    setIsSelect(false);
    console.log(state);
    if (window.confirm("The information you wrote will be saved, Would you like to go on?")) {
      loading({ type: "LOADING" });

      UserApi.UpdateUser(state)
        .then((data) => {
          data.user.companyName = data.company.name;
          userDispatch({ type: "UPDATE_USER", item: data.userInfo });
          // UserApi.SetToken(data.token);
          onLoad();
        })
        .catch((e) => {
          //no-matter
          console.log(e);
        })
        .finally(() => {
          loading({ type: "COMPLETE" });
        });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleDiv title="Profile"></TitleDiv>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <th>Id</th>
                <td>{state.id}</td>
              </tr>
              <tr>
                <th>User ID</th>
                <td>{state.user_id}</td>
              </tr>
              <tr>
                <th>Name</th>
                <td>
                  <div>
                    <TextField
                      id="user_name"
                      name="user_name"
                      type="text"
                      value={state.user_name}
                      onChange={onChangeText}
                      inputProps={{
                        title: "user_name",
                      }}
                      className={classes.input}
                      disabled={isSelect}
                    ></TextField>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  <div>
                    <TextField
                      id="user_email"
                      name="user_email"
                      type="text"
                      value={state.user_email}
                      onChange={onChangeText}
                      inputProps={{
                        title: "user_email",
                      }}
                      className={classes.input}
                      disabled={isSelect}
                    ></TextField>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Tel</th>
                <td>
                  <div>
                    <TextField
                      id="user_tel"
                      name="user_tel"
                      type="text"
                      value={state.user_tel}
                      onChange={onChangeText}
                      inputProps={{
                        title: "user_tel",
                      }}
                      className={classes.input}
                      disabled={isSelect}
                    ></TextField>
                  </div>
                </td>
              </tr>
              {/* <tr>
                <th>Locale</th>
                <td>
                  <div>
                    <FormControl className={classes.formControl} disabled={isSelect}>
                      <Select id="locale" name="locale" value={state.locale} onChange={onChangeText}>
                        <MenuItem value="none" disabled>
                          ----
                        </MenuItem>
                        <MenuItem value={"kor"}>Korea</MenuItem>
                        <MenuItem value={"chi"}>China</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </td>
              </tr> */}
              {/* <tr>
                <th>Source Language</th>
                <td>
                  <div>
                    <FormControl className={classes.formControl} disabled={isSelect}>
                      <Select
                        id="user_native_lang"
                        name="user_native_lang"
                        value={state.user_native_lang}
                        onChange={onChangeText}
                      >
                        <MenuItem value="none" disabled>
                          ----
                        </MenuItem>
                        <MenuItem value={"KO"}>Korean</MenuItem>
                        <MenuItem value={"EN_EN"}>English</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </td>
              </tr> */}
              <tr>
                <th>Target Language</th>
                <td>{targetLanguage}</td>
              </tr>
              {/* <tr>
                <th>Admin</th>
                <td>
                  <FormControl component="fieldset" variant="standard" disabled={isSelect}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch checked={state.admin} onChange={handleChange} name="admin" />}
                        label=""
                      />
                    </FormGroup>
                  </FormControl>
                </td>
              </tr>
              <tr>
                <th>Active Account</th>
                <td>
                  <FormControl component="fieldset" variant="standard" disabled={isSelect}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch checked={state.active_account} onChange={handleChange} name="active_account" />
                        }
                        label=""
                      />
                    </FormGroup>
                  </FormControl>
                </td>
              </tr> */}
              <tr>
                <th>Register Date</th>
                <td>{state.register_date}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {isSelect ? (
                    <div className={classes.alignButton}>
                      <ActionButton onClick={onClickUpdate} variant="contained" style={{ float: "right" }}>
                        Modify User Profile
                      </ActionButton>
                    </div>
                  ) : (
                    <div className={classes.alignButton}>
                      <CancelOkButtonSet
                        cancelMethod={onClickCancel}
                        cancelContent="Cancel"
                        okMethod={onClickUpdate}
                        okContent="Update"
                      ></CancelOkButtonSet>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </>
  );
}

export default UserProfileTable;
