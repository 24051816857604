import {
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { CancelButton, SaveButton } from "components/Common/Button";
import SimpleSelect from "components/Common/Custom/SimpleSelect";
import { useEffect, useMemo, useState } from "react";
import { BaseInfoApi, ErrorHandler, GroupApi, ProjectApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import {
  Baselanguage,
  Group,
  INIT_CLICKCOUNT,
  INIT_TRANSLATE_PROJECT,
  MainProject,
  SubProject,
  TemplateType,
} from "system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    bottom: {
      width: "100%",
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    button: {
      width: "100%",
      padding: theme.spacing(1.5),
      fontSize: "1.0rem",
    },
    hidden: {
      display: "none",
    },
  })
);

interface SubProjectProps {
  _main: MainProject;
  _sub: SubProject;
  templateType: TemplateType;
  onClose?: () => void;
  saveCallback?: () => void;
}

function SubProjectTemplate({ _main, _sub, templateType, onClose, saveCallback }: SubProjectProps) {
  const classes = useStyles();
  const [sub, setSub] = useState<SubProject>(_sub);
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<Baselanguage[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  // 서브 프로젝트 생성 시 선택한 나라 정보를 관리 (번역프로젝트 자동 생성용)
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  // const [clickCounts, setClickCounts] = useState<Record<string, number>>({});
  const [clickCount, setClickCount] = useState<{ [key: string]: number }>(INIT_CLICKCOUNT);

  const [groupList, setGroupList] = useState<Group[]>([]);

  const [translateProjectLanguages, setTranslateProjectLanguages] = useState<string[]>([]);

  const loading = useLoadingDispatch();

  const onChange = (args: any) => {
    var name = (args.target as HTMLInputElement).name;
    var value = args.target.value;
    // 숫자로 받아야하는 데이터에 대한 분기처리
    if (name === "main_project_id") {
      value = parseInt(value);
    }
    setSub({ ...sub, [name]: value });
  };

  // 마감기한 사용 보류로 인한 주석처리
  const onChangeDate = (date: MaterialUiPickersDate) => {
    if (date) {
      let year = date.toDate().getFullYear();
      let month = date.toDate().getMonth() + 1;
      let day = date.toDate().getDate();
      let dateString = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
      setSub({ ...sub, project_duedate: dateString });
    }
  };

  // sub project 나라정보
  // 1 : 첫 생성 : 생성
  // 2 : 수정 : 추가 생성만 가능 / 기존것 삭제 X, 직접 삭제
  // 3 : 자식이 하나라도 존재한다 : 삭제 불가능하도록 막음

  // translate project와 연동 예정
  // 번역 프로젝트 존재 => 선택하지 못하도록 함 // isChatGPTOnly면 초록색으로 빛나게 하고 disabled 처리한다.

  // 나라 클릭해서 삭제하는 방식 : 최후의 보루

  const onChangeTargetLanguage = (event: string[]) => {
    setSub({
      ...sub,
      target_lang_code: event,
    });
  };

  const onChangeClickCount = (clickedValue: string) => {
    const currentCount = clickCount[clickedValue] || 0;
    setClickCount((prevClickCount) => {
      // 이전 상태를 복사하여 새로운 객체를 생성
      const updatedClickCount = { ...prevClickCount };

      // lang_code에 대한 클릭 횟수를 업데이트
      updatedClickCount[clickedValue] = currentCount === 2 ? 0 : currentCount + 1; ////

      // 새로운 상태를 반환
      return updatedClickCount;
    });
  };

  useEffect(() => {
    if (_main.id) {
      setSub({ ...sub, main_project_id: _main.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_main.id]);

  useEffect(() => {
    GroupApi.GetGroupList()
      .then((res) => {
        let translateGroupListData = res.data.filter((item: Group) => {
          return item.is_admin === 0;
        });
        console.log("list", translateGroupListData);
        setGroupList(translateGroupListData);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        let languageExceptKO = res.data;

        languageExceptKO.sort((a: Baselanguage, b: Baselanguage) => {
          if (a.lang_name_english < b.lang_name_english) {
            return -1;
          }
          if (a.lang_name_english > b.lang_name_english) {
            return 1;
          }
          return 0;
        });

        setData(languageExceptKO);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  const onSave = () => {
    let flag = false;
    console.log(clickCount, sub.target_lang_code);

    const selectedLanguages: { [key: string]: number } = {};

    sub.target_lang_code.forEach((key) => {
      if (clickCount.hasOwnProperty(key)) {
        selectedLanguages[key] = clickCount[key];
      }
    });

    console.log(sub.project_duedate);
    console.log(selectedLanguages, "선택됨");

    const selectedLanguagesMap = new Map(Object.entries(selectedLanguages));

    // start

    // promise
    const promises: Promise<void>[] = [];

    if (
      sub.project_type !== 0 &&
      sub.project_name !== "" &&
      sub.source_lang_code !== "" &&
      sub.project_duedate !== ""
    ) {
      flag = true;
    } else {
      alert("정보를 모두 입력해주세요.");
      return;
    }

    if (sub.project_duedate === "") {
      let date = new Date();
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let dateString = year + "-" + month + "-" + day;
      setSub({ ...sub, project_duedate: dateString });
      sub.project_duedate = dateString;
      flag = true;
    }

    if (flag) {
      if (sub.id === 0) {
        // sub project 생성
        ProjectApi.AddSubProject(sub)
          .then((res) => {
            const translateProjectPromises: Promise<void>[] = Array.from(selectedLanguagesMap).map(
              ([selectedLanguage, value]: [string, number], index: number) => {
                let translateProject = { ...INIT_TRANSLATE_PROJECT };
                console.log(selectedLanguage, value);

                // 번역가 그룹 id 검색
                // 배열에서 일치하는 객체 찾기
                const matchingObject = groupList.find((item) => item.trans_lang_code === selectedLanguage);

                const translatorGroupId = matchingObject && matchingObject.id ? matchingObject.id : 0;

                // TODO 원본언어 유연하게 설정하도록 수정 필요
                translateProject.source_lang_code = sub.source_lang_code;
                // selectedLanguage === "KO" || selectedLanguage === "EN_EN"
                //   ? //|| selectedLanguage === "JA"
                //     // || selectedLanguage === "CN"
                //     "KO"
                //   : "EN_EN";

                translateProject.target_lang_code = selectedLanguage;
                translateProject.translator_group_id = translatorGroupId;
                translateProject.sub_project_id = parseInt(res.subproject_id, 10);
                translateProject.project_duedate = sub.project_duedate;
                translateProject.project_status = "";
                translateProject.is_closed = false;
                translateProject.is_chatgpt_only = value == 2;

                console.log(translateProject, "등록 예정");

                return ProjectApi.AddTranslateProject(translateProject)
                  .then((res) => {})
                  .catch((e) => {
                    let msg = ErrorHandler(e);
                    console.log(msg);
                  });
              }
            );

            // promise 등록
            promises.push(...translateProjectPromises);

            saveCallback && saveCallback();
            onClose && onClose();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            console.log(msg);
          });
      } else {
        // sub project 업데이트
        ProjectApi.UpdateSubProject(sub)
          .then((res) => {
            saveCallback && saveCallback();
            onClose && onClose();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            console.log(msg);
          });
      }
    }

    // promise 생성
    Promise.all(promises)
      .then(() => {
        console.log("All Promises completed");
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        loading({ type: "COMPLETE" });
      });

    // end
  };

  const [baselanguageList, setBaselanguageList] = useState<Baselanguage[]>([]);
  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaselanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  return (
    <>
      {templateType === "show" ? (
        <Grid container>
          <Grid item xs={12}>
            <b>카테고리 정보</b> / <b>과제명 :</b> {_sub.project_name}, <b>종류 : </b>
            {_sub.project_type === 1 ? "User Manual" : <></>}
            {_sub.project_type === 2 ? "Result Sheet" : <></>}
            {_sub.project_type === 3 ? "LCD" : <></>}
            {_sub.project_type === 4 ? "Catalog" : <></>}
            {_sub.project_type === 5 ? "Voice" : <></>}
            {_sub.project_type === 6 ? "Nameplate" : <></>}
            {_sub.project_type === 7 ? "ETC" : <></>}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SimpleSelect
              id="project_type"
              name="project_type"
              label="카테고리 선택"
              value={sub.project_type || 0}
              className={classes.bottom}
              onChange={onChange}
            >
              <MenuItem value={0} disabled>
                Category Type
              </MenuItem>
              <MenuItem value={1}>User Manual</MenuItem>
              <MenuItem value={2}>Result Sheet</MenuItem>
              <MenuItem value={3}>LCD</MenuItem>
              <MenuItem value={4}>Catalog</MenuItem>
              <MenuItem value={5}>Voice</MenuItem>
              <MenuItem value={6}>Nameplate</MenuItem>
              <MenuItem value={7}>ETC</MenuItem>
            </SimpleSelect>
          </Grid>
          <Grid item xs={6}>
            {/* <SimpleSelect
              id="user_native_lang"
              name="user_native_lang"
              label="원본 언어"
              error={sub.source_lang_code === "" ? true : false}
              className={classes.bottom}
              value={sub.source_lang_code}
              onChange={onChange}
            >
              <MenuItem value={"KO"}>Korean</MenuItem>
              <MenuItem value={"EN_EN"}>English(Common)</MenuItem>
              <MenuItem value={"NL"}>Dutch</MenuItem>
            </SimpleSelect> */}

            <SimpleSelect
              id="source_lang_code"
              name="source_lang_code"
              label="원본 언어"
              error={sub.source_lang_code === "" ? true : false}
              className={classes.bottom}
              onChange={onChange}
              value={sub.source_lang_code}
            >
              {baselanguageList.map((baselanguage: Baselanguage, idx) => {
                return (
                  <MenuItem key={idx} value={baselanguage.lang_code}>{`${baselanguage.lang_name_english}`}</MenuItem>
                );
              })}
            </SimpleSelect>
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              id="project_duedate"
              name="project_duedate"
              format="yyyy-MM-DD"
              label="카테고리 과제 마감기한 입력"
              value={sub.project_duedate}
              onChange={onChangeDate}
              className={classes.bottom}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sub_project_name"
              name="project_name"
              label="카테고리 과제명"
              value={sub.project_name}
              className={classes.bottom}
              onChange={onChange}
            ></TextField>
          </Grid>
          <Grid className="text-right" item xs={12}>
            <CancelButton onClick={onClose}>CANCEL</CancelButton>
            <SaveButton onClick={onSave}>SAVE</SaveButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default SubProjectTemplate;
