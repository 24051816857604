import React, { useEffect, useState } from "react";
import { CancelButton, SaveButton } from "components/Common/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Baselanguage, INIT_BASE_LANGUAGE, ModalType } from "system/types";
import { BaseInfoApi, ErrorHandler } from "system/ApiService";
import BaseLanguageDialogTemplate from "./BaseLanguageDialogTemplate";

interface BaseLanguageDialogProps {
  open: boolean;
  id?: string;
  type: ModalType;
  saveCallback: () => void;
  onClose: () => void;
}

function BaseLanguageDialog({ open, id, type, saveCallback, onClose }: BaseLanguageDialogProps) {
  const [state, setState] = useState<Baselanguage>(INIT_BASE_LANGUAGE);

  const onLoad = React.useCallback(() => {
    if (id) {
      BaseInfoApi.GetBaselanguageDetail(id)
        .then((res) => {
          setState(() => res.data);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    } else {
      setState(INIT_BASE_LANGUAGE);
    }
  }, [id]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const updateBaselanguage = () => {
    if (window.confirm("Base language will be updated by this information. continue?")) {
      BaseInfoApi.UpdateBaselanguage(state)
        .then((res) => {
          onClose();
          saveCallback();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const addBaselanguage = () => {
    if (window.confirm("Base language will be added by this information. continue?")) {
      BaseInfoApi.AddBaselanguage(state)
        .then((res) => {
          onClose();
          saveCallback();
          setState(INIT_BASE_LANGUAGE);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      {type === "edit" ? (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
          <DialogTitle>Update Baselanguage</DialogTitle>
          <DialogContent>
            <BaseLanguageDialogTemplate data={state} onChange={onChange}></BaseLanguageDialogTemplate>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onClose}>CANCEL</CancelButton>
            <SaveButton onClick={updateBaselanguage}>UPDATE</SaveButton>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
          <DialogTitle>Add Baselanguage</DialogTitle>
          <DialogContent>
            <BaseLanguageDialogTemplate data={state} add={true} onChange={onChange}></BaseLanguageDialogTemplate>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onClose}>CANCEL</CancelButton>
            <SaveButton onClick={addBaselanguage}>ADD</SaveButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
export default BaseLanguageDialog;
