import {
  createStyles,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Theme } from "@material-ui/core/styles";
import { ErrorHandler, ProjectApi } from "system/ApiService";
import { INIT_SOURCETEXT_OUTPUT, MainProject, SourceTextOutput, SubProject, TranslateProject } from "system/types";
import { CancelButton, SaveButton } from "components/Common/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    button: {
      marginLeft: "10px",
    },
    textArea: {
      width: "100%",
      padding: "5px",
    },
    textField: {
      marginTop: "20px",
    },
  })
);

interface SourceVoiceUploadProps {
  _main: MainProject;
  _sub: SubProject;
  _translate?: TranslateProject;
}

export function SourceVoiceUpload({ _sub }: SourceVoiceUploadProps): ReactElement {
  const classes = useStyles();
  const [choose, setChoose] = useState<string>(_sub.source_lang_code);
  const [enlist, setENList] = useState<SourceTextOutput[]>([]);
  const [kolist, setKOList] = useState<SourceTextOutput[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChoose(event.target.value as string);
  };

  // TODO voice 원본언어 유연하게 설정하도록 변경
  const onChangeText = (event: any, choose: string, index: number) => {
    if (choose === "EN_EN") {
      setENList(enlist.map((x, i) => (i === index ? { ...x, [event.target.name]: event.target.value } : x)));
    } else if (choose === "KO") {
      setKOList(kolist.map((x, i) => (i === index ? { ...x, [event.target.name]: event.target.value } : x)));
    }
  };

  const onLoad = React.useCallback(() => {
    if (_sub.id) {
      ProjectApi.GetSourceTextBySubProject(_sub.id)
        .then((res) => {
          setENList(res.data.filter((x: SourceTextOutput) => x.source_lang_code === "EN_EN"));
          setKOList(res.data.filter((x: SourceTextOutput) => x.source_lang_code === "KO"));
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  }, [_sub]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const addSourceText = () => {
    if (_sub.id && choose) {
      if (choose === "EN_EN") {
        setENList(
          enlist.concat({
            ...INIT_SOURCETEXT_OUTPUT,
            source_lang_code: choose,
            sub_project_id: _sub.id,
          })
        );
      } else if (choose === "KO") {
        setKOList(
          kolist.concat({
            ...INIT_SOURCETEXT_OUTPUT,
            source_lang_code: choose,
            sub_project_id: _sub.id,
          })
        );
      }
    }
  };
  const onSaveENlist = () => {
    enlist.forEach((item, index) => {
      if (item.id === 0) {
        ProjectApi.PostSourceTextBySubProject({ ...item, source_page_no: index + 1 })
          .then(() => {})
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log(msg);
          });
      } else if (item.id !== 0) {
        ProjectApi.PutSourceTextBySubProject(item.id, { ...item, source_page_no: index + 1 })
          .then(() => {})
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log(msg);
          });
      }
    });
    onLoad();
  };

  const onSaveKOlist = () => {
    kolist.forEach((item, index) => {
      if (item.id === 0) {
        ProjectApi.PostSourceTextBySubProject({ ...item, source_page_no: index + 1 })
          .then(() => {})
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log(msg);
          });
      } else if (item.id !== 0) {
        ProjectApi.PutSourceTextBySubProject(item.id, { ...item, source_page_no: index + 1 })
          .then(() => {})
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log(msg);
          });
      }
    });
    onLoad();
  };

  const onDeleteENlist = (index: number) => {
    if (enlist[index].id === 0) {
      const newList = enlist.filter((x, i) => i !== index);
      setENList(newList);
    } else if (enlist[index].id !== 0) {
      if (window.confirm("Are you sure to delete?")) {
        ProjectApi.DeleteSourceTextBySubProject(enlist[index].id)
          .then(() => {
            onLoad();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log(msg);
          });
      }
    }
  };

  const onDeleteKOlist = (index: number) => {
    if (kolist[index].id === 0) {
      const newList = kolist.filter((x, i) => i !== index);
      setKOList(newList);
    } else if (kolist[index].id !== 0) {
      if (window.confirm("Are you sure to delete?")) {
        ProjectApi.DeleteSourceTextBySubProject(kolist[index].id)
          .then(() => {
            onLoad();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log(msg);
          });
      }
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item container spacing={2} justifyContent="space-between">
          <FormControl className={classes.formControl}>
            <InputLabel id="choose-select-label">원문 언어</InputLabel>
            <Select labelId="choose-select-label" id="choose-select" value={choose} onChange={handleChange}>
              <MenuItem value={"EN_EN"}>EN_EN</MenuItem>
              <MenuItem value={"KO"}>KO</MenuItem>
            </Select>
          </FormControl>
          <Grid item>
            <CancelButton className={classes.button} onClick={addSourceText}>
              추가
            </CancelButton>
            <SaveButton className={classes.button} onClick={choose === "KO" ? onSaveKOlist : onSaveENlist}>
              저장
            </SaveButton>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.textField}>
          {choose === "KO" && kolist.length > 0 ? (
            <>
              {kolist.map((data, index) => (
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <TextareaAutosize
                      id="source-text"
                      name="source_sentence"
                      value={data.source_sentence}
                      onChange={(e) => onChangeText(e, choose, index)}
                      className={classes.textArea}
                      minRows={2}
                    ></TextareaAutosize>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => onDeleteKOlist(index)}>
                      <DeleteOutlineIcon></DeleteOutlineIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : null}
          {choose === "EN_EN" && enlist.length > 0 ? (
            <>
              {enlist.map((data, index) => (
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <TextareaAutosize
                      id="source-text"
                      name="source_sentence"
                      value={data.source_sentence}
                      onChange={(e) => onChangeText(e, choose, index)}
                      className={classes.textArea}
                      minRows={2}
                    ></TextareaAutosize>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => onDeleteENlist(index)}>
                      <DeleteOutlineIcon></DeleteOutlineIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
export default SourceVoiceUpload;
