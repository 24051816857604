import { FormControl, Select } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import PersistentDrawerLeft from "components/Common/PersistentDrawerLeft";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { AuthApi, ErrorHandler, UserApi } from "system/ApiService";
import { useUserState } from "system/UserContext";
import Cookies from "universal-cookie";
import { MyStyles } from "../../style/theme";

interface HeaderProps {
  open: boolean;
  setOpen: (status: boolean) => void;
}

function Header({ open, setOpen }: HeaderProps) {
  const user = useUserState();
  const classes = MyStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openProfile = Boolean(anchorEl);
  const { i18n } = useTranslation();
  const [state, setState] = React.useState<{ language: string }>({
    language: "en",
  });

  const handleChangeLanguage = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as keyof typeof state;

    setState({
      ...state,
      language: value,
    });
    i18n.changeLanguage(value);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();

    UserApi.DisableToken();

    AuthApi.Logout()
      .then((data) => {
        console.log("logout success");
        const cookies = new Cookies();
        cookies.remove("refreshToken", { path: "/" });
        console.log("remove success");
        window.location.replace("/");
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        if (error.response) {
          switch (error.response.status) {
            case 403: //forbidden
            case 404:
              msg = "The id or password you entered is incorrect.";
              break;
          }
        }
        console.log(error + " AuthAPI Error, ErrorMsg : " + msg);
        window.location.replace("/");
      });
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  return (
    <div className={classes.root}>
      <AppBar color="inherit" position="fixed">
        <Toolbar>
          {/* 메뉴 아이콘 */}
          <IconButton
            edge="start"
            className={classes.menuButton}
            onClick={handleDrawerOpen}
            color="inherit"
            aria-label="menu"
            title={"Side Bar:\nWhen you click here, the side bar will show up."}
          >
            <MenuIcon />
          </IconButton>
          {/* 로고 - 홈버튼 */}
          {/* todo: user.admin 값이 제대로 와야함 */}
          {user.user_id === "sys_seo" || user.user_id === "hq_hwang" ? (
            <NavLink to="/project-management">
              <img src="/images/inbody_logo.png" width="100px" alt="title"></img>
            </NavLink>
          ) : (
            <NavLink to="/project/translator">
              <img src="/images/inbody_logo.png" width="100px" alt="title"></img>
            </NavLink>
          )}
          {/* 사이트 이름 */}
          <Typography variant="h6" className={classes.title}>
            InBody Translation
          </Typography>
          {/* 사용자 프로필 */}
          <div className={classes.navProfile}>
            <Typography variant="body2"></Typography>
          </div>
          <div className={classes.navProfile}>
            {/* <button onClick={() => onChangeLanguage("ko")}>ko</button>
            <button onClick={() => onChangeLanguage("en")}>en</button> */}

            <FormControl>
              <Select
                native
                value={state.language}
                onChange={handleChangeLanguage}
                inputProps={{
                  name: "language",
                  id: "language-id",
                }}
                className={classes.formControl}
              >
                <option value={"en"}>English</option>
                <option value={"ko"}>Korean</option>
              </Select>
            </FormControl>
          </div>
          <div>{user.user_id}</div>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openProfile}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component={Link} to={MyRoutes.userProfile}>
                Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {/* nav */}
      <div>
        <PersistentDrawerLeft open={open}></PersistentDrawerLeft>
      </div>
    </div>
  );
}

export default Header;
