import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { CancelButton } from "components/Common/Button";
import TitleDiv from "components/Common/TitleDiv";
import { INIT_MATERIALTABLEOPTIONS, MaterialTableOptions, SourceGlossary, INIT_SOURCE_GLOSSARY } from "system/types";
import SourceGlossaryTable from "./SourceGlossaryTable";
import SourceGlossaryTemplate from "./SourceGlossaryTemplate";

interface SourceGlossarySelectDialogProps {
  type: string;
  open: boolean;
  _sourceGlossary: SourceGlossary;
  onClose: () => void;
  saveCallback: () => void;
}
function SourceGlossaryDialog({ type, open, _sourceGlossary, onClose, saveCallback }: SourceGlossarySelectDialogProps) {
  let options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    onRowClick: (param: SourceGlossary) => {},
  };

  const getDialogContent = (type: string) => {
    switch (type) {
      case "select":
        options = { ...options, select: true };
        return (
          <>
            <SourceGlossaryTable options={options}></SourceGlossaryTable>
            <DialogActions>
              <CancelButton onClick={onClose}>CANCEL</CancelButton>
            </DialogActions>
          </>
        );
      case "edit":
        return (
          <SourceGlossaryTemplate
            onClose={onClose}
            _sourceGlossary={_sourceGlossary}
            saveCallback={saveCallback}
            templateType={"edit"}
          ></SourceGlossaryTemplate>
        );
      case "add":
        return (
          <SourceGlossaryTemplate
            onClose={onClose}
            _sourceGlossary={INIT_SOURCE_GLOSSARY}
            saveCallback={saveCallback}
            templateType={"add"}
          ></SourceGlossaryTemplate>
        );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          <TitleDiv title={`${type.charAt(0).toUpperCase()}${type.substring(1)} Source Glossary`}></TitleDiv>
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>{getDialogContent(type)}</DialogContent>
      </Dialog>
    </>
  );
}
export default SourceGlossaryDialog;
