import { ButtonProps } from "@material-ui/core";
import React from "react";
import * as XLSX from "xlsx";
import ActionButton from "./ActionButton";

interface Props extends ButtonProps {
  data: any[];
  excludeColumns?: string[];
  includeColumns?: string[];

  generateWorkbook: () => XLSX.WorkBook;
  filename: string;
  buttonProps?: ButtonProps;
}

const DownloadButton: React.FC<Props> = ({
  data,
  includeColumns,
  excludeColumns,
  generateWorkbook,
  buttonProps,
  ...props
}) => {
  const handleDownload = () => {
    // let filteredData = null;
    // filteredData = data.map((entry) => {
    //   const result: { [key: string]: any } = {};
    //   if (includeColumns) {
    //     includeColumns.forEach((col) => {
    //       if (entry[col] !== undefined) {
    //         result[col] = entry[col];
    //       }
    //     });
    //   } else {
    //     return entry;
    //   }
    //   return result;
    // });

    // filteredData = filteredData.map((entry) => {
    //   const result = { ...entry };
    //   if (excludeColumns) {
    //     excludeColumns.forEach((col) => {
    //       delete result[col];
    //     });
    //   }
    //   return result;
    // });

    // const ws = XLSX.utils.json_to_sheet(filteredData);
    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wb = generateWorkbook();

    // 파일이름 뒤에 현재날짜 붙임
    const today = new Date();
    const formattedDate = `${String(today.getFullYear()).slice(2)}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
    console.log(formattedDate);
    XLSX.writeFile(wb, props.filename + "_" + formattedDate + ".xlsx");
  };

  return (
    <ActionButton onClick={handleDownload} variant="contained" color="secondary" {...props}>
      Download Excel
    </ActionButton>
  );
};

export default DownloadButton;
