import SourceGlossaryTable from "./SourceGlossaryTable";
import { Dispatch, ReactElement, SetStateAction } from "react";
import { INIT_MATERIALTABLEOPTIONS, MaterialTableOptions, SourceGlossary } from "system/types";

interface Props {
  _setSourceGlossary: Dispatch<SetStateAction<SourceGlossary>>;
  _setActiveStep: Dispatch<SetStateAction<number>>;
}

function SourceGlossaryMain({ _setSourceGlossary, _setActiveStep }: Props): ReactElement {
  const options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    add: true,
    onRowClick: (param: SourceGlossary) => {
      _setSourceGlossary(param);
      _setActiveStep(1);
    },
  };
  return (
    <>
      <SourceGlossaryTable options={options}></SourceGlossaryTable>
    </>
  );
}

export default SourceGlossaryMain;
