import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { CancelButton } from "components/Common/Button";
import TitleDiv from "components/Common/TitleDiv";
import {
  INIT_MATERIALTABLEOPTIONS,
  MaterialTableOptions,
  TargetGlossary,
  INIT_TARGET_GLOSSARY,
  SourceGlossary,
} from "system/types";
import TargetGlossaryTable from "./TargetGlossaryTable";
import TargetGlossaryTemplate from "./TargetGlossaryTemplate";

interface TargetGlossarySelectDialogProps {
  type: string;
  open: boolean;
  _sourceGlossary: SourceGlossary;
  _targetGlossary: TargetGlossary;
  onClose: () => void;
  saveCallback: () => void;
}
function TargetGlossaryDialog({
  type,
  open,
  _sourceGlossary,
  _targetGlossary,
  onClose,
  saveCallback,
}: TargetGlossarySelectDialogProps) {
  let options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    onRowClick: (param: TargetGlossary) => {
      console.log("target glossary table row click in Target Glossary page");
    },
  };

  const getDialogContent = (type: string) => {
    switch (type) {
      case "select":
        options = { ...options, select: true };
        return (
          <>
            <TargetGlossaryTable options={options} _sourceGlossary={_sourceGlossary}></TargetGlossaryTable>
            <DialogActions>
              <CancelButton onClick={onClose}>CANCEL</CancelButton>
            </DialogActions>
          </>
        );
      case "edit":
        return (
          <TargetGlossaryTemplate
            onClose={onClose}
            _sourceGlossary={_sourceGlossary}
            _targetGlossary={_targetGlossary}
            saveCallback={saveCallback}
            templateType={"edit"}
          ></TargetGlossaryTemplate>
        );
      case "add":
        return (
          <TargetGlossaryTemplate
            onClose={onClose}
            _sourceGlossary={_sourceGlossary}
            _targetGlossary={INIT_TARGET_GLOSSARY}
            saveCallback={saveCallback}
            templateType={"add"}
          ></TargetGlossaryTemplate>
        );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          <TitleDiv title={`${type.charAt(0).toUpperCase()}${type.substring(1)} Target Glossary`}></TitleDiv>
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>{getDialogContent(type)}</DialogContent>
      </Dialog>
    </>
  );
}
export default TargetGlossaryDialog;
