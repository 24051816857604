import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { CancelButton, SaveButton } from "components/Common/Button";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorHandler, GroupApi, UserApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { Group, INIT_USER_INFO, ModalType, UserInfo } from "system/types";
import UserDialogTemplate from "./UserManagementDialogTemplate";

interface UserDialogProps {
  open: boolean;
  userId?: string;
  type: ModalType;
  groupList: Group[];
  saveCallback: () => void;
  onClose: () => void;
}

function UserManagementDialog({ open, userId: id, type, groupList, saveCallback, onClose }: UserDialogProps) {
  const [userInfo, setUserInfo] = useState<UserInfo>(INIT_USER_INFO);
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [verifyId, setVerifyId] = useState<boolean>(false);
  const loading = useLoadingDispatch();

  const onLoad = React.useCallback(() => {
    if (id) {
      UserApi.GetUserDetail(id)
        .then((res) => {
          UserApi.GetGroupListByUserId(id)
            .then((subRes) => {
              if (subRes.data.length > 0) {
                setUserInfo({
                  ...res.data,
                  group_role: subRes.data[0].group_role || 0,
                  user_translate_lang: subRes.data[0].trans_lang_code || "",
                });
              } else {
                setUserInfo(res.data);
              }
            })
            .catch((e) => {
              let msg = ErrorHandler(e);
              console.log(msg);
            });
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    } else {
      setUserInfo(INIT_USER_INFO);
    }
  }, [id]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChange = (event: any) => {
    if (event.target.name === "user_id") {
      setVerifyId(false);
    }
    setUserInfo({
      ...userInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInfo({
      ...userInfo,
      [event.target.name]: event.target.checked,
    });
  };

  const onChangeRepeatPassword = (data: string) => {
    setRepeatPassword(data);
  };

  const onCheckId = (userId: string) => {
    // 앞뒤 공백 제거 후 비교
    let trimmedUserId = userId.trim();

    // userId에 공백이 포함되어 있다면 경고창
    if (trimmedUserId.indexOf(" ") >= 0) {
      alert("ID에 공백을 포함할 수 없습니다.");
      return;
    }
    UserApi.GetUserDetail(trimmedUserId).then((res) => {
      if (res.data.user_id === trimmedUserId) {
        setVerifyId(false);
        alert("이미 사용중인 아이디 입니다.");
      } else if (res.data.user_id === null) {
        setVerifyId(true);
        alert("사용 가능한 아이디 입니다.");
      }
    });
  };

  const updateUser = () => {
    // 그룹 정보 수정 시 업데이트 put
    UserApi.UpdateUser(userInfo)
      .then(() => {
        onClose();
        saveCallback();
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  };

  const addUser = async () => {
    const json = JSON.parse(JSON.stringify(userInfo));
    const keys = Object.keys(userInfo);
    let flag = true;

    if (userInfo.password === "" || userInfo.password !== repeatPassword) {
      alert("비밀번호가 입력되지 않았거나, 비밀번호 확인값과 다릅니다.");
      flag = false;
      return;
    } else if (userInfo.user_id === "" || userInfo.group_role === 0) {
      alert("ID 또는 권한 대분류 값을 확인해 주세요.");
      flag = false;
      return;
    } else if (!verifyId) {
      alert("ID 중복체크를 해주세요.");
      flag = false;
      return;
    }

    for (let i = keys.length - 1; i >= 0; i -= 1) {
      if (typeof json[keys[i]] === "string" && !json[keys[i]]) {
        if (keys[i] === "locale" || keys[i] === "register_date" || keys[i] === "user_tel") {
          continue;
        } else {
          flag = false;
        }
        break;
      }
    }

    if (flag) {
      try {
        let groupId: number = 0;
        if (
          groupList.filter(
            (x) => x.group_role === userInfo.group_role && x.trans_lang_code === userInfo.user_translate_lang
          ).length > 0
        ) {
          groupId = groupList.filter(
            (x) => x.group_role === userInfo.group_role && x.trans_lang_code === userInfo.user_translate_lang
          )[0].id;
        } else {
          await GroupApi.AddGroup(userInfo.group_role, userInfo.user_translate_lang).then((res) => {
            groupId = res.data.id;
          });
        }

        const addUser = await UserApi.AddUser(userInfo);

        if (groupId !== 0 && addUser.status === 201 && userInfo.user_id) {
          loading({ type: "LOADING" });
          GroupApi.Assign(groupId, userInfo.user_id)
            .then(() => {
              onClose();
              saveCallback();
              alert("회원 등록을 완료했습니다.");
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              console.log(msg);
            })
            .finally(() => {
              loading({ type: "COMPLETE" });
            });
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      } finally {
      }
    }
  };

  const onCloseModal = () => {
    setUserInfo(INIT_USER_INFO);
    onClose();
  };

  return (
    <>
      {type === "edit" && (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
          <DialogTitle>회원 수정</DialogTitle>
          <DialogContent>
            <UserDialogTemplate data={userInfo} onChange={onChange} handleChange={handleChange}></UserDialogTemplate>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onClose}>취소</CancelButton>
            <SaveButton onClick={updateUser}>수정</SaveButton>
          </DialogActions>
        </Dialog>
      )}
      {type === "add" && (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
          <DialogTitle>회원 추가</DialogTitle>
          <DialogContent>
            <UserDialogTemplate
              data={userInfo}
              add={true}
              onChange={onChange}
              handleChange={handleChange}
              onChangeRepeatPassword={onChangeRepeatPassword}
              verifyId={verifyId}
              onCheckId={onCheckId}
            ></UserDialogTemplate>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onCloseModal}>취소</CancelButton>
            <SaveButton onClick={addUser}>추가</SaveButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default UserManagementDialog;
