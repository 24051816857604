import React from "react";
import { Button, ButtonProps } from "@material-ui/core";

const SaveButton = (props: ButtonProps) => {
  return (
    <Button color="primary" variant="contained" {...props}>
      {props.children}
    </Button>
  );
};

export default SaveButton;
