import { createStyles, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { ActionButton } from "components/Common/Button";
import jwt_decode from "jwt-decode";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { MyRoutes } from "routes/Routes";
import { AuthApi, ErrorHandler, UserApi } from "system/ApiService";
import { useLoadingDispatch, useLoadingState } from "system/LoadingContext";
import useLocationStateMatch from "system/useLocationStateMatch";
import { useUserDispatch } from "system/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    bottom: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      width: "100%",
      padding: theme.spacing(1.5),
      fontSize: "1.0rem",
    },
  })
);

function LoginPage(): ReactElement {
  const classes = useStyles();
  const dispatch = useUserDispatch();
  const loadingDispatch = useLoadingDispatch();
  const { loading } = useLoadingState();
  const history = useHistory();
  const requestedPath = useLocationStateMatch("requestedPath");
  const [state, setState] = useState({ userId: "", userPw: "" });
  const { t } = useTranslation();

  const onChangeText = function (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onKeyDownEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onClickSubmit();
    }
  };

  useEffect(() => {
    // 요청한 주소가 로그인 페이지가 아니었다면, 로그인 완료 후 해당 페이지로 redirect
    if (requestedPath && requestedPath !== MyRoutes.login) {
      loadingDispatch({ type: "LOADING" });

      UserApi.Refresh()
        .then((tokenData) => {
          const decoded: any = jwt_decode(tokenData.access_token);
          UserApi.SetToken(tokenData.access_token);
          // 유저 정보 userState에 저장
          UserApi.GetUserDetail(decoded.sub.user_id)
            .then((res) => {
              UserApi.GetGroupListByUserId(decoded.sub.user_id)
                .then((subRes) => {
                  res.data.admin = subRes.data[0].is_admin > 0;
                  // TODO : res.data.group_info.is_admin값이 들어온다면 admin 여부 결정
                  // if (res.data.user_id === "sys_seo" || res.data.user_id === "hq_hwang") {
                  //   history.push(MyRoutes.projectManagement);
                  // } else {
                  //   history.push(MyRoutes.translatorProject);
                  // }
                  dispatch({ type: "LOGIN", item: res.data });
                  UserApi.SetToken(tokenData.access_token);
                  history.push({
                    pathname: requestedPath,
                    state: undefined,
                  });
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              let msg = ErrorHandler(e);
              console.log(msg);
            });
        })
        .catch((e) => {
          console.log(e);
          //no-matter
        })
        .finally(() => {
          loadingDispatch({ type: "COMPLETE" });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onClickSubmit() {
    AuthApi.Login(state.userId, state.userPw)
      .then((data) => {
        UserApi.SetToken(data.access_token);
        const cookies = new Cookies();
        cookies.set("refreshToken", data.refresh_token);

        UserApi.GetUserDetail(state.userId)
          .then((res) => {
            UserApi.GetGroupListByUserId(state.userId)
              .then((subRes) => {
                res.data.admin = subRes.data[0].is_admin === 1;

                dispatch({ type: "LOGIN", item: res.data });
                // TODO : res.data.group_info.is_admin값이 들어온다면 admin 여부 결정
                if (res.data.admin) {
                  history.push(MyRoutes.projectManagement);
                } else {
                  history.push(MyRoutes.translatorProject);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            let msg = ErrorHandler(e);
            console.log(msg);
          });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        if (error.response) {
          switch (error.response.status) {
            case 403: //forbidden
            case 404:
              msg = t("dialogue-incorrect-id-or-password");
              break;
          }
        }
        console.log(msg);
        alert(t("dialogue-login-failed"));
      });
  }

  if (loading) {
    return <></>;
  } else
    return (
      <div>
        <Typography variant="h5">InBody Translation</Typography>

        <TextField
          id="userId"
          label="Id"
          name="userId"
          type="text"
          variant="outlined"
          value={state.userId}
          className={classes.top}
          inputProps={{
            title: "id",
          }}
          onChange={onChangeText}
          onKeyDown={onKeyDownEnter}
        ></TextField>
        <TextField
          id="userPw"
          name="userPw"
          label="Password"
          type="password"
          variant="outlined"
          value={state.userPw}
          className={classes.bottom}
          onChange={onChangeText}
          onKeyDown={onKeyDownEnter}
          inputProps={{
            title: "password",
          }}
        ></TextField>
        <ActionButton variant="contained" type="submit" className={classes.button} onClick={onClickSubmit}>
          Log In
        </ActionButton>
      </div>
    );
}

export default LoginPage;
