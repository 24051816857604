import UserManagementTable from "components/UserManagement/UserManagementTable";

function UserManagementMain() {
  return (
    <div>
      <UserManagementTable></UserManagementTable>
    </div>
  );
}

export default UserManagementMain;
