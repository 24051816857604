import { ButtonProps, createStyles, makeStyles } from "@material-ui/core";
import { MouseEventHandler, ReactElement } from "react";
import CancelButton from "./CancelButton";
import SaveButton from "./SaveButton";

type NewButtonProps = ButtonProps & {
  cancelMethod: MouseEventHandler<HTMLButtonElement>;
  okMethod: MouseEventHandler<HTMLButtonElement>;
  cancelContent: string;
  okContent: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    cancelOKButtonDefault: {
      float: "right",
    },
    buttonDefault: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  })
);

function CancelOKButtonSet(props: NewButtonProps): ReactElement {
  const classes = useStyles();
  return (
    <>
      <div className={classes.cancelOKButtonDefault}>
        <CancelButton onClick={props.cancelMethod} className={classes.buttonDefault} {...props}>
          {props.cancelContent}
        </CancelButton>
        <SaveButton onClick={props.okMethod} className={classes.buttonDefault} autoFocus {...props}>
          {props.okContent}
        </SaveButton>
      </div>
    </>
  );
}

export default CancelOKButtonSet;
