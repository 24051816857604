import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { TranslationSettings } from "./types";

export const TABLEICONS = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const PROJECT_TYPE = {
  1: "User Manual",
  2: "Result Sheet",
  3: "LCD",
  4: "Catalog",
  5: "Voice",
  6: "Nameplate",
  7: "ETC",
};

export const ADMIN_TYPE = {
  sys_admin: "System Admin",
  hq_manager: "HQ Manager",
  br_manager: "Branch Manager",
  translator: "Translator",
};

export const PROJECT_COMPLETE_TYPE = {
  1: "Translator Complete",
  2: "Re-open",
  3: "Administrator Complete",
};

export const WHOLE_COUNTRY_COLUMNS = [
  { title: "Text Code", field: "text_code", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Korean", field: "KO", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "English", field: "EN_EN", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Arabic", field: "AR", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Bulgarian", field: "BG", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Chinese", field: "CN", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Croatian", field: "HR", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Czech", field: "CZ", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Danish", field: "DA", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Dutch", field: "NL", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Finnish", field: "FI", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "French", field: "FR", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "French(American)", field: "FR_US", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Galician", field: "GA", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "German", field: "DE", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Greek(Greece)", field: "EL", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Hebrew", field: "IW", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Hindi", field: "HI", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Italian", field: "IT", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Japanese", field: "JA", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Latvian", field: "LV", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Lithuanian", field: "LT", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Malay", field: "MS", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Maltese", field: "MT", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Polish", field: "PL", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Portuguese", field: "PT", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Portuguese(Brazil)", field: "PT_BR", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Romanian", field: "RO", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Russian", field: "RU", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Serbian", field: "SR", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Slovak", field: "SK", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Slovenian", field: "SL", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Spanish", field: "ES_SP", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Spanish(Mexico)", field: "SP_MX", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Swedish", field: "SV", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Taiwanese", field: "TW", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Tamil", field: "TA", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Telugu", field: "TE", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Thai", field: "TH", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Turkish", field: "TU", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Ukrainian", field: "UK", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  //{ title: "Undefined", field: "ZZ_ZZ", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "USA", field: "EN_US", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
  { title: "Vietnamese", field: "VI", cellStyle: { width: "200px" }, headerStyle: { width: "200px" } },
];

export const WHOLE_COUNTRY_COLUMNS_STRING = [
  "text_code",
  "KO",
  "EN_EN",
  "AR",
  "BG",
  "CN",
  "HR",
  "CZ",
  "DA",
  "NL",
  "FI",
  "FR",
  "FR_US",
  "GA",
  "DE",
  "EL",
  "IW",
  "HI",
  "IT",
  "JA",
  "LV",
  "LT",
  "MS",
  "MT",
  "PL",
  "PT",
  "PT_BR",
  "RO",
  "RU",
  "SR",
  "SK",
  "SL",
  "ES_SP",
  "SP_MX",
  "SV",
  "TW",
  "TA",
  "TE",
  "TH",
  "TU",
  "UK",
  "ZZ_ZZ",
  "EN_US",
  "VI",
];

export const NO_TRANSLATION_NEEDED = "##No translation needed##";

export const translationSettings: TranslationSettings = {
  KO: { EN_EN: 1.2, JA: 1.1 },
  EN_EN: { NE: 1.3, FR_FR: 1.3 },
};

export function getTranslationLengthMultiplier(sourceLang: string, targetLang: string): number | undefined {
  const sourceSettings = translationSettings[sourceLang];
  if (!sourceSettings) {
    console.warn(`No translation settings found for source language: ${sourceLang}`);
    return undefined;
  }

  const multiplier = sourceSettings[targetLang];
  if (multiplier === undefined) {
    console.warn(
      `No translation length multiplier found for target language: ${targetLang} in source language: ${sourceLang}`
    );
    return undefined;
  }

  return multiplier;
}
