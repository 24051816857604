import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import { SaveButton } from "components/Common/Button";
import "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ErrorHandler, ProjectApi } from "system/ApiService";
import {
  ImageTextDataManual,
  INIT_SELECTED_ITEM,
  INIT_SELECTED_SENTENCE,
  INIT_TRANSLATE_PROJECT,
  SourceTextOutput,
  TranslateProject,
} from "system/types";
import { useUserState } from "system/UserContext";
import TranslationInputManual from "./TranslationInputManual";

interface Params {
  translate_project_code: string;
}
interface TranslatorVoiceComponentProps {
  _trans?: TranslateProject;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pictureLeft: {
      width: "100%",
    },
    imageSize: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      maxHeight: "80vh",
      "& > *": {
        backgroundColor: "#DCDFE3",
      },
    },
    pictureRight: {
      overflowY: "scroll",
      maxHeight: "80vh",
    },
    textArea: {
      width: "100%",
    },
    info: {
      marginTop: "10%",
      marginBottom: "10%",
      textAlign: "center",
    },
    textField: {
      marginTop: "20px",
    },
  })
);

export function TranslatorVoiceComponent({ _trans }: TranslatorVoiceComponentProps) {
  let { translate_project_code } = useParams<Params>();
  const classes = useStyles();
  const user = useUserState();
  const [textData, setTextData] = useState<ImageTextDataManual[]>([]);
  const [pageNum, setPageNum] = useState<number>(1);
  const [wholePageNum, setWholePageNum] = useState<number>(1);
  const [translateProject] = useState<TranslateProject>(_trans ? _trans : INIT_TRANSLATE_PROJECT);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const { t } = useTranslation();
  console.log(textData);
  useMemo(() => {
    setWholePageNum(10);
  }, [translateProject.sub_project_id]);

  const onLoad = React.useCallback(() => {
    if (translateProject) {
      ProjectApi.GetSourceTextBySubProject(61)
        .then((res) => {
          setTextData(res.data.filter((x: SourceTextOutput) => x.source_lang_code === "EN_EN"));
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  }, [translateProject.sub_project_id]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onPageNumChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    window.onbeforeunload = function (event: any) {
      event.preventDefault();
    };

    if (isEdited) {
      setIsEdited(false);
    }

    if (!isEdited || window.confirm("" + t("dialogue-unsaved-content-will-be-lost"))) {
      setPageNum(page);
    }
    // 페이지가 넘어감에 따라, 페이지 번역 데이터 배열 초기화
    setTextData([]);
  };

  const onWriteUnit = (imageTextData: ImageTextDataManual, index: number, subIndex: number) => {
    alert("not supported yet");
  };
  const onSaveUnit = (targetTextData: ImageTextDataManual, index: number) => {
    // todo: 곧 원본문장 id를 이용하여 수정요청 예정
    if (!targetTextData.target_id) {
      ProjectApi.AddTargetText(targetTextData, translateProject, user, "image")
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    } else {
      ProjectApi.UpdateTargetTextBySourceId(targetTextData, translateProject, user, "image")
        .then((res) => {
          console.log(res, "success");
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  };

  const onFinishProject = (id: number) => {
    if (window.confirm("" + t("dialogue-confirm-finish"))) {
      ProjectApi.FinishTranslateProject(id)
        .then(() => {})
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container justifyContent="space-between">
        <div></div>
        <Pagination
          count={wholePageNum}
          defaultValue={1}
          page={pageNum}
          showFirstButton
          showLastButton
          onChange={onPageNumChangePagination}
        ></Pagination>
        <SaveButton
          onClick={() => {
            onFinishProject(parseInt(translate_project_code));
          }}
          title={
            "Final Submission button:\nIf you clicked on all 'Finish/Check Button', and submitted all translated sentences,\nplease click on 'Final Submission' button for administrator's final approval."
          }
        >
          {t("final-submission")}
        </SaveButton>
      </Grid>
      <Grid item xs={12}>
        {textData.map((data, index) => (
          <>
            <TranslationInputManual
              _data={data}
              _index={index}
              // todo: voice 은 subIndex 없음
              _subIndex={0}
              _onSaveUnit={onSaveUnit}
              _onWriteUnit={onWriteUnit}
              setIsEdited={setIsEdited}
              _isFinished={_trans?.is_closed}
              _selectedSentence={INIT_SELECTED_SENTENCE}
              _handleTabChange={() => {}}
            ></TranslationInputManual>
          </>
        ))}
        {textData.length < 1 ? (
          <>
            <div className={classes.info}>
              <Typography variant="h6">
                {"<"} {t("guide-no-sentence")} {">"}
              </Typography>
              <Typography variant="h6">{t("guide-go-next")}</Typography>
            </div>
          </>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
export default TranslatorVoiceComponent;
