import ScrollToTop from "components/Common/Button/ScrollToTop";
import Toast from "components/Common/Custom/Toast";
import TranslatorProjectComponent from "components/Project/Translator/TranslatorProjectComponent";
import BaseInformationMain from "page/BaseInformation/BaseInformationMain";
import BaseTextMain from "page/BaseTranslation/BaseTextMain";
import GlossariesMain from "page/BaseTranslation/GlossariesMain";
import Content from "page/Layout/Content";
import Footer from "page/Layout/Footer";
import Header from "page/Layout/Header";
import * as Login from "page/Login/MainPage";
import NotFound from "page/NotFound";
import UserManagementMain from "page/UserManagement/UserManagementMain";
import UserProfileMain from "page/UserProfile/UserProfileMain";
import { ReactElement, useState } from "react";
import { Route, Switch, useLocation, matchPath } from "react-router-dom";
import UserRoute from "routes/UserRoute";
import { MyStyles } from "style/theme";
import ProjectManagementMain from "page/ProjectManagement/ProjectManagementMain";
import ProjectManagementCompleteMain from "page/ProjectManagement/ProjectManagementCompleteMain";
import TranslatorProjectTable from "components/Project/Translator/TranslatorProjectTable";
import ProjectManagementOngoingMain from "page/ProjectManagement/ProjectManagementOngoingMain";
import { useUserState } from "system/UserContext";

export enum MyRoutes {
  root = "/",
  login = "/login",
  logout = "/logout",
  notFound = "/notfound",

  // Project Management
  projectManagement = "/project-management",
  projectManagementOngoing = "/project-ongoing",
  projectManagementComplete = "/project-complete",

  // User Management
  userManagement = "/user-management",

  // User Profile - user
  userProfile = "/user-profile",

  // Base Information
  baseInformation = "/base-information",

  // Glossaries
  stepByStepGlossaries = "/glossaries",

  // Basetext
  stepByStepBasetext = "/basetext",

  // Project
  project = "/project",
  translatorProject = "/project/translator",
  translation = "/project/translator/:translate_project_code",
}

function Routes(): ReactElement {
  return (
    <>
      <Route path={MyRoutes.login}>
        <Login.MainPage></Login.MainPage>
      </Route>
      <UserRoute Component={ContentRoute} path={MyRoutes.root}></UserRoute>
    </>
  );
}

export default Routes;

function ContentRoute(): ReactElement {
  const classes = MyStyles();
  const [open, setOpen] = useState<boolean>(true);

  let location = useLocation();

  // 현재 경로가 /project/translator/:translate_project_code 패턴과 일치하는지 확인
  const match = matchPath(location.pathname, {
    path: MyRoutes.translation,
    exact: true,
    strict: false,
  });

  // match가 존재하면 해당 경로가 /project/translator/:translate_project_code 패턴과 일치합니다.
  const hideFooter = !!match;

  const user = useUserState();

  return (
    <>
      <div className={classes.bodyWrap}>
        <Header open={open} setOpen={setOpen}></Header>
        {/* Router */}
        <Content open={open}>
          <Switch>
            {/* Project Management */}
            <Route
              path={MyRoutes.root}
              exact
              component={user.admin ? ProjectManagementMain : TranslatorProjectTable}
            ></Route>
            <Route path={MyRoutes.projectManagement} exact component={ProjectManagementMain}></Route>
            <Route path={MyRoutes.projectManagementOngoing} exact component={ProjectManagementOngoingMain}></Route>
            <Route path={MyRoutes.projectManagementComplete} exact component={ProjectManagementCompleteMain}></Route>
            {/* User Management */}
            <Route path={MyRoutes.userManagement} exact component={UserManagementMain}></Route>
            {/* User Profile */}
            <Route path={MyRoutes.userProfile} exact component={UserProfileMain}></Route>
            {/* Glossaries(구) */}
            <Route path={MyRoutes.stepByStepGlossaries} exact component={GlossariesMain}></Route>
            {/* Basetext */}
            <Route path={MyRoutes.stepByStepBasetext} exact component={BaseTextMain}></Route>
            {/* Base Information */}
            <Route path={MyRoutes.baseInformation} component={BaseInformationMain}></Route>
            {/* Translate Project */}
            <Route path={MyRoutes.translatorProject} exact component={TranslatorProjectTable}></Route>
            <Route path={MyRoutes.translation} component={TranslatorProjectComponent}></Route>
            <Route path={"*"} component={NotFound}></Route>
          </Switch>
        </Content>
        <br />
        <ScrollToTop />
        <Toast></Toast>
      </div>
      {!hideFooter && <Footer></Footer>}
    </>
  );
}
