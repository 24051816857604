import { Button, createStyles, Theme, ButtonProps, withStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { ReactElement } from "react";
import { alpha } from "@material-ui/core/styles";
import { ButtonStyles, styledBy } from "components/Common/Button/ImportantButton";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: styledBy("variant", {
        contained: grey[600],
        outlined: "#fff",
      }),
      color: styledBy("variant", {
        contained: theme.palette.getContrastText(grey[600]),
        outlined: grey[600],
      }),
      padding: styledBy("size", {
        contained: "6px 16px",
        small: "4px 10px",
      }),
      boxShadow: styledBy("variant", {
        contained:
          "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        outlined: "0px 0px 0px 0px",
      }),
      "&:hover": {
        backgroundColor: styledBy("variant", {
          contained: grey[600],
          outlined: alpha(grey[600], 0.04),
        }),
      },
      fontWeight: 500,
      border: styledBy("variant", {
        contained: "0",
        outlined: `1px solid ${grey[600]}`,
      }),
    },
  });

const StyledButton = withStyles(styles)(({ classes, variant, ...other }: ButtonStyles) => (
  <Button className={classes.root} {...other} />
));

function GreyButton(props: ButtonProps): ReactElement {
  return (
    <StyledButton variant={props.variant || "contained"} {...props}>
      {props.children}
    </StyledButton>
  );
}

export default GreyButton;
