import React, { useEffect, useState } from "react";
import { CancelButton, SaveButton } from "components/Common/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { INIT_PRODUCT, ModalType, Product } from "system/types";
import { BaseInfoApi, ErrorHandler } from "system/ApiService";
import BaseProductDialogTemplate from "./BaseProductDialogTemplate";

interface ProductDialogProps {
  open: boolean;
  id?: string;
  type: ModalType;
  saveCallback: () => void;
  onClose: () => void;
}

function BaseProductDialog({ open, id, type, saveCallback, onClose }: ProductDialogProps) {
  const [state, setState] = useState<Product>(INIT_PRODUCT);

  const onLoad = React.useCallback(() => {
    if (id) {
      BaseInfoApi.GetProductDetail(id)
        .then((res) => {
          setState(() => res.data);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    } else {
      setState(INIT_PRODUCT);
    }
  }, [id]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const updateProduct = () => {
    if (window.confirm("Product will be updated by this information. continue?")) {
      BaseInfoApi.UpdateProduct(state)
        .then((res) => {
          onClose();
          saveCallback();
          setState(INIT_PRODUCT);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const addProduct = () => {
    if (window.confirm("Product will be added by this information. continue?")) {
      BaseInfoApi.AddProduct(state)
        .then((res) => {
          onClose();
          saveCallback();
          setState(INIT_PRODUCT);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      {type === "edit" ? (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
          <DialogTitle>Update Product</DialogTitle>
          <DialogContent>
            <BaseProductDialogTemplate data={state} onChange={onChange}></BaseProductDialogTemplate>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onClose}>CANCEL</CancelButton>
            <SaveButton onClick={updateProduct}>UPDATE</SaveButton>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
          <DialogTitle>제품 추가</DialogTitle>
          <DialogContent>
            <BaseProductDialogTemplate data={state} add={true} onChange={onChange}></BaseProductDialogTemplate>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onClose}>CANCEL</CancelButton>
            <SaveButton onClick={addProduct}>ADD</SaveButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default BaseProductDialog;
