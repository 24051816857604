import { Grid, Tab, Tabs } from "@material-ui/core";
import BaseLanguageTable from "components/BaseInformation/BaseLanguageTable";
import BaseProductTable from "components/BaseInformation/BaseProductTable";
import GroupManagementTable from "components/BaseInformation/GroupManagementTable";
import TitleDiv from "components/Common/TitleDiv";
import React from "react";
import { useTranslation } from "react-i18next";

function BaseInformationMain() {
  const [value, setValue] = React.useState(1);
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Grid item xs={6}>
        <TitleDiv title={"" + t("base-information")}></TitleDiv>
      </Grid>
      <br></br>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t("base-language")} value={1} />
        <Tab label={t("product-name")} value={2} />
        <Tab label={t("translator-group")} value={3} />
      </Tabs>
      {value === 1 && <BaseLanguageTable></BaseLanguageTable>}
      {value === 2 && <BaseProductTable></BaseProductTable>}
      {value === 3 && <GroupManagementTable></GroupManagementTable>}
    </div>
  );
}

export default BaseInformationMain;
