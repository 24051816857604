import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { PRIMARY } from "style/theme";

const useStyles = makeStyles((theme) => ({
  headTitleBar: {
    borderLeft: "8px solid",
    borderColor: PRIMARY,
    paddingLeft: "5px",
    letterSpacing: "-0.5px",
    fontWeight: "bold",
  },
}));

interface TitleDivProps {
  title: string;
}

function TitleDiv({ title }: TitleDivProps) {
  const classes = useStyles();

  return (
    <Typography variant="h5" component="h3" className={classes.headTitleBar}>
      {title}
    </Typography>
  );
}

export default TitleDiv;
