import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";

import { ImageTextDataManual, SelectedItem, SelectedSentence } from "system/types";

import { t } from "i18next";
import MaterialTable from "material-table";
import React from "react";
import { NO_TRANSLATION_NEEDED, TABLEICONS } from "system/Constants";

import DiffTextComponent from "components/Common/DiffTextComponent";
import { theme } from "style/theme";
import { checkExcludedSentence, checkValidArray, checkValidString } from "system/util";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideGuide: {
      backgroundColor: "#f0f2f5",
      height: "100%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    basetextBottom: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: "100%",
    },

    content: {
      // marginLeft: theme.spacing(2),
    },

    // 상단 섹션 스타일
    topSection: {
      flex: 1, // 'InBody DB'와 'ChatGPT' 섹션이 나머지 공간을 차지합니다.
      overflow: "auto", // 내용이 많을 경우 스크롤을 허용합니다.
    },

    // 하단 섹션 스타일 ('History')
    historySection: {
      flexBasis: "25%", // 'History' 섹션이 전체 높이의 40%를 차지합니다.
      maxHeight: "25%",
      flexGrow: 0,
    },
    historyContent: {},
  })
);

interface TranslationGuideProps {
  _selectedItem: SelectedItem;
  _setSelectedSentence: Dispatch<SetStateAction<SelectedSentence>>;
  _isReadOnly?: boolean;
}

function TranslationGuide({ _selectedItem, _setSelectedSentence, _isReadOnly }: TranslationGuideProps): ReactElement {
  const { newData, index, subIndex, lastTwoSentences, projectReOpenHistory, isFinished } = _selectedItem;

  // 원본/번역 문장 데이터 관리
  const [data, setData] = useState<ImageTextDataManual>(newData);

  // 텍스트박스 재활성용 토글버튼 상태값 관리
  const [checked, setChecked] = React.useState(
    checkValidString(newData.target_sentence) && !checkExcludedSentence(newData.target_sentence)
  );
  // 비활성화 여부 상태값 관리
  const [isDisableTargetSentence, setIsDisableTargetSentence] = React.useState(
    checkValidString(newData.target_sentence)
  );

  const [isModalOpen, setModalOpen] = useState(false);

  // isExcept : 번역가의 번역 제외문장 여부 파악
  const [isExcept, setIsExcept] = useState<boolean>(
    checkValidString(newData.target_sentence) && checkExcludedSentence(newData.target_sentence)
  );
  // isUseOrigin : 번역가의 원본문장 그대로 사용할지 여부 파악
  const [isUseOrigin, setIsUseOrigin] = useState<boolean>(newData.target_sentence === newData.source_sentence);

  const [isDropdownVisible, setDropdownVisible] = useState(true);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const classes = useStyles();

  useEffect(() => {
    if (
      !checkValidString(newData.target_sentence) &&
      checkValidArray(newData.basetext) &&
      checkValidString(newData.basetext[0].managed_text)
    ) {
      setData((prev) => {
        return { ...prev, target_sentence: newData.basetext[0].managed_text };
      });
    }
  }, []);

  useEffect(() => {
    setData(newData);
  }, [newData]);

  // 문장을 채우기 위한 메서드
  const handleFillTargetSentence = (
    textToFill: string | undefined
  ): React.MouseEventHandler<HTMLImageElement> | undefined => {
    // todo : 비효율적으로 많이 불리는 메서드

    return (event) => {
      if (checkValidString(textToFill) && !(checked ? false : data.hasTargetSentence || isDisableTargetSentence)) {
        console.log("comon");
        _setSelectedSentence((prev) => {
          return { ...prev, sentence: textToFill, index: index, subIndex: subIndex };
        });
        console.log(_selectedItem, "to parent");
      }
    };
  };

  // 재오픈 날짜 포맷팅
  var dateString = projectReOpenHistory ? projectReOpenHistory.time_created : new Date();

  var date = new Date(dateString);

  // en-CA 로케일을 사용하여 YYYY-MM-DD 형식으로 변환
  var formattedReOpenDate = date.toLocaleDateString("en-CA");
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  formattedReOpenDate = `${formattedReOpenDate} ${formattedTime}`;

  // // 재오픈 날짜 보다 이전이면서 가장 가까운 시간 찾기
  let closestDate: Date | null = null;

  if (newData.basetext_history.length > 0 && newData.basetext_history[0].history_data.length > 0) {
    closestDate = newData.basetext_history[0].history_data
      .map((item: any) => new Date(item.time_created))
      .filter((date: Date) => date < new Date(formattedReOpenDate))
      .sort((a: Date, b: Date) => b.getTime() - a.getTime())[0]; // 가장 최근 날짜
  }

  return (
    <>
      <div className={classes.sideGuide}>
        <div
          className={classes.container}
          style={{
            border: isFinished && data.is_changed ? "3px solid orange" : "none",
          }}
        >
          <div className="markdown-content">
            <div style={{ fontSize: "11px" }}>
              <ReactMarkdown children={t("translationInfoHeader")} />
            </div>
          </div>
          <div className={classes.topSection}>
            <>
              {/* Basetext 추천 문장 */}
              <div className={classes.basetextBottom}>
                {_isReadOnly ? (
                  <>
                    <span>
                      <img
                        style={{
                          marginRight: "8px",
                        }}
                        src="/dictionary.png"
                        width="20px"
                        alt="title"
                        title={"Standard translation."}
                      />
                      Standard translation
                    </span>
                  </>
                ) : (
                  <>
                    <img
                      style={{
                        marginRight: "8px",
                      }}
                      src="/db-file-format-symbol.svg"
                      width="20px"
                      alt="title"
                      onClick={handleFillTargetSentence(
                        checkValidArray(newData.basetext) ? newData.basetext[0].managed_text : ""
                      )}
                      title={
                        "Translations provided from DB button:\nThe text is the result of a single translation into the respective country's language and is the last entry saved in the database. When you click this button, the text stored in the database will be automatically filled in the blank space."
                      }
                    />
                    <span>InBody DB</span>
                  </>
                )}
                <div
                  style={{
                    cursor:
                      !_isReadOnly &&
                      newData.basetext &&
                      !(checked ? false : data.hasTargetSentence || isDisableTargetSentence)
                        ? "pointer"
                        : "default",

                    marginTop: theme.spacing(0.5),
                    marginLeft: theme.spacing(1),
                    marginBottom: theme.spacing(2),
                  }}
                  onClick={handleFillTargetSentence(
                    newData.basetext.length > 0 &&
                      newData.basetext[0].managed_text &&
                      newData.basetext[0].managed_text.indexOf(NO_TRANSLATION_NEEDED) < 0
                      ? newData.basetext[0].managed_text
                      : ""
                  )}
                  title={
                    "Translations provided from DB button:\nThe text is the result of a single translation into the respective country's language and is the last entry saved in the database. When you click this button, the text stored in the database will be automatically filled in the blank space."
                  }
                >
                  {newData.basetext.length > 0 && newData.basetext[0].managed_text ? (
                    <>
                      {newData.basetext[0].managed_text.indexOf(NO_TRANSLATION_NEEDED) < 0 ? (
                        <span style={{ color: _isReadOnly ? "gray" : "blue" }}>{newData.basetext[0].managed_text}</span>
                      ) : (
                        <span style={{ color: "gray" }}>(Excluded in the last project)</span>
                      )}
                    </>
                  ) : (
                    <span style={{ color: "gray" }}>(The sentence does not exist.)</span>
                  )}
                </div>
              </div>
              {!_isReadOnly && (
                <>
                  {/* ChatGPT 추천 문장 */}
                  <div className={classes.basetextBottom}>
                    <img
                      style={{
                        marginRight: "8px",
                      }}
                      src="/chatgpt-logo.svg"
                      width="20px"
                      alt="title"
                      onClick={handleFillTargetSentence(
                        checkValidArray(newData.chatgpt_text) && newData.chatgpt_text[0].managed_text
                          ? newData.chatgpt_text[0].managed_text
                          : ""
                      )}
                      title={
                        "Translations provided from Chat GPT Button:\nThis is the translation provided by Chat GPT for the given text. When you click this button, the text stored in the database will be automatically filled in the blank space."
                      }
                    />
                    <span>ChatGPT</span>

                    <div
                      style={{
                        cursor:
                          checkValidArray(newData.chatgpt_text) &&
                          newData.chatgpt_text[0].managed_text &&
                          !(checked ? false : data.hasTargetSentence || isDisableTargetSentence)
                            ? "pointer"
                            : "default",
                        marginTop: theme.spacing(0.5),
                        marginLeft: theme.spacing(1),
                        marginBottom: theme.spacing(2),
                      }}
                      onClick={handleFillTargetSentence(
                        checkValidArray(newData.chatgpt_text) ? newData.chatgpt_text[0].managed_text : ""
                      )}
                      title={
                        "Translations provided from Chat GPT Button:\nThis is the translation provided by Chat GPT for the given text. When you click this button, the text stored in the database will be automatically filled in the blank space."
                      }
                    >
                      {checkValidArray(newData.chatgpt_text) && newData.chatgpt_text[0].managed_text ? (
                        <span style={{ color: "#74aa9c" }}>{newData.chatgpt_text[0].managed_text}</span>
                      ) : (
                        <span style={{ color: "gray" }}>(The sentence does not exist.)</span>
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* 유사도 레이아웃 */}
              {/* <div className={classes.basetextBottom}>
                      <img
                        style={{
                          marginRight: "8px",
                        }}
                        src="/similarity.png"
                        width="20px"
                        alt="title"
                        onClick={handleFillTargetSentence(
                          checkValidArray(newData.chatgpt_text) && newData.chatgpt_text[0].managed_text
                            ? newData.chatgpt_text[0].managed_text
                            : ""
                        )}
                        title={
                          "Translations provided from Chat GPT Button:\nThis is the translation provided by Chat GPT for the given text. When you click this button, the text stored in the database will be automatically filled in the blank space."
                        }
                      />
                      <span>Similarity</span>
                      <br />
                      <br />
                      {console.log(typeof newData.basetext_history[0])}
                      {typeof newData.basetext_history[0] !== "undefined" &&
                        newData.basetext_history[0].history_data.length > 0 &&
                        newData.basetext_history[0].history_data.map((sentence, index) => (
                          <div
                            style={{
                              border: "1px solid #ccc",
                              background: "#fff",
                              marginLeft: theme.spacing(2),
                            }}
                            onClick={handleFillTargetSentence(
                              sentence.history_managed_text
                                ? // newData.basetext_history[0].history_data[0].history_managed_text
                                  sentence.history_managed_text
                                : ""
                            )}
                          >
                            <>
                              <div
                                key={index}
                                onClick={() => handleHistoryImageClick(sentence.history_managed_text)}
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  // borderBottom:
                                  //   index !== newData.basetext_history[0].history_data.length - 1
                                  //     ? "1px solid #e0e0e0"
                                  //     : undefined,
                                  padding: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1, // 나머지 공간을 모두 차지하도록 설정
                                    paddingLeft: "10px", // 왼쪽에 여백을 주어 구분선과 문장 사이의 간격을 늘림
                                    color: "gray",
                                  }}
                                >
                                  {sentence.history_managed_text}
                                </div>
                                <div
                                  style={{
                                    width: "50px", // 고정된 너비를 가진 번호 칸
                                    marginRight: "10px",
                                    color: "gray",
                                    fontWeight: "bold",
                                    borderLeft: "1px solid #e0e0e0", // 오른쪽에 구분선을 추가
                                    textAlign: "right", // 번호를 우측 정렬
                                    paddingLeft: "10px", // 텍스트와 구분선 사이의 여백
                                  }}
                                >
                                  90%
                                </div>
                              </div>
                            </>
                          </div>
                        ))}

                      {(!newData.basetext_history[0] || newData.basetext_history[0].history_data.length === 0) && (
                        <>
                          <span
                            key={index}
                            style={{
                              marginLeft: theme.spacing(2),
                              cursor: "pointer",
                              // borderBottom: newData.basetext_history ? "1px solid #e0e0e0" : undefined,
                              color: "gray",
                            }}
                          >
                            No Data
                          </span>
                          <br />
                        </>
                      )}
                      <br />
                    </div> */}
            </>
          </div>
          <div style={{ width: "100%" }} className={classes.historySection}>
            {/* <hr style={{ marginRight: "16px" }}></hr> */}
            <hr />
            <div style={{ display: "flex", alignItems: "center" }} className={classes.basetextBottom}>
              <img
                style={{
                  marginRight: "8px",
                }}
                src="/history.svg"
                width="20px"
                alt="title"
                onClick={() => setDropdownVisible(true)}
                title={"History Button:\nThis button will show previous text that you wrote."}
              />

              <span
                style={{
                  margin: "0",
                  cursor:
                    checkValidArray(newData.basetext_history) &&
                    checkValidArray(newData.basetext_history[0].history_data)
                      ? "pointer"
                      : "default",
                }}
                onClick={() => setDropdownVisible(true)}
              >
                <span>History</span>
                <span
                  style={{
                    margin: "0",
                    color: "gray",
                    marginLeft: theme.spacing(2),
                  }}
                  title={"Basetext's text code."}
                >
                  {newData.basetext.length > 0 &&
                    newData.basetext[0].managed_text &&
                    newData.basetext[0].managed_text.indexOf(NO_TRANSLATION_NEEDED) < 0 &&
                    "#  " + newData.basetext[0].text_code + " "}
                </span>
              </span>
              <hr />
            </div>

            {isDropdownVisible &&
            checkValidArray(newData.basetext_history) &&
            checkValidArray(newData.basetext_history[0].history_data) ? (
              <>
                <div
                  style={{
                    border: "1px solid #ccc",
                    background: "#f0f2f5",
                    // marginRight: theme.spacing(2),
                    maxHeight: "50%",
                    overflowY: "auto",
                    boxSizing: "border-box" /* 테두리와 패딩을 너비에 포함 */,
                  }}
                >
                  {newData.basetext_history[0].history_data.map((sentence, index) => (
                    <>
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          cursor: _isReadOnly ? "default" : "pointer",
                          borderBottom:
                            index !== newData.basetext_history[0].history_data.length - 1
                              ? "1px solid #e0e0e0"
                              : undefined,
                          paddingLeft: theme.spacing(1),
                          alignItems: "center",
                        }}
                        onClick={
                          !_isReadOnly
                            ? handleFillTargetSentence(
                                sentence.history_managed_text
                                  ? // newData.basetext_history[0].history_data[0].history_managed_text
                                    sentence.history_managed_text
                                  : ""
                              )
                            : undefined
                        }
                      >
                        <div
                          style={{
                            // width: "50px", // 고정된 너비를 가진 번호 칸
                            color: "gray",
                            borderRight: "1px solid #e0e0e0", // 오른쪽에 구분선을 추가
                            textAlign: "left", // 번호를 우측 정렬
                            marginRight: "4px",
                            paddingRight: "4px", // 텍스트와 구분선 사이의 여백
                            // width: "90%",
                            flex: 6,
                          }}
                        >
                          {sentence.history_managed_text}
                        </div>
                        <div
                          style={{
                            flex: 1, // 나머지 공간을 모두 차지하도록 설정
                            fontSize: "10px",
                            paddingRight: "4px", // 텍스트와 구분선 사이의 여백
                          }}
                          title={new Date(sentence.time_created)
                            .toLocaleString("ko-KR", {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              // second: "2-digit",
                              hour12: false,
                            })
                            .replaceAll(" ", "")}
                        >
                          {new Date(sentence.time_created)
                            .toLocaleString("ko-KR", {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                              // hour: "2-digit",
                              // minute: "2-digit",
                              // // second: "2-digit",
                              // hour12: false,
                            })
                            .replaceAll(" ", "")}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <div
                key={index}
                style={{
                  marginLeft: theme.spacing(2),
                  cursor: "default",
                  color: "gray",
                }}
              >
                No Data
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal Dialog */}
      {checkValidArray(newData.basetext_history) && checkValidArray(newData.basetext_history[0].history_data) && (
        <Dialog open={isModalOpen} onClose={toggleModal} fullWidth maxWidth="lg">
          <DialogTitle>Translation History</DialogTitle>
          <DialogContent>
            {/* ① Basetext : <br></br>
            {newData.basetext[0].managed_text}
          <br></br> */}
            <br></br>
            <b>① [ Final ] User-Submitted Text:</b>
            <br></br>
            {lastTwoSentences ? lastTwoSentences[1] : ""} <br></br>
            <br></br>
            <b>② Differences :</b>
            <br />
            {lastTwoSentences && lastTwoSentences.length >= 2 && lastTwoSentences[0] !== "" ? (
              <>
                {lastTwoSentences[0] !== lastTwoSentences[1] ? (
                  <>
                    <DiffTextComponent beforeText={lastTwoSentences[0]} afterText={lastTwoSentences[1]} />
                  </>
                ) : (
                  <>
                    <span style={{ color: "lightgray" }}>(same)</span>
                    <br></br>
                  </>
                )}
              </>
            ) : (
              <>
                <span style={{ color: "lightgray" }}>(No sentence to check difference)</span>
                <br></br>
              </>
            )}
            <br></br>
            <b>③ Pre-Reopen Sentence (as of {formattedReOpenDate}) :</b>
            <br></br>
            {lastTwoSentences && lastTwoSentences[0] !== "" ? (
              lastTwoSentences[0]
            ) : (
              <span style={{ color: "lightgray" }}>(No sentence)</span>
            )}{" "}
            <br></br>
            <br></br>
            {/* <p>Translation 1: ...</p>
            <p>Translation 2: ...</p> */}
          </DialogContent>

          <DialogTitle>Basetext history table</DialogTitle>
          <DialogContent>
            {/* <span style={{ color: "darkgray" }}>
              In the table, <b>Pre-Reopen Sentence</b> dated before <b>{formattedReOpenDate}</b> are marked in gray.
            </span> */}

            <MaterialTable
              title=""
              icons={TABLEICONS}
              columns={[
                {
                  title: "" + t("history_managed_text"),
                  field: "history_managed_text",
                  cellStyle: { width: "24%" },
                  disableClick: true,
                },
                {
                  title: "" + t("time_created"),
                  field: "time_created",
                  cellStyle: { width: "24%" },
                  disableClick: true,
                  render: (rowData) => {
                    const date = new Date(rowData.time_created);
                    const formattedDate = date.toLocaleDateString("en-CA"); // YYYY-MM-DD
                    const formattedTime = date.toLocaleTimeString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: false, // 24시간 형식
                    });
                    return `${formattedDate} ${formattedTime}`; // YYYY-MM-DD HH:mm:ss
                  },
                  defaultSort: "desc",
                },
              ]}
              data={newData.basetext_history[0].history_data || []}
              options={{
                filtering: true,
                pageSize: 5,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default TranslationGuide;
