import React, { useEffect, useState } from "react";
import { diff_match_patch, Diff } from "diff-match-patch";

type TextTuple = [number, string];

interface DiffTextComponentProps {
  beforeText: string;
  afterText: string;
}

const DiffTextComponent: React.FC<DiffTextComponentProps> = ({ beforeText, afterText }) => {
  const [diffResult, setDiffResult] = useState<TextTuple[]>([]);

  useEffect(() => {
    const dmp = new diff_match_patch();
    const diffs = dmp.diff_main(beforeText, afterText);
    dmp.diff_cleanupSemantic(diffs);

    const result: TextTuple[] = diffs.map((diff: Diff) => [diff[0], diff[1]]);
    setDiffResult(result);
  }, [beforeText, afterText]);

  return (
    <div>
      {diffResult.map((text, index) => {
        let style: React.CSSProperties = {};
        switch (text[0]) {
          case -1:
            style = { textDecoration: "line-through", color: "red", backgroundColor: "pink" };
            break;
          case 1:
            style = { color: "green", backgroundColor: "lightgreen" };
            break;
          default:
            break;
        }

        return (
          <span key={index} style={style}>
            {text[1]}
          </span>
        );
      })}
    </div>
  );
};

export default DiffTextComponent;
