export const DATE_NO_DATA = "NO-DATA";

export function getDateUsingNumber(item: number | undefined): string {
  if (item) {
    return getDateUsingDate(new Date(item));
  } else {
    return DATE_NO_DATA;
  }
}
export function getDateUsingDate(date: Date): string {
  return getFormatDate(date);
}

/**
 * 날짜 Format
 * @param date
 * @returns string (2020-01-15)
 */
function getFormatDate(date: Date) {
  const ret = [];
  const month = 1 + date.getMonth();
  const day = date.getDate();

  ret.push(date.getFullYear());

  ret.push(month >= 10 ? month : `0${month}`);
  ret.push(day >= 10 ? day : `0${day}`);

  return ret.join("-");
}

export function isInt(n: number) {
  return n % 1 === 0;
}
export function isFloat(n: number) {
  return n % 1 !== 0;
}
export const largerThanZero = (n: number | undefined) => (n ? n >= 0 : false);

export function ParseIntOrZero(n: string) {
  const num = parseInt(n);
  return isNaN(num) ? 0 : num;
}

export function HasRole(roles: string, roleName: string) {
  return roles.includes(roleName);
}

export function IsAdminUser(roles: string) {
  return HasRole(roles, "ADMIN");
}
export function IsManagerUser(roles: string) {
  return HasRole(roles, "MANAGER");
}
export function IsUser(roles: string) {
  return HasRole(roles, "USER");
}
export function IsEngineerUser(roles: string) {
  return HasRole(roles, "ENGINEER");
}

/**
 * SubString with ...
 * @param str 자를 문자열
 * @param length 자를 문자열의 총 길이
 * @returns 자를 문자열의 총 길이보다 작으면 그대로 문자열을 리턴하고, 길다면 최대 length까지 자른 후에 "..."를 추가합니다.
 */
export function SubString(str: string, length: number): string {
  if (length < 0) {
    throw new Error("length greater than 0");
  } else {
    if (str.length === length || str.length < length) return str;
    else {
      return str.substring(0, length) + "...";
    }
  }
}

/**
 * str에서
 * @param str <p>hello world! <br/>This is React.</p>
 * @returns hello world! This is React.
 */
export function RemoveHtmlTag(str: string) {
  const regex = /(<([^>]+)>)/gi;
  const result = str.replace(regex, "");
  return result;
}

/**
 * 이메일 체크 정규식
 * @param value
 * @returns boolean
 */
export function isEmail(value: string) {
  const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regExp.test(value); // 형식에 맞는 경우 true 리턴
}

/**
 * 비밀번호 체크 정규식 (8자~256자, 영문자,특수문자,숫자 각 1개 이상)
 * @param targetPassword
 * @returns boolean
 */
export function isValidPassword(targetPassword: string) {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]*$/g;
  if (targetPassword.length >= 8 && targetPassword.length <= 256) {
    return passwordRegex.test(targetPassword);
  }
  return false;
}
