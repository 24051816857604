import SourceBasetextMain from "components/BaseTranslation/SourceBasetextMain";
import { ReactElement } from "react";

function BaseTextMain(): ReactElement {
  return (
    <div>
      <SourceBasetextMain></SourceBasetextMain>
    </div>
  );
}
export default BaseTextMain;

// (구) Basetext 테이블
// import BaseTextStepMain from "components/BaseTranslation/BaseText/BaseTextStepMain";
// import { ReactElement } from "react";

// function BaseTextMain(): ReactElement {
//   return (
//     <div>
//       <BaseTextStepMain></BaseTextStepMain>
//     </div>
//   );
// }
// export default BaseTextMain;
