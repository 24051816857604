import { createStyles, Grid, makeStyles, MenuItem, TextField, Theme } from "@material-ui/core";
import { CancelButton, SaveButton } from "components/Common/Button";
import SimpleSelect from "components/Common/Custom/SimpleSelect";
import React, { useEffect, useState } from "react";
import { BaseInfoApi, ErrorHandler, GlossaryApi } from "system/ApiService";
import { Baselanguage, SourceGlossary, TargetGlossary, TemplateType } from "system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    bottom: {
      width: "100%",
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    button: {
      margin: "3px",
    },
    hidden: {
      display: "none",
    },
  })
);

interface SourceGlossaryProps {
  _sourceGlossary: SourceGlossary;
  _targetGlossary: TargetGlossary;
  templateType: TemplateType;
  onClose?: () => void;
  saveCallback?: () => void;
}

function SourceGlossaryTemplate({
  _sourceGlossary,
  _targetGlossary,
  templateType,
  onClose,
  saveCallback,
}: SourceGlossaryProps) {
  const classes = useStyles();
  const [targetGlossary, setTargetGlossary] = useState<TargetGlossary>(_targetGlossary);
  const [baseLanguageList, setBaseLanguageList] = useState<Baselanguage[]>([]);
  // const [error, setError] = useState<string>("");

  const onChange = (args: any) => {
    var name = (args.target as HTMLInputElement).name;
    var value = args.target.value;
    setTargetGlossary({ ...targetGlossary, [name]: value });
  };

  const onLoad = React.useCallback(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaseLanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onSave = () => {
    if (!onClose) {
      return;
    }

    if (targetGlossary.glossary_text !== "" && targetGlossary.lang_code !== "") {
      if (targetGlossary.id === 0) {
        targetGlossary.src_glossary_id = _sourceGlossary.id;
        GlossaryApi.AddTargetGlossary(targetGlossary)
          .then((res) => {
            if (saveCallback) saveCallback();
            onClose();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            console.log(msg);
          });
      } else {
        GlossaryApi.UpdateTargetGlossary(targetGlossary)
          .then((res) => {
            if (saveCallback) saveCallback();
            onClose();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            console.log(msg);
          });
      }
    } else alert("모든 값을 입력해주세요.");
  };

  return (
    <>
      {templateType === "show" ? (
        <>
          {/* 단순 정보 표시용 Template */}
          <h5>
            <b>Target Glossary Info</b>
          </h5>
          <h6>
            <b>Target Glossary Text :</b> {targetGlossary.glossary_text}, <b>Language :</b> {targetGlossary.lang_code}
          </h6>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="glossary_text"
              name="glossary_text"
              label="Target Glossary"
              value={targetGlossary.glossary_text}
              className={classes.bottom}
              onChange={onChange}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <SimpleSelect
              id="lang_code"
              name="lang_code"
              label="Language"
              value={targetGlossary.lang_code}
              className={classes.bottom}
              onChange={onChange}
            >
              {baseLanguageList.map((item) => {
                return <MenuItem value={item.lang_code}>{item.lang_name_english}</MenuItem>;
              })}
            </SimpleSelect>
          </Grid>
          <Grid className="text-right" item xs={12}>
            <CancelButton className={classes.button} onClick={onClose}>
              CANCEL
            </CancelButton>
            <SaveButton className={classes.button} onClick={onSave}>
              SAVE
            </SaveButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default SourceGlossaryTemplate;
