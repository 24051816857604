import { createStyles, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { ActionButton } from "components/Common/Button";
import MaterialTable from "material-table";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseInfoApi, ErrorHandler, GlossaryApi } from "system/ApiService";
import { TABLEICONS } from "system/Constants";
import {
  Baselanguage,
  INIT_SOURCE_GLOSSARY,
  INIT_TARGET_GLOSSARY,
  MaterialTableOptions,
  ModalType,
  SourceGlossary,
  TargetGlossary,
} from "system/types";
import TargetGlossaryDialog from "./TargetGlossaryDialog";

interface TargetGlossaryTableProps {
  options: MaterialTableOptions;
  _sourceGlossary: SourceGlossary;
  setActiveStep?: Dispatch<SetStateAction<number>>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: "right",
      marginLeft: "10px",
    },
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
  })
);
function TargetGlossaryTable({ options, _sourceGlossary }: TargetGlossaryTableProps) {
  const classes = useStyles();
  const [data, setData] = useState<TargetGlossary[]>([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<ModalType>("edit");
  const [targetGlossary, setTargetGlossary] = useState<TargetGlossary>(INIT_TARGET_GLOSSARY);
  const [baselanguageList, setBaselanguageList] = useState<Baselanguage[]>([]);
  const baseLangLookup: Object = baselanguageList.reduce((x, y) => ({ ...x, [y.lang_code]: y.lang_name_english }), {});
  const { t } = useTranslation();

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaselanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  const onLoad = React.useCallback(() => {
    GlossaryApi.GetTargetGlossaryListBySourceGlossaryId(_sourceGlossary.id)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, [_sourceGlossary.id]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const addTargetGlossary = () => {
    setTargetGlossary(INIT_TARGET_GLOSSARY);
    setType("add");
    setOpen(true);
  };

  const onClose = () => {
    setTargetGlossary(INIT_TARGET_GLOSSARY);
    setOpen(false);
  };

  const onDeleteTargetGlossary = (id: number) => {
    if (window.confirm("Are you sure to delete '" + id + "' ?")) {
      GlossaryApi.DeleteTargetGlossary(id)
        .then(() => {
          onLoad();
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  };

  const onOpenModal = (targetGlossary: TargetGlossary) => {
    setTargetGlossary(targetGlossary);
    setType("edit");
    setOpen(true);
  };

  return (
    <Grid style={{ minWidth: "1000px" }}>
      <MaterialTable
        title=""
        icons={TABLEICONS}
        onRowClick={(event, rowData) => {
          if (!event) return;
          let target = event.target + "";
          if (!target.includes("SVG") && options.rowClick) {
            options.onRowClick(rowData);
          }
        }}
        columns={[
          { title: "Target Glossary", field: "glossary_text", cellStyle: { width: "45%" } },
          { title: "Language", field: "lang_code", cellStyle: { width: "15%" }, lookup: baseLangLookup },
          { title: "Created", field: "time_created", cellStyle: { width: "15%" } },
          { title: "Updated", field: "time_updated", cellStyle: { width: "15%" } },
          {
            title: "",
            field: "",
            cellStyle: { width: "10%" },
            render: (rowData) => {
              return options.select ? (
                <div className="text-end">
                  <ActionButton
                    style={{ padding: "5px" }}
                    onClick={() => {
                      options.onRowClick();
                    }}
                  >
                    SHOW
                  </ActionButton>
                </div>
              ) : (
                <div className={classes.buttonGroup}>
                  <IconButton className={classes.iconButton} onClick={() => onOpenModal(rowData || 0)}>
                    <CreateOutlinedIcon />
                  </IconButton>

                  <IconButton className={classes.iconButton} onClick={() => onDeleteTargetGlossary(rowData.id || 0)}>
                    <DeleteForeverOutlinedIcon />
                  </IconButton>
                </div>
              );
            },
          },
        ]}
        data={data || []}
        options={{
          filtering: true,
          padding: "dense",
          pageSize: 10,
        }}
        actions={[
          {
            icon: "add",
            tooltip: "",
            isFreeAction: true,
            onClick: (event) => {},
          },
        ]}
        components={{
          Action: (props) =>
            options.add ? (
              <ActionButton onClick={addTargetGlossary} variant="contained" className={classes.button}>
                <AddIcon />
                {t("add-target-glossary")}
              </ActionButton>
            ) : (
              <></>
            ),
        }}
      />

      <TargetGlossaryDialog
        _sourceGlossary={_sourceGlossary || INIT_SOURCE_GLOSSARY}
        _targetGlossary={targetGlossary}
        type={type}
        open={open}
        onClose={onClose}
        saveCallback={onLoad}
      ></TargetGlossaryDialog>
    </Grid>
  );
}

export default TargetGlossaryTable;
