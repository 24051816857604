import React, { Component } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

interface Props {}
interface State {
  isVisible: boolean;
}

export default class ScrollToTop extends Component<Props, State> {
  state = {
    isVisible: false,
  };
  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
  }
  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        isVisible: true,
      });
    } else {
      this.setState({
        isVisible: false,
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div className="scroll-to-top" role="button" aria-details="scroll-to-top">
        {this.state.isVisible && (
          <div onClick={this.scrollToTop}>
            <ArrowUpwardIcon></ArrowUpwardIcon>
          </div>
        )}
      </div>
    );
  }
}
