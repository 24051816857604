import StepByStepOngoing from "components/Project/StepByStepOngoing/StepByStepOngoing";
import { ReactElement } from "react";

function ProjectManagementOngoingMain(): ReactElement {
  return (
    <div>
      <StepByStepOngoing></StepByStepOngoing>
    </div>
  );
}
export default ProjectManagementOngoingMain;
