import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationKO from "./locales/ko/translation.json";

// the translations
const resources = {
  en: { translation: translationEN },
  ko: { translation: translationKO },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    resources,
    lng: "en", // 초기 언어 설정
    fallbackLng: "en", // 지원하지 않는 언어일 경우 fallback language 설정
    keySeparator: false, // "."으로 구분된 key를 사용하지 않고 언어별로 별도의 파일로 분리
    interpolation: {
      escapeValue: false, // react 내에서 HTML 태그 사용을 허용
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
