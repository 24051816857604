import SourceGlossaryTemplate from "./SourceGlossaryTemplate";
import TargetGlossaryTable from "./TargetGlossaryTable";
import { INIT_MATERIALTABLEOPTIONS, MaterialTableOptions, SourceGlossary, TargetGlossary } from "system/types";

interface Props {
  _sourceGlossary: SourceGlossary;
}

function TargetGlossaryMain({ _sourceGlossary }: Props) {
  const options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    add: true,
    onRowClick: (param: TargetGlossary) => {},
  };
  return (
    <>
      <SourceGlossaryTemplate templateType="show" _sourceGlossary={_sourceGlossary}></SourceGlossaryTemplate>
      <hr />
      <TargetGlossaryTable options={options} _sourceGlossary={_sourceGlossary}></TargetGlossaryTable>
    </>
  );
}

export default TargetGlossaryMain;
