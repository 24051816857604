import { createStyles, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { ActionButton, CancelButton, SaveButton } from "components/Common/Button";
import MaterialTable from "material-table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseInfoApi, ErrorHandler, GroupApi, ProjectApi } from "system/ApiService";
import { TABLEICONS } from "system/Constants";
import {
  Baselanguage,
  Group,
  INIT_CLICKCOUNT,
  INIT_TRANSLATE_PROJECT,
  MaterialTableOptions,
  ModalType,
  SubProject,
  TranslateProject,
} from "system/types";
import TranslateProjectDialog from "./TranslateProjectDialog";
import MultipleSelectChips from "components/Common/MultipleSelectChips";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SubProjectAtom } from "system/atom";

interface TranslateProjectTableProps {
  options: MaterialTableOptions;
  _sub: SubProject;
  _setSub: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
  })
);

function TranslateProjectTable({ options, _sub, _setSub }: TranslateProjectTableProps) {
  const classes = useStyles();
  const [data, setData] = useState<TranslateProject[]>([]);
  const [translate, setTranslate] = useState<TranslateProject>(INIT_TRANSLATE_PROJECT);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<ModalType>("edit");
  const [lang, setLang] = useState<Baselanguage[]>([]);
  const [target, setTarget] = useState<[]>([]);
  const [translateProjectLanguages, setTranslateProjectLanguages] = useState<string[]>([]);
  const baseLangLookup: Object = lang.reduce((x, y) => ({ ...x, [y.lang_code]: y.lang_name_english }), {});
  const [group, setGroup] = useState<Group[]>([]);

  const [isDisabledMultiSelectChips, setIsDisabledMultiSelectChips] = useState(true);
  const { t } = useTranslation();

  // 전체 언어리스트 받아옴 todo : multiple chips안에서 불러와도 될듯
  const [allLanguageData, setAllLanguageData] = useState<Baselanguage[]>([]);
  const [error, setError] = useState<string>("");

  const [clickCount, setClickCount] = useState<{ [key: string]: number }>(INIT_CLICKCOUNT);

  const [subProject, setSubProject] = useRecoilState<SubProject>(SubProjectAtom);

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        // let languageExceptKO = res.data.filter((data: Baselanguage) => data.lang_code !== "KO");
        let languageExceptKO = res.data;

        languageExceptKO.sort((a: Baselanguage, b: Baselanguage) => {
          if (a.lang_name_english < b.lang_name_english) {
            return -1;
          }
          if (a.lang_name_english > b.lang_name_english) {
            return 1;
          }
          return 0;
        });

        setAllLanguageData(languageExceptKO);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  // 나라 선택과 동시에 sub project 갱신필요
  const [sub, setSub] = useState<SubProject>(subProject);

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setLang(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });

    GroupApi.GetGroupList()
      .then((res) => {
        setGroup(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });

    if (subProject.id) {
      ProjectApi.GetSubProjectDetail(subProject.id.toString())
        .then((res) => {
          setTarget(res.data.target_lang_code);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = React.useCallback(() => {
    setSub(subProject);
    if (subProject && subProject.id) {
      ProjectApi.GetTranslateProjectListBySubProjectId(subProject.id)
        .then((res) => {
          // 마감된 프로젝트도 보여주기로 함
          setData(res.data);

          setTranslateProjectLanguages((currentLangCodes) => {
            // 'res.data'에서 새 언어 코드 배열을 만듭니다.
            const newLangCodes = res.data.map(
              (translateProject: TranslateProject) => translateProject.target_lang_code
            );

            // 기존 배열에 없는 새 언어 코드만 필터링합니다.
            const uniqueNewLangCodes = newLangCodes.filter((newCode: string) => !currentLangCodes.includes(newCode));

            // 기존 언어 코드 배열에 중복되지 않는 새 언어 코드들을 추가합니다.
            return [...currentLangCodes, ...uniqueNewLangCodes];
          });

          // target_lang_code를 키로 하고, is_chatgpt_only 값에 따라 1 또는 2를 값으로 하는 객체 생성
          setClickCount(
            res.data.reduce((acc: Record<string, number>, translateProject: TranslateProject) => {
              const key = translateProject.target_lang_code;
              const value = translateProject.is_chatgpt_only ? 2 : 1;
              acc[key] = value;
              return acc;
            }, {})
          );

          // setClickCount 객체를 여기서 사용하거나 상태에 저장하면 됩니다.
          console.log(setClickCount);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const makeDate = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let dateString = year + "." + month + "." + day;
    return dateString;
  };
  const addTranslateProject = () => {
    setTranslate(INIT_TRANSLATE_PROJECT);
    setType("add");
    setOpen(true);
  };

  const onOpenModal = (translate: TranslateProject) => {
    setTranslate(translate);
    setType("edit");
    setOpen(true);
  };

  const onClose = () => {
    setTranslate(INIT_TRANSLATE_PROJECT);
    setOpen(false);
  };

  const onDeleteTranslateProject = (id: number, target_lang_code: string) => {
    if (window.confirm("" + t("dialogue-confirm-delete"))) {
      ProjectApi.DeleteTranslateProject(id)
        .then(() => {
          // sub project 업데이트
          ProjectApi.UpdateSubProject({
            ...subProject,
            target_lang_code: subProject.target_lang_code.filter((x) => x !== target_lang_code),
          })
            .then((res) => {
              setSubProject((prev: SubProject) => {
                return { ...prev, target_lang_code: prev.target_lang_code.filter((x) => x !== target_lang_code) };
              });
              setSub((prev: SubProject) => {
                return { ...prev, target_lang_code: prev.target_lang_code.filter((x) => x !== target_lang_code) };
              });
              onLoad();
            })
            .catch((error) => {
              let msg = ErrorHandler(error);
              console.log(msg);
            });
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  };

  const onChangeTargetLanguage = (event: string[]) => {
    setSub({
      ...sub,
      target_lang_code: event,
    });
  };

  const onChangeClickCount = (clickedValue: string) => {
    const currentCount = clickCount[clickedValue] || 0;
    setClickCount((prevClickCount) => {
      // 이전 상태를 복사하여 새로운 객체를 생성
      const updatedClickCount = { ...prevClickCount };

      // lang_code에 대한 클릭 횟수를 업데이트
      updatedClickCount[clickedValue] = currentCount === 2 ? 0 : currentCount + 1; ///

      // 새로운 상태를 반환
      return updatedClickCount;
    });
  };

  const onSave = () => {
    console.log("onSave!!!");

    const keysWithValueAboveZero: string[] = Object.keys(clickCount).filter((key) => clickCount[key] > 0);
    console.log(keysWithValueAboveZero, subProject.target_lang_code);

    const promises: Promise<void>[] = [];
    const newProjects: TranslateProject[] = [];
    const failedKeys: string[] = [];

    Object.keys(clickCount).forEach((key) => {
      const value = clickCount[key];

      console.log(key, value === 1 || value === 2, subProject.target_lang_code.indexOf(key));

      let groupItem = group.filter((x) => x.trans_lang_code === key && x.group_role === 4)[0];

      if ((value === 1 || value === 2) && subProject.target_lang_code.indexOf(key) < 0) {
        console.log(subProject.target_lang_code.indexOf(key), subProject.target_lang_code, key, sub.target_lang_code);
        let newData: TranslateProject = {
          ...INIT_TRANSLATE_PROJECT,
          project_duedate: makeDate(),
          source_lang_code:
            //key === "JA" ||
            // key === "CN" ||
            // key === "EN_EN" ? "KO" : "EN_EN",
            subProject.source_lang_code,
          sub_project_id: subProject.id,
          target_lang_code: key,
          translator_group_id: groupItem.id,
          is_chatgpt_only: value === 2,
          is_closed: value === 2,
          project_status: value === 2 ? "Completed" : "",
        };

        // todo: 마감 대신에 생성시 상태값을 조정하는 방식 재확인

        // API 호출 프로미스를 배열에 추가
        const projectPromise = ProjectApi.AddTranslateProject(newData)
          .then(() => {
            console.log("success" + key);
            newProjects.push(newData);
          })
          .catch((error) => {
            failedKeys.push(key);
            let msg = ErrorHandler(error);
            console.log(msg);
          });

        promises.push(projectPromise);
      }
    });

    // 모든 프로미스가 완료된 후 data 상태를 업데이트
    Promise.all(promises).then(() => {
      setData((prev) => [...prev, ...newProjects]);

      if (failedKeys.length > 0) {
        alert("Cannot create assignment for some languages:\n" + failedKeys.join(", "));
      }
      let fixArray = { ...subProject, target_lang_code: keysWithValueAboveZero };
      // sub project 업데이트
      ProjectApi.UpdateSubProject({ ...subProject, target_lang_code: keysWithValueAboveZero })
        .then((res) => {
          setSubProject((prevSub: SubProject) => ({
            ...prevSub,
            target_lang_code: keysWithValueAboveZero,
          }));
          setSub((prevSub: SubProject) => ({
            ...prevSub,
            target_lang_code: keysWithValueAboveZero,
          }));
          onLoad();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          console.log(msg);
        });

      // 데이터 로드 또는 추가 작업
    });
    setIsDisabledMultiSelectChips(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MultipleSelectChips
            name="target_lang_code"
            label="번역 필요 언어"
            value={subProject.target_lang_code}
            setValue={onChangeTargetLanguage}
            clickCount={clickCount}
            setClickCount={onChangeClickCount}
            options={allLanguageData}
            error={error}
            setError={setError}
            disabled={isDisabledMultiSelectChips}
            translateProjectLanguages={translateProjectLanguages}
          />
        </Grid>
        <Grid item xs={6}>
          <MaterialTable
            title=""
            icons={TABLEICONS}
            onRowClick={(event, rowData) => {
              if (!rowData || !options.rowClick) return;
              options.onRowClickTranslateProject(rowData);
            }}
            columns={[
              {
                title: "" + t("target-language"),
                field: "target_lang_code",
                // cellStyle: { width: "15%" },
                disableClick: !options.rowClick,
                lookup: baseLangLookup,
                render: (rowData) => {
                  let item = lang.filter((item) => item.lang_code === rowData.target_lang_code)[0];
                  if (item) {
                    return <>{item.lang_name_english}</>;
                  }
                },
              },
              {
                title: "" + t("source-language"),
                field: "source_lang_code",
                // cellStyle: { width: "15%" },
                disableClick: !options.rowClick,
                filtering: false,
                lookup: baseLangLookup,
                render: (rowData) => {
                  let item = lang.filter((item) => item.lang_code === rowData.source_lang_code)[0];
                  if (item) {
                    return <>{item.lang_name_english}</>;
                  }
                },
              },
              {
                title: "" + t("duedate"),
                field: "project_duedate",
                disableClick: !options.rowClick,
              },

              {
                title: "" + t("Is Closed"),
                field: "is_closed",
                cellStyle: { width: "10%" },
                lookup: { true: "Closed", false: "-" },
              },

              {
                title: "" + t("Project Status "),
                field: "project_status",
                cellStyle: { width: "10%" },
              },
              {
                title: "",
                field: "",
                // cellStyle: { width: "15%" },
                disableClick: !options.rowClick,
                render: (rowData) => {
                  return options.add ? (
                    <div className={classes.buttonGroup}>
                      <IconButton className={classes.iconButton} onClick={() => onOpenModal(rowData || 0)}>
                        <CreateOutlinedIcon />
                      </IconButton>

                      <IconButton
                        className={classes.iconButton}
                        onClick={() => onDeleteTranslateProject(rowData.id || 0, rowData.target_lang_code || "")}
                      >
                        <DeleteForeverOutlinedIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div className="text-end">
                      <ActionButton
                        style={{ padding: "5px" }}
                        onClick={() => {
                          options.onRowClick();
                        }}
                      >
                        SHOW
                      </ActionButton>
                    </div>
                  );
                },
              },
            ]}
            data={data || []}
            options={{
              filtering: true,
              padding: "dense",
              pageSize: 5,
              rowStyle: (rowData, index) => {
                return {
                  backgroundColor: rowData.is_chatgpt_only ? "#85BBAC" : "white",
                };
              },
            }}
            // actions={[
            //   {
            //     icon: AddIcon,
            //     tooltip: "Add Translate Project",
            //     isFreeAction: true,
            //     onClick: (event) => {
            //       console.log("addddd");
            //     },
            //   },
            // ]}
            // components={{
            //   Action: (props) =>
            //     options.add ? (
            //       <>
            //         <CancelButton
            //           onClick={() => makeAllTranslate(subProject.id)}
            //           variant="contained"
            //           style={{ marginLeft: "10px" }}
            //         >
            //           <AddIcon />
            //           {t("add-countries-in-bulk")}
            //         </CancelButton>
            //         <ActionButton onClick={addTranslateProject} variant="contained" style={{ marginLeft: "10px" }}>
            //           <AddIcon />
            //           {t("add-country")}
            //         </ActionButton>
            //       </>
            //     ) : (
            //       <></>
            //     ),
            // }}
          />

          <TranslateProjectDialog
            sub={subProject}
            setSub={setSubProject}
            _translate={translate}
            onRowClickTranslateProject={options.onRowClickTranslateProject}
            type={type}
            open={open}
            onClose={onClose}
            saveCallback={onLoad}
          ></TranslateProjectDialog>
        </Grid>

        <Grid item xs={6}>
          <div className="text-center">
            {isDisabledMultiSelectChips ? (
              <SaveButton onClick={() => setIsDisabledMultiSelectChips(false)}>Add TranslateProject</SaveButton>
            ) : (
              <>
                <SaveButton onClick={onSave}>Save</SaveButton>
                <CancelButton onClick={() => setIsDisabledMultiSelectChips(true)} className={classes.button}>
                  Cancel
                </CancelButton>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </>
  );
}

export default TranslateProjectTable;
