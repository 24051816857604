import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import TitleDiv from "components/Common/TitleDiv";
import { useTranslation } from "react-i18next";
import { MainProject } from "system/types";
import MainProjectTemplate from "./MainProjectTemplate";

interface MainProjectSelectDialogProps {
  type: string;
  open: boolean;
  _main: MainProject;
  onRowClickMainProject: (params: MainProject) => void;
  saveCallback?: () => void;
  onClose?: () => void;
}
function MainProjectDialog({
  type,
  open,
  _main,
  onRowClickMainProject,
  saveCallback,
  onClose,
}: MainProjectSelectDialogProps) {
  const { t } = useTranslation();

  const getDialogContent = (type: string) => {
    switch (type) {
      // todo : main project 이식 끝나면 지우기
      case "select":
        return <></>;
      case "edit":
        return (
          <MainProjectTemplate
            onClose={onClose}
            main={_main}
            onRowClickMainProject={onRowClickMainProject}
            templateType={"edit"}
            saveCallback={saveCallback}
          ></MainProjectTemplate>
        );
      case "add":
        return (
          <MainProjectTemplate
            onClose={onClose}
            main={_main}
            onRowClickMainProject={onRowClickMainProject}
            templateType={"add"}
            saveCallback={saveCallback}
          ></MainProjectTemplate>
        );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          {type === "add" ? <TitleDiv title={t("add-project")}></TitleDiv> : <></>}
          {type === "select" ? <TitleDiv title={t("select-project")}></TitleDiv> : <></>}
        </DialogTitle>
        <DialogContent style={{ minWidth: "800px" }}>{getDialogContent(type)}</DialogContent>
      </Dialog>
    </>
  );
}

export default MainProjectDialog;
