// 번역 컴포넌트 : Manual, Catalog 용

import {
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import "date-fns";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { ErrorHandler, ProjectApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { ImageText, INIT_IMAGETEXT, MainProject, SubProject, TranslateProject } from "system/types";
import { getTitleByField } from "system/util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputClass: {
      marginTop: "10px",
      marginLeft: "15%",
    },
    pictureLeft: {
      width: "100%",
    },
    imageSize: {
      display: "flex",
      // justifyContent: "center",
      width: "100%",
      maxHeight: "70vh",
      "& > *": {
        backgroundColor: "#DCDFE3",
        heigth: "auto",
      },
    },
    pictureRight: {
      overflowY: "scroll",
      maxHeight: "70vh",
    },
    textArea: {
      width: "100%",
    },
    info: {
      marginTop: "10%",
      marginBottom: "10%",
      textAlign: "center",
    },
    gridBorder: {
      border: "1px solid black",
    },

    toolBar: {
      top: "40%",
      position: "sticky",
    },
  })
);

interface TranslateProjectProps {
  _main: MainProject;
  _sub: SubProject;
  _translate?: TranslateProject;
}

export function TranslateProjectManualComponent({ _main, _sub }: TranslateProjectProps): ReactElement {
  const classes = useStyles();
  const [pageNum, setPageNum] = useState<number>(1);
  const [wholePageNum, setWholePageNum] = useState<number>(0);

  const [sourceLang, setSourceLang] = useState<string>(_sub.source_lang_code);

  const [imageText, setImageText] = useState<ImageText>(INIT_IMAGETEXT);

  const loading = useLoadingDispatch();

  useMemo(() => {
    if (_sub.id) {
      ProjectApi.getMaxSourcePageNumber(_sub.id + "", sourceLang)
        .then((res) => {
          setWholePageNum(res.data.max_page_no);
          console.log(res.data.max_page_no, "pagenum");
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  }, [_sub.id, sourceLang]);

  const onLoad = React.useCallback(
    (page: number) => {
      if (!_sub) return;
      if (!_sub.id) return;
      loading({ type: "LOADING" });
      ProjectApi.GetListSource(_sub.id, sourceLang, page)
        .then((res) => {
          console.log(res.data);
          if (res.data.a_image_and_multi_texts[0]) {
            setImageText(res.data.a_image_and_multi_texts[0]);
          }
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(e, msg);
        })
        .finally(() => {
          loading({ type: "COMPLETE" });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageNum, sourceLang]
  );

  useEffect(() => {
    onLoad(pageNum);
  }, [onLoad, pageNum]);

  const onChangeLang = (e: any) => {
    setSourceLang(e.target.value);
  };

  const onPageNumChangePagination = (event: any, page: number) => {
    setPageNum(page);
    onLoad(page);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* <MainProjectTemplate templateType="show" main={_main}></MainProjectTemplate>
          <SubProjectTemplate templateType="show" _main={_main} _sub={_sub}></SubProjectTemplate> */}
          <FormControl>
            <Select value={sourceLang} onChange={onChangeLang} displayEmpty>
              <MenuItem value={sourceLang}>{getTitleByField(sourceLang)}</MenuItem>
              {/* <MenuItem value={"EN_EN"}>English(Common)</MenuItem>
              <MenuItem value={"KO"}>Korea</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} container justifyContent="space-around">
          <Pagination
            style={{ marginBottom: "10px" }}
            count={wholePageNum}
            defaultValue={1}
            page={pageNum}
            showFirstButton
            showLastButton
            size={"large"}
            color="primary"
            onChange={onPageNumChangePagination}
          ></Pagination>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="space-around">
        <>
          <Grid item xs={6} className={classes.pictureLeft}>
            {imageText && (
              <div className={classes.imageSize}>
                <TransformWrapper
                  centerOnInit={true}
                  initialScale={0.4}
                  minScale={0.4}
                  maxScale={1.5}
                  limitToBounds={false}
                >
                  <TransformComponent>
                    <img src={"data:image/png;base64," + imageText.encoded_image_string} alt={"image"}></img>
                  </TransformComponent>
                </TransformWrapper>
              </div>
            )}
            {!imageText.encoded_image_string && <div>Image loading..</div>}
          </Grid>
          <Grid item xs={1} className={classes.pictureLeft}></Grid>
          <Grid item xs={5} className={classes.pictureRight}>
            {imageText.image_text_data.map((data, index) => (
              <>
                <div>
                  <TextareaAutosize
                    id="source-text"
                    name="sourceText"
                    value={data.source_sentence}
                    className={classes.textArea}
                    required
                    disabled
                  ></TextareaAutosize>
                </div>
                <br />
              </>
            ))}
            {imageText.image_text_data.length === 0 ? (
              <>
                <div className={classes.info}>
                  <Typography variant="h5">
                    {"<"}번역이 필요한 문장이 없습니다.{">"}
                  </Typography>
                  <Typography variant="h6">다른 번역 문장을 번역해 주세요.</Typography>
                  {/* 한글 문장으로 일단 표시 */}
                  {/* <Typography variant="h5">
                            {"<"}The blank page with no extracted text{">"}
                          </Typography>
                          <Typography variant="h6">Please go to the next page!</Typography> */}
                </div>
              </>
            ) : (
              ""
            )}
          </Grid>
        </>
      </Grid>
    </>
  );
}
export default TranslateProjectManualComponent;
