import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import {
  INIT_MAIN_PROJECT,
  INIT_SUB_PROJECT,
  INIT_TRANSLATE_PROJECT,
  MainProject,
  Memo,
  SubProject,
  TranslateProject,
} from "./types";
const { persistAtom } = recoilPersist();
export const SourceLangAtom = atom({
  key: "SourceLangAtom",
  default: "KO",
  effects_UNSTABLE: [persistAtom],
});
export const TargetLangAtom = atom({
  key: "TargetLangAtom",
  default: "EN_EN",
  effects_UNSTABLE: [persistAtom],
});

export const MainProjectAtom = atom<MainProject>({
  key: "MainProjectAtom",
  default: INIT_MAIN_PROJECT,
  // effects_UNSTABLE: [persistAtom],
});

export const SubProjectAtom = atom<SubProject>({
  key: "SubProjectAtom",
  default: INIT_SUB_PROJECT,
});

export const TranslateProjectAtom = atom<TranslateProject>({
  key: "TranslateProjectAtom",
  default: INIT_TRANSLATE_PROJECT,
});

export const IsFirstVisitAtom = atom<boolean>({
  key: "IsFirstVisitAtom",
  default: false,
});

export const WholeMemoDataAtom = atom<Memo[]>({
  key: "WholeMemoDataAtom",
  default: [],
});
