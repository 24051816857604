import { createStyles, makeStyles, Theme } from "@material-ui/core";
import MainProjectDialog from "components/Project/MainProject/MainProjectDialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { INIT_MATERIALTABLEOPTIONS, MainProject, MaterialTableOptions } from "system/types";
import MainProjectTable from "../MainProject/MainProjectTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerDivRight: {
      justifyContent: "left",
      display: "flex",
      cursor: "pointer",
      padding: "20px",
      "& :hover": {
        backgroundColor: "#DCDFE3",
        fontWeight: "bolder",
      },
      "& :hover > *": {
        backgroundColor: "#DCDFE3",
        fontWeight: "bolder",
      },
    },
    outerDivLeft: {
      justifyContent: "right",
      display: "flex",
      cursor: "pointer",
      padding: "20px",
      "& :hover": {
        backgroundColor: "#DCDFE3",
        fontWeight: "bolder",
      },
      "& :hover > *": {
        backgroundColor: "#DCDFE3",
        fontWeight: "bolder",
      },
    },
    innerDiv: {
      width: "400px",
      height: "400px",
      textAlign: "center",
      padding: "50px",
    },
    iconButton: {
      width: "200px",
      height: "200px",
    },
  })
);

interface Props {
  _main: MainProject;
  onRowClickMainProject: (params: MainProject) => void;
}

function StepByStepMainProject({ _main, onRowClickMainProject }: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<string>("");

  const { t } = useTranslation();

  const onDialog = (type: string) => {
    setType(type);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    select: true,
    add: true,
    onRowClickMainProject: onRowClickMainProject,
  };

  return (
    <div>
      <MainProjectTable options={options}></MainProjectTable>

      <MainProjectDialog
        type={type}
        open={open}
        _main={_main}
        onClose={onClose}
        onRowClickMainProject={onRowClickMainProject}
      ></MainProjectDialog>
    </div>
  );
}

export default StepByStepMainProject;
