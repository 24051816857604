import { Badge, Button, createStyles, Grid, makeStyles, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import { ActionButton, SaveButton } from "components/Common/Button";
import "date-fns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { ErrorHandler, ProjectApi, UserApi } from "system/ApiService";
import {
  ImageTextDataManual,
  ImageTextTargetManual,
  INIT_PROJECT_COMPLETE_HISTORY,
  INIT_SELECTED_ITEM,
  INIT_SELECTED_SENTENCE,
  Memo,
  ProjectCompleteHistory,
  SelectedItem,
  SelectedSentence,
  SourceTargetMatching,
  // TargetTextData,
  TranslateProject,
} from "system/types";
import { useUserState } from "system/UserContext";

import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useRecoilState } from "recoil";
import { MyRoutes } from "routes/Routes";
import { theme } from "style/theme";
import { IsFirstVisitAtom, SubProjectAtom, TranslateProjectAtom } from "system/atom";
import { useLoadingDispatch } from "system/LoadingContext";
import TranslationGuide from "./TranslationGuide";
import TranslationInputManual from "./TranslationInputManual";

import SideMemoPanel from "components/Common/SideMemoPanel";
import jwt_decode from "jwt-decode";
import SourceTargetMatchingModalContent from "components/Common/SourceTargetMatchingModalContent";

interface Params {
  translate_project_code: string;
}
interface TranslatorComponentProps {
  _trans?: TranslateProject;
  type: unknown;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pictureLeft: {
      width: "100%",
    },
    imageSize: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      maxHeight: "70vh",
      // height: "100%",
      "& > *": {
        backgroundColor: "#DCDFE3",
      },
    },

    pictureRight: {
      // overflowY: "auto",
      // maxHeight: "70vh",
      padding: theme.spacing(1),
    },
    textArea: {
      width: "100%",
    },
    info: {
      marginTop: "20px",
      textAlign: "center",
    },
    commentArea: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "white",
      border: "1px solid #ccc",
      textAlign: "center",
    },
    textFieldContainer: {
      padding: theme.spacing(0.5),
    },
    textField: {
      // marginTop: "20px",
      // padding: theme.spacing(1),
      maxHeight: "70vh",
      overflowY: "scroll",
      overflowX: "hidden",
    },
    inputClass: {
      marginTop: "10px",
      marginLeft: "15%",
    },
    gridBorder: {
      border: "1px solid black",
      marginBottom: "40px",
    },
    toolBar: {
      top: "10%",
      position: "sticky",
    },
    paginationUL: {
      margin: "10px",
      "& .MuiPagination-ul ": {
        justifyContent: "center",
      },
    },
    root: {
      display: "flex",
      alignItems: "flex-end",
    },
    root2: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      "& > *": {
        marginBottom: theme.spacing(2), // 아이템들에 하단 여백 부여
        minHeight: "50px", // 최소 높이 설정
        overflow: "auto", // 내용이 너무 많을 때 스크롤 허용
      },
      "@media (max-width: 600px)": {
        // 미디어 쿼리를 사용하여 모바일 화면에 대응
        flexDirection: "column",
        alignItems: "stretch", // 모바일 화면에서는 아이템들을 늘림
      },
    },
    rightContainer: {
      backgroundColor: "#f0f2f5",
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "flex-start",
      // justifyContent: "space-around",
      padding: theme.spacing(1),
      height: "100%",
      maxHeight: "70vh",
    },
    guideImages: {
      height: "1vw",
    },
    underlined: {
      borderBottom: "2px solid #000",
      paddingBottom: "3px",
    },
    rootTab: {
      // display: "flex",
      width: "100%",
      // flexGrow: 1, // 부모 컨테이너를 꽉 채우도록 설정
      backgroundColor: "#eee", // 배경색 설정, 필요에 따라 변경
    },
    tabs: {
      width: "100%", // 탭의 너비를 100%로 설정
    },
    flexContainer: {
      width: "100%", // flexContainer의 너비를 100%로 설정
    },
    tabRoot: {
      minWidth: "0", // min-width 스타일을 오버라이드
      width: "50%", // 각 탭의 너비를 부모의 50%로 설정 (예시: 2개의 탭일 경우)
    },
  })
);

export function TranslatorComponent({ _trans, type }: TranslatorComponentProps) {
  let { translate_project_code } = useParams<Params>();

  const [hasComments, setHasComments] = useState<boolean>(false);
  const [commentCount, setCommentCount] = useState<number>(0);
  const classes = useStyles();
  const user = useUserState();
  const [subPageNum, setSubPageNum] = useState<number>(1);
  const [pageNum, setPageNum] = useState<number>(1);
  const [wholePageNum, setWholePageNum] = useState<number>(0);
  // const [translateProject, setTranslateProject] = useState<TranslateProject>(_trans ? _trans : INIT_TRANSLATE_PROJECT);
  const [subProject, setSubProject] = useRecoilState(SubProjectAtom);
  const [translateProject, setTranslateProject] = useRecoilState(TranslateProjectAtom);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  // 관리자의 원문 리소스를 언어별로 조회시, 해당 언어정보 저장하는 state
  const [sourceLang, setSourceLang] = useState<string>("KO");
  const [targetLang, setTargetLang] = useState<string>(translateProject?.target_lang_code || "EN_EN");
  const [isPng, setIsPng] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("1");

  // 해당 페이지에서 번역할 이미지+원문+번역문 리스트
  const [imageTextTargetList, setImageTextTargetList] = useState<ImageTextTargetManual[]>([]);

  // 번역 화면에서 선택한 컴포넌트
  const [selectedItem, setSelectedItem] = useState<SelectedItem>(INIT_SELECTED_ITEM);
  // Guide에서 선택한 문장
  const [selectedSentence, setSelectedSentence] = useState<SelectedSentence>(INIT_SELECTED_SENTENCE);

  const [isFirstVisit, setIsFirstVisit] = useRecoilState<boolean>(IsFirstVisitAtom);

  // 이전에 랜더링된 컴포넌트를 저장하는 Set
  const renderedSourceSentenceSet = new Set();

  const { t } = useTranslation();

  // 메모 입력칸 표시용
  const [isVisible, setIsVisible] = useState(false);

  const handleToggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  // Summary Modal의 표시 여부
  const [isModalSummaryOpen, setIsModalSummaryOpen] = useState<boolean>(false);

  // #region 페이지용 번호 ref
  const pageNumberRef = useRef<HTMLInputElement>(null);
  // #endregion

  // ** 창 크기 얻어오기 위한 state 및 메서드, 화면 크기 또는 SourceLang 바뀔때마다 체크

  interface Size {
    width: number;
    height: number;
  }

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [imageSize, setImageSize] = useState<Size>({ width: 0, height: 0 });
  const [wrapperSize, setWrapperSize] = useState<Size>({ width: 0, height: 0 });

  const [isClosed, setIsClosed] = useState(translateProject.is_closed ?? false);
  const [isFinished, setIsFinished] = useState(translateProject.project_status === "Completed");

  // 프로젝트 마감 정보
  const [projectCompleteHistory, setProjectCompleteHistory] =
    useState<ProjectCompleteHistory>(INIT_PROJECT_COMPLETE_HISTORY);

  // 프로젝트 재오픈 정보
  const [projectReOpenHistory, setProjectReOpenHistory] =
    useState<ProjectCompleteHistory>(INIT_PROJECT_COMPLETE_HISTORY);

  const [sector_image, setSector_image] = useState<string>("");

  const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        setWrapperSize({
          width: wrapperRef.current.offsetWidth,
          height: wrapperRef.current.offsetHeight,
        });
      }
    };
    console.log(wrapperSize.width, wrapperSize.height);
    // 초기 렌더링 후에 크기를 계산합니다.
    window.setTimeout(handleResize, 0);

    // 창 크기가 변경될 때마다 크기를 다시 계산합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [wrapperRef, sourceLang]);

  // comment관련
  const [commentTranslator, setCommentTranslator] = useState("");
  const [commentAdmin, setCommentAdmin] = useState("");

  const handleSaveCommentTranslatorClick = () => {
    // Implement your save logic here, using the 'inputValue' state

    ProjectApi.addPagememo(translateProject.id ? translateProject.id : -1, pageNum, commentTranslator)
      .then((res) => {
        alert("Successfully sent the memo.");
        handleTrigger();
      })
      .catch((e) => {
        alert("Failed to send the memo. Please try logging in again.\nIf the issue persists, contact headquarters.");
        console.log(e);
      })
      .finally(() => {});
  };

  const handleSaveCommentAdminClick = () => {
    // Implement your save logic here, using the 'inputValue' state
    ProjectApi.addPagememo(translateProject.id ? translateProject.id : -1, pageNum, commentAdmin)
      .then((res) => {
        alert("Successfully sent the memo.");
        handleTrigger();
      })
      .catch((e) => {
        alert("Failed to send the memo. Please try logging in again.\nIf the issue persists, contact headquarters.");
      })
      .finally(() => {});
  };

  const loading = useLoadingDispatch();

  const location = useLocation();

  interface PageInfo {
    page: number;
  }

  useMemo(() => {
    // 번역 언어에 따른 원문 언어 세팅
    // TODO [확인필요] subProject 선택 없이 넘어왔을경우, setSubProject 이루어지지 않음

    // 페이지 첫 진입시, 번역언어 및 원문언어 기억
    setSourceLang(translateProject.source_lang_code);
    setTargetLang(translateProject.target_lang_code);
    // 언어 및 Sub Project 정보를 통해서 원문 리소스의 페이지 수 조회
    if (translateProject.sub_project_id) {
      ProjectApi.getMaxSourcePageNumber(translateProject.sub_project_id + "", translateProject.source_lang_code)
        .then((res) => {
          setWholePageNum(res.data.max_page_no);
          // 페이지 넘버가져오면서 SourceLang도 함께 가져옴
          setSourceLang(translateProject.source_lang_code);
          setTargetLang(translateProject.target_lang_code);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  }, [translateProject.sub_project_id, type, pageNum]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const onLoad = React.useCallback((page: number) => {
    const decoded: any = jwt_decode(UserApi.GetToken());
    setIsAdmin(decoded.sub.is_admin);

    // 번역 언어에 따른 원문 언어 세팅
    // const sourceLang = subProject.source_lang_code;
    //translateProject.target_lang_code === "JA" ||
    // translateProject.target_lang_code === "CN" ||
    // translateProject.target_lang_code === "EN_EN" ? "KO" : "EN_EN";

    // 페이지 첫 진입시, 번역언어 및 원문언어 기억
    setSourceLang(translateProject.source_lang_code);
    setTargetLang(translateProject.target_lang_code);
    let oneImageWithMultiTexts: ImageTextTargetManual[] = [];
    let translate_project_id =
      translate_project_code && translate_project_code !== "" ? translate_project_code : _trans?.id + "";

    // Translate Project 상세 정보 조회
    ProjectApi.GetTranslateProjectDetail(translate_project_id)
      .then((res) => {
        setTranslateProject(res.data);

        ProjectApi.GetSubProjectDetail(res.data.sub_project_id + "")
          .then((subRes) => {
            setSubProject(subRes.data);

            // 페이지 첫 진입시, 번역언어 및 원문언어 기억
            setSourceLang((s) => res.data.source_lang_code);
            setTargetLang((t) => res.data.target_lang_code);

            const api =
              subRes.data.project_type === 2
                ? ProjectApi.GetListSourceTargetAllInOneForPhotoshop
                : ProjectApi.GetListSourceTargetAllInOne;

            loading({ type: "LOADING" });
            // Translate Project 기반으로 원문/번역 문장 조회
            api(parseInt(translate_project_id), res.data.source_lang_code, res.data.target_lang_code, page)
              .then((res) => {
                // 번역가용 데이터 Set
                res.data[0].a_image_and_multi_texts.sort(function (
                  prev: ImageTextTargetManual,
                  next: ImageTextTargetManual
                ) {
                  return prev.dir_order_no - next.dir_order_no;
                });
                let response = res.data[0].a_image_and_multi_texts;
                // 페이지의 가장 첫 이미지를 확인하고, PSD프로젝트인지,  SVG프로젝트인지 확인
                if (response.length > 0) {
                  let isPng = atob(response[0].encoded_image_string).slice(0, 5).indexOf("PNG") >= 0;
                  setIsPng(isPng);
                }

                setImageTextTargetList(res.data[0].a_image_and_multi_texts);

                if (res.data[0].sector_image) {
                  setSector_image(res.data[0].sector_image);
                }
              })
              .catch((e) => {
                let msg = ErrorHandler(e);
                alert(msg);
                console.log(msg);
              })
              .finally(() => {
                loading({ type: "COMPLETE" });
              });
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    if (translateProject.project_status !== "Completed") {
      return;
    }
    ProjectApi.GetTranslateProjectCompleteHistory(translateProject.id ? translateProject.id : -1)
      .then((res) => {
        console.log(res);
        if (!res.data || res.data.length <= 0) {
          return;
        }
        // 가장 최근에 생성된 complete_type이 3인 데이터 찾기
        const mostRecentDataWithType3 = res.data.reduce(
          (prevData: ProjectCompleteHistory, currentData: ProjectCompleteHistory) => {
            if (!prevData) {
              return currentData;
            }
            const prevDataTime = new Date(prevData.time_created).getTime();
            const currentDataTime = new Date(currentData.time_created).getTime();

            if (currentDataTime > prevDataTime && currentData.complete_type === 3) {
              return currentData;
            } else {
              return prevData;
            }
          },
          null
        );

        // 가장 최근에 생성된 complete_type이 3인 데이터 찾기
        const mostRecentDataWithType2 = res.data.reduce(
          (prevData: ProjectCompleteHistory, currentData: ProjectCompleteHistory) => {
            if (!prevData) {
              return currentData;
            }
            const prevDataTime = new Date(prevData.time_created).getTime();
            const currentDataTime = new Date(currentData.time_created).getTime();

            if (currentDataTime > prevDataTime && currentData.complete_type === 2) {
              return currentData;
            } else {
              return prevData;
            }
          },
          null
        );

        if (mostRecentDataWithType3 && mostRecentDataWithType3.complete_type) {
          setProjectCompleteHistory(mostRecentDataWithType3.complete_type === 3 ? mostRecentDataWithType3 : null);
        }

        if (mostRecentDataWithType2 && mostRecentDataWithType2.complete_type) {
          setProjectReOpenHistory(mostRecentDataWithType2.complete_type === 2 ? mostRecentDataWithType2 : null);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [pageNum, translateProject.id]);

  useEffect(() => {
    ProjectApi.getPagememo(translateProject.id ? translateProject.id : -1, pageNum)
      .then((res) => {
        // 1. Filter data where memo_disable is false
        const enabledMemos = res.data.filter((memo: Memo) => !memo.memo_disable);

        // 2. Group the filtered data by memo_type
        const groupedMemos: { [key: number]: Memo[] } = enabledMemos.reduce((acc: any, memo: Memo) => {
          (acc[memo.memo_type] = acc[memo.memo_type] || []).push(memo);
          return acc;
        }, {});

        // 3. From each group, get the item with the latest time_created
        const latestMemos = Object.values(groupedMemos).map((memos: Memo[]) => {
          return memos.reduce((latest: Memo, current: Memo) => {
            return new Date(current.time_created) > new Date(latest.time_created) ? current : latest;
          });
        });

        let HasCommentTranslator = false;
        let HasCommentAdmin = false;

        latestMemos.forEach((memo: Memo) => {
          if (memo.memo_type === 1) {
            setCommentAdmin(() => memo.memo_contents);
            HasCommentAdmin = true;
            console.log("admin", pageNum);
          } else if (memo.memo_type === 2) {
            setCommentTranslator(() => memo.memo_contents);
            HasCommentTranslator = true;
            console.log("translator", pageNum);
          }
        });

        if (!HasCommentAdmin) setCommentAdmin("");
        if (!HasCommentTranslator) setCommentTranslator("");

        setHasComments(HasCommentAdmin || HasCommentTranslator);
        setCommentCount((HasCommentAdmin ? 1 : 0) + (HasCommentTranslator ? 1 : 0));
        setIsVisible(() => {
          return HasCommentAdmin || HasCommentTranslator;
        });
      })
      .catch((error) => {
        console.error("Error occurred during promise execution:", error);
      })
      .finally(() => {});
  }, [pageNum, translateProject.id]);

  useEffect(() => {
    if (isFirstVisit) {
      setIsFirstVisit(() => {
        console.log("method called");
        return false;
      });
      handleTrigger();
    }
    let pageInfo = null;
    if (location.state && (location.state as PageInfo).page >= 0) {
      pageInfo = location.state;
      let page = (pageInfo as PageInfo).page;

      setPageNum(() => page);
      onLoad(page);
      setImageTextTargetList([]);
      setSelectedItem(INIT_SELECTED_ITEM);

      location.state = { ...location, state: { page: -1 } };
    } else {
      onLoad(pageNum);
    }
  }, []);

  const onPageNumChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    window.onbeforeunload = function (event: any) {
      event.preventDefault();
    };

    let isBlock = false;
    //전체 순회
    imageTextTargetList.forEach((element: ImageTextTargetManual, index: number) => {
      element.image_text_data.map((subElement: ImageTextDataManual, subIndex: number) => {
        // 빈 번역칸이 있거나, 기준문장을 그냥 썼을때, 번역 입력칸이 차있는데 저장을 하지 않았을때,
        if (
          // !imageTextTargetList[index].image_text_data[subIndex].target_sentence ||
          // imageTextTargetList[index].image_text_data[subIndex].is_basetext ||
          imageTextTargetList[index].image_text_data[subIndex].is_onWrite
        ) {
          isBlock = true;
        }
      });
    });

    if (isBlock && groupRole !== 2) {
      if (
        window.confirm(
          "Any unsaved content will be lost. Still want to move?\nPlease check if all activated buttons have been pressed."
        )
      ) {
        setPageNum(() => page);
        onLoad(page);
        setImageTextTargetList([]);
        setSelectedItem(INIT_SELECTED_ITEM);
      }
    } else {
      setPageNum(() => page);
      onLoad(page);
      setImageTextTargetList([]);
      setSelectedItem(INIT_SELECTED_ITEM);
    }
  };

  const onSubPageNumChangePagination = (event: any, page: number) => {
    setSubPageNum(page);
  };

  const onWriteUnit = (imageTextData: ImageTextDataManual, index: number, subIndex: number) => {
    imageTextTargetList[index].image_text_data[subIndex].is_onWrite = true;
    setImageTextTargetList(imageTextTargetList);
  };
  const onSaveUnit = (targetTextData: ImageTextDataManual, index: number, subIndex: number, isSaved: boolean) => {
    // console.log(targetTextData, isSaved);
    // 번역과제 정보가 없다면 skip
    if (!translateProject.id) {
      return;
    }

    // targettext 있는지 먼저 확인
    ProjectApi.GetTargetTextBySourceId(targetTextData.source_id, translateProject.target_lang_code, translateProject.id)
      .then((res) => {
        // 번역문장 id를 알고있거나, 번역문장을 검색했을때 존재한다면,
        if (targetTextData.target_id || res.data.id) {
          ProjectApi.UpdateTargetTextBySourceId(targetTextData, translateProject, user, "image")
            .then((res) => {
              console.log(res, "success");
              // imageTextTargetList[index].image_text_data[subIndex].is_basetext = false;
              imageTextTargetList[index].image_text_data[subIndex].is_onWrite = false;
              setImageTextTargetList(imageTextTargetList);
            })
            .catch((e) => {
              let msg = ErrorHandler(e);
              console.log(msg);
            });
        } else {
          ProjectApi.AddTargetText(targetTextData, translateProject, user, "image")
            .then((res) => {
              console.log(res);
              // imageTextTargetList[index].image_text_data[subIndex].is_basetext = false;
              imageTextTargetList[index].image_text_data[subIndex].is_onWrite = false;
              setImageTextTargetList(imageTextTargetList);
            })
            .catch((e) => {
              let msg = ErrorHandler(e);
              console.log(msg);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterUntranslated = (data: SourceTargetMatching[]) => {
    return data.filter((item) => item.is_translated === false);
  };

  // 프로젝트의 번역가 마감
  const onCloseProject = (id: number) => {
    if (window.confirm("" + t("dialogue-confirm-close"))) {
      loading({ type: "LOADING" });
      ProjectApi.getTranslateCompareSourceTargetByProjectCode(id)
        .then((res) => {
          const untranslatedData = filterUntranslated(res.data);
          if (untranslatedData && untranslatedData.length > 0) {
            let noList: number[] = [];
            untranslatedData.map((item: SourceTargetMatching) => {
              noList.push(item.page_no);
            });
            loading({ type: "COMPLETE" });
            const uniqueNoList: Set<number> = new Set(noList);
            alert("Translation not completed on the following pages.\n" + Array.from(uniqueNoList).join(", "));
          } else {
            ProjectApi.CloseTranslateProject(id)
              .then(() => {
                history.push(MyRoutes.translatorProject);
                console.log("close success");
              })
              .catch((e) => {
                let msg = ErrorHandler(e);
                console.log(e);
                setIsClosed(true);
                if (e.response.status === 501) {
                  window.alert(e.response.data.message);
                } else {
                  console.log(e.response);
                }
              })
              .finally(() => {
                loading({ type: "COMPLETE" });
              });
          }
        })
        .catch((e) => {});
    }
  };

  // 프로젝트의 관리자 마감
  const onFinishProject = (id: number) => {
    if (window.confirm("" + t("dialogue-confirm-finish"))) {
      loading({ type: "LOADING" });
      ProjectApi.FinishTranslateProject(id)
        .then(() => {
          history.push(MyRoutes.projectManagementComplete);
          console.log("finish success");
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
          setIsFinished(true);
          alert("An error has occurred.\nPlease contact the Infrastructure Development Department at headquarters.");
        })
        .finally(() => {
          loading({ type: "COMPLETE" });
        });
    }
  };

  // 프로젝트의 마감취소
  const onReopenProject = (id: number) => {
    if (window.confirm("" + t("dialogue-confirm-reopen"))) {
      loading({ type: "LOADING" });
      ProjectApi.ReopenProject(id)
        .then(() => {
          console.log("reopen success");
          history.push(MyRoutes.projectManagementComplete);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
          alert("An error has occurred.\nPlease contact the Infrastructure Development Department at headquarters.");
        })
        .finally(() => {
          loading({ type: "COMPLETE" });
        });
    }
  };
  const onClickAreaImage = (event: any) => {
    let imageUrl = event.target.src;
    if (imageUrl) {
      // Base64 데이터를 Blob으로 변환
      fetch(imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const newImageUrl = URL.createObjectURL(blob);
          window.open(newImageUrl, "_blank");
        })
        .catch((error) => {
          console.error("Error converting image to Blob:", error);
        });
    }
  };

  // 입력 필드에서 값을 변경했을 때 호출됩니다.
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  // 코멘트 작성
  const handleCommentTranslatorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentTranslator(event.target.value);
  };
  // 코멘트 작성
  const handleCommentAdminChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentAdmin(event.target.value);
  };

  const changePageNumber = (page: number) => {
    let isBlock = false;
    //전체 순회
    imageTextTargetList.forEach((element: ImageTextTargetManual, index: number) => {
      element.image_text_data.map((subElement: ImageTextDataManual, subIndex: number) => {
        // 번역 입력칸이 차있는데 저장을 하지 않았을때 확인창 띄움
        if (imageTextTargetList[index].image_text_data[subIndex].is_onWrite) {
          isBlock = true;
        }
      });
    });

    if (isBlock && groupRole !== 2) {
      if (
        window.confirm(
          "Any unsaved content will be lost. Still want to move?\nPlease check if all activated buttons have been pressed."
        )
      ) {
        setPageNum(() => page);
        onLoad(page);
        setImageTextTargetList([]);
        setSelectedItem(INIT_SELECTED_ITEM);
      }
    } else {
      setPageNum(() => page);
      onLoad(page);
      setImageTextTargetList([]);
      setSelectedItem(INIT_SELECTED_ITEM);
    }
  };

  // PageNumberRef 값으로 페이지 이동
  const handleGoClick = () => {
    if (!pageNumberRef.current) return;
    const newPage = Number(pageNumberRef.current?.value);

    if (!isNaN(newPage) && newPage >= 1 && newPage <= wholePageNum) {
      changePageNumber(newPage);
    } else {
      alert("Invalid page number");
    }
  };
  type CombinedData = {
    managed_text: string;
    time_created: string;
  };

  const UPDATE_BUFFER_TIME = 5 * 60 * 1000;

  const checkTrue = (index: number, subIndex: number) => {
    let translateInfotemp = imageTextTargetList[index].image_text_data[subIndex];
    let basetexttemp = translateInfotemp.basetext;
    let basetext_historytemp = translateInfotemp.basetext_history;

    if (index >= 0 && subIndex >= 0) {
      if (basetexttemp.length > 0) {
        if (basetext_historytemp.length > 0)
          if (basetext_historytemp[0].history_data.length > 0) {
            if (!projectReOpenHistory) {
              // Basetext 변경 여부는 reopen이력을 기준으로 판단
              console.log(index, subIndex, basetexttemp, basetext_historytemp, "no reopen data");
            }
          }
      }
    }

    if (!projectCompleteHistory || !projectReOpenHistory || projectCompleteHistory.complete_type !== 3) {
      return false;
    }
    let translateInfo = imageTextTargetList[index].image_text_data[subIndex];
    let basetext = translateInfo.basetext;
    let basetext_history = translateInfo.basetext_history;
    let target_sentence = translateInfo.target_sentence;
    if (index >= 0 && subIndex >= 0) {
      if (basetext.length > 0) {
        if (basetext_history.length > 0)
          if (basetext_history[0].history_data.length > 0) {
            // 재오픈 시 사용, history가 없는 데이터는 의미 없는 데이터
            // Basetext와 History가 함께 존재

            // 데이터 결합
            // const combinedData = createCombinedData(index, subIndex);
            // 프로젝트 종료시점으로 가장 가까운 1개 추출
            const latestEntries = basetext_history[0].history_data
              .filter((data) => {
                const dataTime = new Date(data.time_created).getTime();
                // FIXME : Backend에서 History데이터에 Basetext의 생성 시간을 넣을때까지 유지
                // const projectTime = new Date(projectReOpenHistory.time_created).getTime();
                const projectTime = new Date(projectCompleteHistory.time_created).getTime();
                // FIXME dataTime과 projectTime의 차이가 10분 이내의 데이터만 추출
                return dataTime < projectTime && projectTime - dataTime < UPDATE_BUFFER_TIME;
              })
              .sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime())
              .slice(0, 1);
            return (
              latestEntries && latestEntries.length > 0 && latestEntries[0].history_managed_text !== target_sentence
            );
          }
      }
    }

    // todo : 위 조건에 걸리지 않는 경우 check
  };

  const handleItemClick = (
    newData: ImageTextDataManual,
    index: number,
    subIndex: number,
    lastTwoSentences: string[],
    projectReOpenHistory: ProjectCompleteHistory,
    isClosed: boolean | undefined
  ) => {
    const selectedItem = {
      newData,
      index,
      subIndex,
      lastTwoSentences,
      projectReOpenHistory,
      isClosed: isClosed ?? false, // isClosed가 undefined일 경우 false로 처리
      isFinished: isFinished,
    };

    setSelectedItem(selectedItem);
  };

  // #region ResizeObserver 객체 생성

  // Ref 객체 생성 (HTMLDivElement 타입을 명시)
  const elementRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLDivElement | null>(null);

  // 상태를 사용하여 요소의 크기를 저장 (Size 인터페이스 사용)
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  useEffect(() => {
    const observeTarget = elementRef.current;
    if (!observeTarget) return;

    // ResizeObserver 콜백 함수 정의
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      }
    });

    // 요소 관찰 시작
    resizeObserver.observe(observeTarget);

    // 컴포넌트 언마운트 시, 관찰 중단
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const observeTarget = imageRef.current;
    if (!observeTarget) return;

    // ResizeObserver 콜백 함수 정의
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setImageSize({ width, height });
      }
    });

    // 요소 관찰 시작
    resizeObserver.observe(observeTarget);

    // 컴포넌트 언마운트 시, 관찰 중단
    return () => resizeObserver.disconnect();
  }, []);
  // #endregion

  // #region 이미지/요소 로딩 완료시, 정보 업데이트
  const [imageLoaded, setImageLoaded] = useState(false);
  const [elementLoaded, setElementLoaded] = useState(false);

  useEffect(() => {
    if (imageRef.current) {
      const { offsetWidth, offsetHeight } = imageRef.current;
      // 초기 크기 설정
      setImageSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [imageLoaded]);

  useEffect(() => {
    if (elementRef.current) {
      const { offsetWidth, offsetHeight } = elementRef.current;
      // 초기 크기 설정
      setSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [elementLoaded]);
  // #endregion

  // #region 탭 변경 이벤트 핸들러
  const [value, setValue] = React.useState(2);

  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };
  // #endregion

  // #region 페이지별 메모 패널 트리거
  const [trigger, setTrigger] = useState(false);

  const handleTrigger = () => {
    setTrigger(true);
  };
  const resetTrigger = () => {
    setTrigger(false);
  };
  // #endregion

  // #region User의 권한 확인
  const [groupRole, setGroupRole] = useState<number>(0); // 시스템관리자 : 1, 본사 인원 : 2
  const getUserRole = React.useCallback(() => {
    const decoded: any = jwt_decode(UserApi.GetToken());
    setGroupRole(decoded.sub.group_role[0]);
  }, []);

  useEffect(() => {
    getUserRole();
  }, [getUserRole]);
  // #endregion

  return (
    <Grid container spacing={1}>
      <>
        {groupRole !== 2 && (
          <Grid item xs={12} container justifyContent="flex-end">
            {!_trans?.is_closed ? (
              // 번역가 마감 버튼
              <SaveButton
                onClick={() => {
                  onCloseProject(parseInt(translate_project_code));
                }}
                disabled={translateProject.is_closed || isClosed}
                title={
                  "Final Submission button:\nIf you clicked on all 'Finish/Check Button', and submitted all translated sentences,\nplease click on 'Final Submission' button for administrator's final approval."
                }
              >
                {t("final-submission")}
              </SaveButton>
            ) : (
              // 관리자 마감취소버튼 & 관리자 마감버튼
              <>
                <div>
                  <SaveButton
                    onClick={() => {
                      let translate_project_id =
                        translate_project_code && translate_project_code !== ""
                          ? translate_project_code
                          : _trans?.id + "";
                      onReopenProject(parseInt(translate_project_id));
                    }}
                    color="secondary"
                    // 번역가 마감 상태일때만 re-open 가능하도록 변경
                    disabled={!translateProject.is_closed}
                    style={{ marginRight: "28px" }}
                  >
                    Reopen project
                  </SaveButton>
                  <SaveButton
                    onClick={() => {
                      let translate_project_id =
                        translate_project_code && translate_project_code !== ""
                          ? translate_project_code
                          : _trans?.id + "";
                      onFinishProject(parseInt(translate_project_id));
                    }}
                    disabled={translateProject.project_status === "Completed" || isFinished}
                  >
                    final submission (admin)
                  </SaveButton>
                </div>
              </>
            )}
          </Grid>
        )}
        <Grid item xs={12} container justifyContent="space-between">
          <div className={classes.root}>
            <TextField
              label="Go to page"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleGoClick();
                  handleTabChange(e, 2);
                }
              }}
              type="number"
              inputRef={pageNumberRef}
            />
            <Button
              onClick={
                // handleGoClick 실행 이후에 handletabchange 실행
                (e) => {
                  handleGoClick();
                  handleTabChange(e, 2);
                }
              }
            >
              Go
            </Button>
          </div>
          <div className={classes.root}>
            <Pagination
              count={wholePageNum}
              defaultValue={1}
              siblingCount={3}
              page={pageNum}
              showFirstButton
              showLastButton
              size={"large"}
              color="primary"
              onChange={
                // handleInputChange 실행 이후에 handletabchange 실행
                (e: React.ChangeEvent<unknown>, page: number) => {
                  onPageNumChangePagination(e, page);

                  // 페이지 전환 시, 가이드 탭으로 전환
                  handleTabChange(e, 2);
                }
              }
            ></Pagination>
          </div>

          <div className={classes.root}>
            <ActionButton
              style={{
                backgroundColor: "#dcdcdc",
                marginRight: "24px",
                color: "black",
              }}
              size="small"
              variant="contained"
              onClick={(event) => {
                event.stopPropagation();
                setIsModalSummaryOpen(true);
              }}
              title={"Clicking on PREVIEW shows project's full translations."}
            >
              SUMMARY
            </ActionButton>
            <SideMemoPanel
              translate_project_id={translateProject?.id ?? 0}
              translate_project={translateProject}
              _changePageNumber={changePageNumber}
              trigger={trigger}
              _resetTrigger={resetTrigger}
            ></SideMemoPanel>
            <Button
              onClick={handleToggleVisibility}
              variant="contained"
              style={{
                // backgroundColor: hasComments ? "lightgray" : PRIMARY, // 코멘트가 있으면 배경색을 변경
                backgroundColor: "lightgray", // TODO : 코멘트 갯수와 상관없이 회색으로 표시
                // color: hasComments ? "black" : "white",
                color: "black",
              }}
              size="small"
              title={
                "Send Memo Button:\nIf you have any suggestions/questions on the page you are in, click on 'SEND MEMO' to convey to the headquarters. \nAdministrator will respond by using the 'SEND MEMO'."
              }
            >
              <Badge
                color="error" // 빨간색으로 설정
                variant="dot" // 점으로 표시
                invisible={commentCount <= 0} // commentCount가 0 이하일 때는 숨김
                anchorOrigin={{
                  vertical: "top", // 상단에 위치
                  horizontal: "left", // 왼쪽에 위치
                }}
              >
                send memo
              </Badge>
            </Button>
          </div>
        </Grid>

        {/* 메모 기능 */}
        <Grid container className={classes.commentArea}>
          {isVisible && (
            <>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <div className={classes.root2}>
                  From Translator
                  <div style={{ display: "flex", height: "100%" }}>
                    <textarea
                      rows={4}
                      cols={100}
                      style={{ width: "100%", marginRight: theme.spacing(1), resize: "none" }} // 여백 추가
                      placeholder={!user.admin ? "Please input comment..." : "(Empty)"}
                      value={commentTranslator}
                      onChange={handleCommentTranslatorChange}
                      disabled={user.admin}
                    />
                    {!user.admin ? (
                      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <Button
                          onClick={handleSaveCommentTranslatorClick}
                          variant="contained"
                          color="primary"
                          disabled={user.admin}
                          style={{ flexGrow: 1 }} // 버튼이 컨테이너 높이에 꽉 차도록 설정
                        >
                          Send
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Grid>

              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <div className={classes.root2}>
                  From Administrator
                  <div style={{ display: "flex", height: "100%" }}>
                    <textarea
                      rows={4}
                      cols={100}
                      style={{ width: "100%", marginRight: theme.spacing(1), resize: "none" }} // 여백 추가
                      placeholder={user.admin ? "Please input comment..." : "(Empty)"}
                      value={commentAdmin}
                      onChange={handleCommentAdminChange}
                      disabled={!user.admin}
                    />
                    {user.admin ? (
                      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <Button
                          onClick={handleSaveCommentAdminClick}
                          variant="contained"
                          color="primary"
                          disabled={!user.admin}
                          style={{ flexGrow: 1 }} // 버튼이 컨테이너 높이에 꽉 차도록 설정
                        >
                          Send
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
            </>
          )}
        </Grid>
        {/* todo: : 우크라이나용 결과지 전체 공개 */}
        <Grid xs={10} className={classes.textFieldContainer}>
          <Grid container justifyContent="space-around" className={classes.textField}>
            {subProject.project_type === 2 ? (
              <Grid item xs={2}>
                <div className={classes.toolBar}>
                  {sector_image ? (
                    <button onClick={onClickAreaImage}>
                      <img
                        src={"data:image/png;base64," + sector_image}
                        width="200px"
                        height="320px"
                        max-height="600px"
                        id="click_image"
                        alt="No_Image"
                      ></img>
                    </button>
                  ) : (
                    <button style={{ cursor: "default" }}>
                      <img
                        src={"/no-sector-image.png"}
                        width="200px"
                        height="320px"
                        max-height="600px"
                        id="click_image"
                        alt="No_Image"
                      ></img>
                    </button>
                  )}
                </div>
              </Grid>
            ) : (
              <></>
            )}
            <Grid
              xs={subProject.project_type === 2 || translateProject.id === 1279 ? 10 : 12}
              container
              justifyContent="space-around"
            >
              {imageTextTargetList.map((item: ImageTextTargetManual, index: number) => {
                // 번역가 번역 Component에서 Png여부
                let isPng = false;
                if (item) {
                  isPng = atob(item.encoded_image_string).slice(0, 5).indexOf("PNG") >= 0;
                }
                return (
                  <>
                    <p>
                      <h5>
                        [ {pageNum}
                        {" - "}
                        {index + 1} ]
                      </h5>
                    </p>
                    {isAdmin ? (
                      <p>
                        <h5>{item.extracted_filename}</h5>
                      </p>
                    ) : (
                      <></>
                    )}
                    <Grid container xs={12} className={classes.gridBorder} justifyContent="space-around">
                      <Grid item xs={5} className={(index - (index % 10)) / 10 + ""}>
                        {item && (
                          <div
                            ref={elementRef}
                            onLoad={() => setElementLoaded(true)}
                            className={classes.imageSize}
                            // style={{ height: "40vh" }}
                            style={{
                              minHeight: "170px",
                              maxHeight: "70vh",
                              objectFit: "cover",
                              position: "sticky",
                              top: 0 /* 상단에 고정 */,
                            }}
                          >
                            {/* {size.width}
                            <br />
                            {size.height}
                            <br />
                            {imageSize.width}
                            <br />
                            {imageSize.height}
                            <br /> */}
                            {/* <TransformWrapper centerOnInit initialScale={0.4} minScale={0.4}> */}
                            <TransformWrapper
                              centerOnInit={true}
                              doubleClick={{ mode: "reset" }}
                              initialScale={1}
                              minScale={0.3}
                              maxScale={9}
                              limitToBounds={false}
                              // initialPositionX={(size.width - imageSize.width) / 2}
                              // initialPositionY={(size.height - imageSize.height) / 2}
                              velocityAnimation={{ disabled: true }}
                            >
                              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <div
                                  style={{ position: "relative", width: "100%", height: "100%", minHeight: "170px" }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      minHeight: "170px",
                                      top: 0,
                                      right: 0,
                                      padding: "10px",
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "10px", // Add space between buttons
                                      zIndex: 40, // Ensure the buttons are above other content
                                    }}
                                  >
                                    <ActionButton onClick={() => zoomIn()}>
                                      <h5>+</h5>
                                    </ActionButton>
                                    <ActionButton onClick={() => zoomOut()}>
                                      <h5>-</h5>
                                    </ActionButton>
                                    <ActionButton onClick={() => resetTransform()}>reset</ActionButton>
                                  </div>
                                  <TransformComponent>
                                    {/* <div className="tools"> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center", // 수평 중앙 정렬
                                        alignItems: "center", // 수직 중앙 정렬
                                        height: "100%",
                                        minHeight: "170px",
                                      }}
                                      ref={imageRef}
                                    >
                                      {/* todo : 이미지 확장자 정보는 Props로 받을 수 있도록 고려 */}
                                      {isPng ? (
                                        <>
                                          <img
                                            src={"data:image/png;base64," + item.encoded_image_string}
                                            alt={"image"}
                                            onLoad={() => setImageLoaded(true)}
                                            style={{ maxHeight: "70vh" }}
                                          ></img>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={"data:image/svg+xml;base64," + item.encoded_image_string}
                                            alt={"image"}
                                            onLoad={() => setImageLoaded(true)}
                                            style={{ maxHeight: "70vh" }}
                                          ></img>
                                        </>
                                      )}
                                    </div>
                                  </TransformComponent>
                                </div>
                              )}
                            </TransformWrapper>
                          </div>
                        )}
                        {!item && <div>{t("image-loading")}</div>}
                      </Grid>
                      <Grid item xs={7} className={(index - (index % 10)) / 10 + " " + classes.pictureRight}>
                        {item.image_text_data.map((data, subIndex) => {
                          // 1임시
                          var lastTwoSentences: string[] = [];

                          if (
                            imageTextTargetList[index].image_text_data[subIndex].target_sentence &&
                            imageTextTargetList[index].image_text_data[subIndex].basetext_history.length > 0 &&
                            projectReOpenHistory
                          ) {
                            let basetext_history =
                              imageTextTargetList[index].image_text_data[subIndex].basetext_history;
                            // 프로젝트 종료시점으로 가장 가까운 1개 추출
                            const latestEntries = basetext_history[0].history_data
                              .filter((historyData) => {
                                const dataTime = new Date(historyData.time_created).getTime();
                                // FIXME : Backend에서 History데이터에 Basetext의 생성 시간을 넣을때까지 유지
                                // const projectTime = new Date(projectReOpenHistory.time_created).getTime();
                                const projectTime = new Date(projectCompleteHistory.time_created).getTime();
                                // FIXME dataTime과 projectTime의 차이가 10분 이내의 데이터만 추출
                                return dataTime < projectTime && projectTime - dataTime < UPDATE_BUFFER_TIME;
                              })
                              .sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime())
                              .slice(0, 1);
                            // 입력문장과 재오픈 이전의 basetext가 다를때
                            if (
                              latestEntries &&
                              latestEntries.length > 0
                              //  &&
                              // imageTextTargetList[index].image_text_data[subIndex].target_sentence !==
                              //   latestEntries[0].history_managed_text
                            ) {
                              lastTwoSentences = [
                                latestEntries[0].history_managed_text,
                                imageTextTargetList[index].image_text_data[subIndex].target_sentence,
                              ];
                            } else {
                              lastTwoSentences = [
                                "",
                                imageTextTargetList[index].image_text_data[subIndex].target_sentence,
                              ];
                            }

                            lastTwoSentences = lastTwoSentences.map((sentence) =>
                              sentence.includes("##No translation needed##") ? "##No translation needed##" : sentence
                            );
                          } else {
                            // console.log(
                            //   imageTextTargetList[index].image_text_data[subIndex].target_sentence,
                            //   imageTextTargetList[index].image_text_data[subIndex].basetext_history,
                            //   "no data 2"
                            // );
                          }
                          // 1임시 끝
                          return (
                            <>
                              <div
                                style={{ backgroundColor: checkTrue(index, subIndex) ? "yellow" : "" }}
                                onClick={() =>
                                  handleItemClick(
                                    data,
                                    index,
                                    subIndex,
                                    lastTwoSentences,
                                    projectReOpenHistory,
                                    _trans?.is_closed
                                  )
                                }
                              >
                                <TranslationInputManual
                                  _data={data}
                                  _index={index}
                                  _subIndex={subIndex}
                                  _onSaveUnit={onSaveUnit}
                                  _onWriteUnit={onWriteUnit}
                                  _isFinished={_trans?.is_closed}
                                  setIsEdited={setIsEdited}
                                  _lastTwoSentences={lastTwoSentences}
                                  _trans={_trans}
                                  _projectReOpenHistory={projectReOpenHistory}
                                  _selectedSentence={selectedSentence}
                                  // TranslationInputManual에서 사용할 함수를 전달
                                  _handleTabChange={handleTabChange}
                                  // 수정이 불가능한 경우 : 결과지 프로젝트가 아니면서 Basetext가 절대언어인 경우
                                  _isReadOnly={
                                    subProject?.project_type !== 2 &&
                                    data.basetext.length > 0 &&
                                    data.basetext[0].is_absolute_text
                                  }
                                ></TranslationInputManual>
                              </div>

                              <br></br>
                            </>
                          );
                        })}
                        {item.image_text_data.length === 0 ? (
                          <div className={classes.info}>
                            <Typography variant="h6">
                              {"<"}
                              {t("guide-no-sentence")}
                              {">"}
                            </Typography>
                            <Typography variant="h6">{t("guide-go-next")}</Typography>
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {/* 왼쪽 부분 (기존 번역 문장 입력 및 참고 문장 제공 부분) */}
          {/* 오른쪽 부분 (데이터 표시) */}

          <div className={classes.rootTab}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              classes={{ flexContainer: classes.flexContainer }}
              variant="fullWidth"
            >
              <Tab label={"Trans Info"} value={1} classes={{ root: classes.tabRoot }} />
              <Tab label={"Guide"} value={2} classes={{ root: classes.tabRoot }} />
            </Tabs>
          </div>
          {value === 1 && selectedItem !== INIT_SELECTED_ITEM && (
            <>
              {/* <div className="markdown-content">
                <div style={{ fontSize: "11px" }}>
                  <ReactMarkdown children={t("translationInfoHeader")} />
                </div>
              </div> */}
              <TranslationGuide
                _selectedItem={selectedItem}
                _setSelectedSentence={setSelectedSentence}
                // 수정이 불가능한 경우 : 결과지 프로젝트가 아니면서 Basetext가 절대언어인 경우
                _isReadOnly={
                  translateProject?.project_type !== 2 &&
                  selectedItem.newData.basetext.length > 0 &&
                  selectedItem.newData.basetext[0].is_absolute_text
                }
              ></TranslationGuide>
            </>
          )}
          {value === 2 && (
            <div className={classes.rightContainer}>
              <p>
                <div className="markdown-content">
                  <div style={{ fontSize: "11px" }}>
                    <ReactMarkdown children={t("translationGuide")} />
                  </div>
                </div>
              </p>
            </div>
          )}
        </Grid>
      </>
      <SourceTargetMatchingModalContent
        _translateProject={translateProject}
        isOpen={isModalSummaryOpen}
        onClose={() => setIsModalSummaryOpen(false)}
        _changePageNumber={changePageNumber}
      ></SourceTargetMatchingModalContent>
    </Grid>
  );
}
export default TranslatorComponent;
