import { makeStyles } from "@material-ui/core/styles";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { ErrorHandler, ProjectApi } from "system/ApiService"; // API 호출 함수를 포함하는 파일 경로에 따라 경로를 조정해주세요.
import { IsFirstVisitAtom, MainProjectAtom, SubProjectAtom, WholeMemoDataAtom } from "system/atom"; // 상태 정의 파일 경로에 따라 경로를 조정해주세요.
import { DesignFile, Memo, TranslateProject } from "system/types";
import { checkValidArray, checkValidString, convertISOToStandardFormat, getTitleByField } from "system/util";

import { ActionButton } from "./Button";
import DownloadButton from "./Button/DownloadExcelButton";

import { Button } from "@material-ui/core";
import { PRIMARY, SECONDARY } from "style/theme";
import * as XLSX from "xlsx";
import { useLoadingDispatch } from "system/LoadingContext";
import { t } from "i18next";
import { useUserState } from "system/UserContext";

const useStyles = makeStyles({
  sideMemoPanel: {
    width: 0,
    position: "fixed",
    zIndex: 9999,
    top: "64px",
    left: 0,
    overflowX: "hidden",
    overflowY: "scroll",
    transition: "0.5s",
    padding: "12px",
    height: "calc(100vh - 64px)",
    border: "2px solid #111",
    marginRight: "1px",
    backgroundColor: "#fff",
    "& a": {
      padding: "8px 8px 8px 32px",
      display: "block",
      transition: "0.3s",
    },
  },
  open: {
    display: "block",
    width: "60%",
  },

  button: {
    dispalay: "inline-block",
  },
  info: {
    textAlign: "center",
  },
  root: {
    margin: "10px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  fileInputLabel: {
    marginRight: "10px",
  },
  fileCheck: {
    width: "100%",
    margin: "10px 0px",
    padding: "10px 0px",
    float: "right",
  },
});

const tableStyle: React.CSSProperties = {
  minWidth: "100%",
  borderCollapse: "collapse",
  overflow: "hidden",
};

const tableRowStyle: React.CSSProperties = {
  borderBottom: "1px solid #ccc",
};
const tableSmallCellStyle: React.CSSProperties = {
  padding: "8px",
  border: "1px solid #ccc",
  width: "5%",
};
const tableCellStyle: React.CSSProperties = {
  padding: "8px",
  border: "1px solid #ccc",
  width: "45%",
  wordBreak: "break-all",
};
const headerStyle: React.CSSProperties = {
  position: "sticky",
  top: -15,
  backgroundColor: "#fff",
  zIndex: 1000,
  overflow: "hidden",
};

const theadStyle: React.CSSProperties = {
  position: "sticky",
  zIndex: 500,
  backgroundColor: "#fff",
};
const scrollableContentStyle: React.CSSProperties = {
  maxHeight: "80%",
  // overflowY: "auto",
};

const tableCellStyleNoTranslation: React.CSSProperties = {
  background: "lightgray",
  padding: "8px",
  border: "1px solid #ccc",
  width: "45%",
};

interface SideMemoPanelProps {
  translate_project_id: number;
  translate_project: TranslateProject;
  _changePageNumber: (page: number) => void;
  trigger: boolean;
  _resetTrigger: () => void;
}

// function BaseProductDialog({ open, id, type, saveCallback, onClose }: ProductDialogProps) {
// const SideMemoPanel: React.FC<{ translate_project_id: number }> = ({ translate_project_id }) => {
function SideMemoPanel({
  translate_project_id,
  translate_project,
  _changePageNumber,
  trigger,
  _resetTrigger,
}: SideMemoPanelProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [memoData, setMemoData] = useRecoilState(WholeMemoDataAtom);

  const [mainProject, setMainProject] = useRecoilState(MainProjectAtom);
  const [subProject, setSubProject] = useRecoilState(SubProjectAtom);

  const [expandedRows, setExpandedRows] = React.useState<ExpandedRows>({});

  const [hasDesignFile, setHasDesignFile] = useState<boolean>(false);

  const [designFileUrl, setDesignFileUrl] = useState<string>("");

  const [isFirstVisit, setIsFirstVisit] = useRecoilState<boolean>(IsFirstVisitAtom);

  const user = useUserState();

  const toggleHistory = (index: number) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  type ExpandedRows = {
    [key: number]: boolean;
  };

  interface SortedMemoHistory {
    page_no: number;
    type1?: Memo[]; // memo_type이 1인 요소
    type2?: Memo[]; // memo_type이 2인 요소
  }

  const INIT_SORTED_MEMO_HISTORY: SortedMemoHistory = {
    page_no: 0,
    type1: [], // memo_type이 1인 요소
    type2: [], // memo_type이 2인 요소
  };

  interface RefinedMemo {
    page_no: number;
    type1?: Memo; // memo_type이 1인 요소
    type2?: Memo; // memo_type이 2인 요소
  }

  const refineMemoData = (data: Memo[]): RefinedMemo[] => {
    const sortedData = [...data].sort((a, b) => {
      if (a.page_no !== b.page_no) {
        return a.page_no - b.page_no;
      }
      return a.memo_type - b.memo_type;
    });

    const resultMap: { [key: number]: RefinedMemo } = {};
    sortedData.forEach((memo) => {
      if (!resultMap[memo.page_no]) {
        resultMap[memo.page_no] = { page_no: memo.page_no };
      }
      if (memo.memo_type === 1) {
        resultMap[memo.page_no].type1 = memo;
      } else if (memo.memo_type === 2) {
        resultMap[memo.page_no].type2 = memo;
      }
    });

    return Object.values(resultMap);
  };

  const refineHistoryData = (data: Memo[]): SortedMemoHistory[] => {
    const sortedData = [...data].sort((a, b) => {
      if (a.page_no !== b.page_no) {
        return a.page_no - b.page_no;
      }
      return a.memo_type - b.memo_type;
    });

    const resultMap: { [key: number]: SortedMemoHistory } = {};
    sortedData.forEach((memo) => {
      if (!resultMap[memo.page_no]) {
        resultMap[memo.page_no] = { page_no: memo.page_no, type1: [], type2: [] };
      }
      if (memo.memo_type === 1) {
        resultMap[memo.page_no].type1?.push(memo);
      } else if (memo.memo_type === 2) {
        resultMap[memo.page_no]?.type2?.push(memo);
      }
    });

    // `type1`과 `type2`를 각각 역순으로 정렬
    Object.values(resultMap).forEach((sortedMemoHistory) => {
      sortedMemoHistory.type1?.sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime());
      sortedMemoHistory.type2?.sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime());
    });
    return Object.values(resultMap);
  };

  const [modalMemoContent, setModalMemoContent] = React.useState<RefinedMemo[]>([]);
  const [sortedMemoHistory, setSortedMemoHistory] = React.useState<SortedMemoHistory[]>([]);

  const getWholePageMemo = (isOpenSidePanel?: boolean) => {
    ProjectApi.getWholePagememo(translate_project_id)
      .then((result) => {
        if (result.data.length > 0) {
          result.data = result.data.filter((memo: Memo) => memo.memo_disable === false);
        }
        const refinedData = refineMemoData(result.data);
        const refinedHistoryData = refineHistoryData(result.data);
        console.log(refinedData);
        setModalMemoContent(refinedData);
        setSortedMemoHistory(refinedHistoryData);
        setMemoData(result.data);
        if (isOpenSidePanel && result.data && result.data.length > 0) {
          openPanel();
        }
      })
      .catch((error) => console.error("Failed to load memos", error));
  };

  useEffect(() => {
    if (
      memoData === null ||
      memoData === undefined ||
      memoData.length === 0 ||
      (memoData.length > 0 && memoData[0].translate_project_id !== translate_project_id)
    ) {
      getWholePageMemo(true);
    } else {
      if (isFirstVisit) {
        setIsOpen(isOpen);
        setIsFirstVisit(false);
      }

      const refinedData = refineMemoData(memoData);
      const refinedHistoryData = refineHistoryData(memoData);
      setModalMemoContent(refinedData);
      setSortedMemoHistory(refinedHistoryData);
      setMemoData(memoData);
    }
  }, [translate_project_id]);

  const openPanel = () => {
    setIsOpen(true);
  };
  const togglePanel = () => {
    setIsOpen(!isOpen);
  };
  // 클릭 이벤트 핸들러를 인라인 함수를 사용하여 정의
  const handleClick = (pageNo: number) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // 링크의 기본 동작 방지
    _changePageNumber(pageNo);
    togglePanel();
  };

  const handleDataCheck = (hasData: boolean) => {
    if (!hasData) {
      // TODO : 메모 데이터가 없을 때 필요한 처리 넣기
      console.log("data 없음");
    }
  };

  // 엑셀 생성 함수
  // 엑셀에 개요 추가
  const createOverviewWorksheet = (translateProject: TranslateProject) => {
    const today = new Date();
    const formattedDate = `${String(today.getFullYear()).slice(2)}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
    const overview = [
      { "[item]": "Download Date", "[Content]": formattedDate },
      { "[item]": "Product", "[Content]": mainProject.product_code },
      { "[item]": "Project Name", "[Content]": mainProject.project_name },
      { "[item]": "Category Name", "[Content]": subProject.project_name },
      { "[item]": "Source Language", "[Content]": getTitleByField(translateProject.source_lang_code) },
      { "[item]": "Target Language", "[Content]": getTitleByField(translateProject.target_lang_code) },
      { "[item]": "Is Closed", "[Content]": translateProject.is_closed },
      { "[item]": "Project Status", "[Content]": translateProject.project_status },
    ];

    return XLSX.utils.json_to_sheet(overview);
  };

  // 엑셀에 번역 필요한 항목 추가
  const createTranslationWorksheet = (data: any[]) => {
    // type1과 type2 컬럼을 Admin / Translator 로 변경
    let dataWithModifiedColumnName = data.map((item) => {
      const updatedItem: UpdatedDataType = {
        page_no: item.page_no,
      };

      if (item.type1) {
        updatedItem.Admin = item.type1;
      }

      if (item.type2) {
        updatedItem.Translator = item.type2;
      }

      return updatedItem;
    });
    console.log(dataWithModifiedColumnName);
    return XLSX.utils.json_to_sheet(dataWithModifiedColumnName);
  };

  type OriginalDataType = {
    page_no: number;
    type1?: string;
    type2?: string;
  };

  type UpdatedDataType = {
    page_no: number;
    Admin?: string;
    Translator?: string;
  };
  // 엑셀에 필요한 정보 추가
  const generateCustomWorkbook = () => {
    let filteredData = null;

    const includeColumns = ["page_no", "type1", "type2"];

    filteredData = modalMemoContent.map((entry: any) => {
      const result: { [key: string]: any } = {};

      if (includeColumns) {
        includeColumns.forEach((col) => {
          if (entry[col] !== undefined) {
            result[col] = col === "page_no" ? entry[col] : entry[col].memo_contents;
          }
        });
      } else {
        return entry;
      }
      return result;
    });

    const wb = XLSX.utils.book_new();

    const overviewWs = createOverviewWorksheet(translate_project);
    XLSX.utils.book_append_sheet(wb, overviewWs, "Project Overview");

    const translationWs = createTranslationWorksheet(filteredData);
    XLSX.utils.book_append_sheet(wb, translationWs, "Memo");

    return wb;
  };

  useEffect(() => {
    if (trigger) {
      getWholePageMemo(true);
      getReferenceFile();
      _resetTrigger(); // 부모에게 트리거 리셋 요청
    }
  }, [trigger]);

  const [hasUploadedFile, setHasUploadedFile] = useState(false);

  const getReferenceFile = () => {
    ProjectApi.getSubProjectDesignFileList(translate_project.sub_project_id + "")
      .then((res) => {
        console.log(subProject.id, res, res.data.length, "design file check");

        // 파일이 있다면 다운로드 링크 GET
        if (res.data.length > 0) {
          // time_created 내림차순 정렬
          const sortObjectsByTimeCreated = (arr: DesignFile[]): DesignFile[] => {
            return arr
              .slice()
              .filter((item) => {
                return item.uploaded_design_version === translate_project.id + "";
              })
              .sort((a, b) => {
                const timeA = new Date(a.time_created).getTime();
                const timeB = new Date(b.time_created).getTime();
                return timeB - timeA;
              });
          };

          let sortedFileList = sortObjectsByTimeCreated(res.data);
          setDesignFile(sortedFileList);
          setHasDesignFile(sortedFileList.length !== 0);

          loading({ type: "LOADING" });
          ProjectApi.DownloadDesignFile(sortedFileList[0].id + "")
            .then((res) => {
              //window.location.href = res.data.download_url;
              setDesignFileUrl(res.download_url);
            })
            .catch((e) => {
              let msg = ErrorHandler(e);
              console.log(msg);
            })
            .finally(() => {
              loading({ type: "COMPLETE" });
            });
        } else {
          setDesignFile(res.data);
          setHasDesignFile(res.data.length !== 0);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => {
        loading({ type: "COMPLETE" });
      });
  };
  useEffect(() => {
    getReferenceFile();
  }, [hasUploadedFile, translate_project_id]);

  const renderMemoHistory = (historyItem: SortedMemoHistory) => {
    let maxLength;
    if (historyItem.type1 && historyItem.type2) {
      maxLength = Math.max(historyItem.type1.length, historyItem.type2.length);
    } else if (historyItem.type1) {
      maxLength = historyItem.type1.length;
    } else if (historyItem.type2) {
      maxLength = historyItem.type2.length;
    } else {
      maxLength = 0;
    }

    // 더 긴 배열의 길이만큼 렌더링합니다.
    return Array.from({ length: maxLength }).map((_, subIndex) => {
      if (subIndex == 0) {
        return;
      }
      // type1과 type2에서 현재 인덱스의 데이터를 가져옵니다.
      const type1Memo = historyItem.type1 ? historyItem.type1[subIndex] : null;
      const type2Memo = historyItem.type2 ? historyItem.type2[subIndex] : null;

      return (
        <tr>
          <td style={tableSmallCellStyle}></td>
          {type2Memo ? (
            <td style={tableCellStyle}>
              {/* // type2 데이터가 있으면 렌더링합니다. */}
              <span style={{ whiteSpace: "pre-wrap" }}>{type2Memo.memo_contents}</span>

              <br />
              <span style={{ float: "right", color: "darkgray" }}>
                <b>{convertISOToStandardFormat(type2Memo.time_created)}</b>
              </span>
            </td>
          ) : (
            // type2 데이터가 없으면 빈 칸 또는 대체 텍스트를 렌더링합니다.
            <td style={tableCellStyleNoTranslation}></td>
          )}
          {type1Memo ? (
            <td style={tableCellStyle}>
              {/* // type1 데이터가 있으면 렌더링합니다. */}
              <span style={{ whiteSpace: "pre-wrap" }}>{type1Memo.memo_contents}</span>

              <br />
              <span style={{ float: "right", color: "darkgray" }}>
                <b>{convertISOToStandardFormat(type1Memo.time_created)}</b>
              </span>
            </td>
          ) : (
            // type1 데이터가 없으면 빈 칸 또는 대체 텍스트를 렌더링합니다.s
            <td style={tableCellStyleNoTranslation}></td>
          )}
          <td style={tableSmallCellStyle}></td>
        </tr>
      );
    });
  };

  const findSortedMemoHistory = (sortedMemoHistory: SortedMemoHistory[], pageNumber: number) => {
    return sortedMemoHistory.find((history: SortedMemoHistory) => history.page_no === pageNumber);
  };

  // const findEmptyHistory = (sortedMemoHistory: SortedMemoHistory[], pageNumber: number) => {
  //   // 페이지 번호에 해당하는 메모 이력 찾기
  //   const pageMemoHistory = sortedMemoHistory.find((history) => history.page_no === pageNumber);

  //   if (pageMemoHistory === null || typeof pageMemoHistory === 'undefined' ) return false;

  //   // pageMemoHistory가 undefined가 아니면서, type1 또는 type2 배열 중 하나라도 길이가 2 이상인지 확인
  //   return pageMemoHistory?.type1?.length >= 2 || pageMemoHistory?.type2?.length >= 2 || false;
  // };

  const findEmptyHistory = (sortedMemoHistory: SortedMemoHistory[], pageNumber: number): boolean => {
    const pageMemoHistory = sortedMemoHistory.find((history) => history.page_no === pageNumber);
    if (!pageMemoHistory) return false;

    if (pageMemoHistory?.type1) {
      // type1이 배열인지 확인하고, 배열이면 길이가 2 이상인지 확인
      const isType1Array = Array.isArray(pageMemoHistory?.type1);
      return isType1Array && pageMemoHistory.type1.length >= 2;
    } else if (pageMemoHistory?.type2) {
      // type2도 동일한 방식으로 처리
      const isType2Array = Array.isArray(pageMemoHistory?.type2);
      return isType2Array && pageMemoHistory.type2.length >= 2;
    } else {
      return false;
    }
  };

  const loading = useLoadingDispatch();

  // 기존에 존재하는 Design 파일용
  const [designFile, setDesignFile] = useState<DesignFile[]>([]);
  // 새로 업로드하는 Design 파일용
  const [designRawFile, setDesignRawFile] = useState<File[]>([]);

  const handleClickDesignFile = (e: any) => {
    setDesignRawFile(e.target.files);
  };

  const confirmDesignFile = () => {
    if (!window.confirm(t("dialogue-project-name") + subProject.project_name)) {
      return;
    }
    loading({ type: "LOADING" });
    ProjectApi.DesignFileUploadSubProject(designRawFile[0], "" + subProject.id, translate_project_id + "")
      .then(() => {
        alert("File Upload Success");
        setHasUploadedFile(true);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        console.log(msg);
      })
      .finally(() => {
        loading({ type: "COMPLETE" });
      });
  };

  const downloadDesignFile = () => {
    console.log("download", designFile);
    window.location.href = designFileUrl;
  };

  return (
    <>
      {isOpen ? (
        <div id="mySideMemoPanel" className={`${classes.sideMemoPanel} ${isOpen ? classes.open : ""}`}>
          {/* <a href="#0" className="closebtn" onClick={togglePanel}>
            ×
          </a> */}
          {memoData && memoData.length > 0 ? (
            //   memoData.map((memo, index) => (
            //     <div key={index}>
            //       <h4>{memo.memo_contents}</h4>
            //       <p>{memo.siteuser_id}</p>
            //     </div>
            //   ))
            <div style={scrollableContentStyle}>
              {/* 메모칸 시작 */}

              <div style={{ position: "relative" }}>
                <div style={headerStyle}>
                  <br />
                  <h5>View All Memos in Project</h5>
                  <table style={{ width: "75%" }}>
                    <tr>
                      <th rowSpan={2}>Upload Reference File</th>
                      <td>
                        {designFile.length > 0 && (
                          <>
                            <label htmlFor="file-upload">
                              <div className={classes.fileInputLabel}>
                                <span>{designFile[0].uploaded_design_filename}</span>
                              </div>
                            </label>
                            <Button
                              id="file-upload"
                              variant="contained"
                              color="primary"
                              onClick={downloadDesignFile}
                              className={classes.button}
                            >
                              Download
                            </Button>
                          </>
                        )}
                      </td>
                    </tr>
                    {user.admin && (
                      <tr>
                        <td>
                          <div className={classes.root}>
                            <input
                              accept=".zip"
                              className={classes.hidden}
                              id="contained-button-design-file"
                              type="file"
                              onChange={handleClickDesignFile}
                            />
                            <label htmlFor="contained-button-design-file">
                              <div className={classes.fileInputLabel}>
                                {designRawFile && designRawFile[0] ? <span>{designRawFile[0].name}</span> : <></>}
                              </div>
                            </label>
                            <label htmlFor="contained-button-design-file">
                              <Button
                                variant="contained"
                                style={{ color: PRIMARY }}
                                component="span"
                                className={classes.button}
                              >
                                <FolderOpenIcon></FolderOpenIcon>
                              </Button>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className={classes.root}>
                            <Button
                              disabled={designRawFile.length === 0}
                              onClick={confirmDesignFile}
                              variant="contained"
                              className={classes.button}
                            >
                              {t("upload")}
                            </Button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </table>

                  <ActionButton
                    style={{ float: "right" }}
                    // className="closebtn"
                    variant="contained"
                    onClick={() => {
                      togglePanel();
                    }}
                  >
                    x
                  </ActionButton>
                  <DownloadButton
                    generateWorkbook={generateCustomWorkbook}
                    style={{ float: "right", marginRight: "14px", backgroundColor: "lightgray", color: "black" }}
                    data={modalMemoContent}
                    filename={
                      translate_project &&
                      checkValidArray(modalMemoContent) &&
                      checkValidString(subProject.project_name) &&
                      checkValidString(translate_project.source_lang_code) &&
                      checkValidString(translate_project.target_lang_code)
                        ? "[Memo]" +
                          subProject.project_name +
                          "_(" +
                          getTitleByField(translate_project.source_lang_code) +
                          "-" +
                          getTitleByField(translate_project.target_lang_code) +
                          ")"
                        : "data"
                    }
                  ></DownloadButton>

                  <ActionButton
                    style={{ float: "right", marginRight: "12px", backgroundColor: "lightgray", color: "black" }}
                    // className="closebtn"
                    variant="contained"
                    onClick={() => {
                      getWholePageMemo(true);
                    }}
                  >
                    Refresh MEMO
                  </ActionButton>
                  <h6>
                    <b>{mainProject.project_name}</b>

                    {" > "}
                    <b>{subProject.project_name}</b>
                  </h6>
                  <h6>
                    {getTitleByField(translate_project.source_lang_code)} {" ➡️ "}
                    {getTitleByField(translate_project.target_lang_code)}
                  </h6>
                  <table style={tableStyle}>
                    <thead style={theadStyle}>
                      <tr style={tableRowStyle}>
                        <th style={tableSmallCellStyle}>Page</th>
                        {/* <th style={tableCellStyle}>Source Sentence</th>
                    <th style={tableCellStyle}>Primary Translation</th> */}

                        <th style={tableCellStyle}>Translator</th>
                        <th style={tableCellStyle}>Administrator</th>
                        <th style={tableSmallCellStyle}></th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div style={scrollableContentStyle}>
                  <table style={tableStyle}>
                    <tbody>
                      {modalMemoContent.map((item, index) => (
                        <>
                          <tr key={index} style={tableRowStyle}>
                            <td style={tableSmallCellStyle}>
                              <a href="#" onClick={handleClick(item.page_no)} style={{ float: "left", padding: "4px" }}>
                                {item.page_no}
                              </a>
                            </td>

                            {item && item.type2 && checkValidString(item.type2.memo_contents) ? (
                              <td style={tableCellStyle}>
                                <span style={{ whiteSpace: "pre-wrap" }}>{item.type2.memo_contents}</span>
                                <br />
                                <span style={{ float: "right", color: "darkgray" }}>
                                  <b>{convertISOToStandardFormat(item.type2.time_created)}</b>
                                </span>
                              </td>
                            ) : (
                              <td style={tableCellStyleNoTranslation}></td>
                            )}

                            {item && item.type1 && checkValidString(item.type1.memo_contents) ? (
                              <td style={tableCellStyle}>
                                <span style={{ whiteSpace: "pre-wrap" }}>{item.type1.memo_contents}</span>
                                <br />
                                <span style={{ float: "right", color: "darkgray" }}>
                                  <b>{convertISOToStandardFormat(item.type1.time_created)}</b>
                                </span>
                              </td>
                            ) : (
                              <td style={tableCellStyleNoTranslation}></td>
                            )}
                            <td style={tableSmallCellStyle}>
                              {findEmptyHistory(sortedMemoHistory, item.page_no) && (
                                <span onClick={() => toggleHistory(index)} style={{ cursor: "pointer" }}>
                                  {expandedRows[index] ? "🔼" : "🔽"}
                                </span>
                              )}
                            </td>
                          </tr>
                          {expandedRows[index] &&
                            findSortedMemoHistory(sortedMemoHistory, item.page_no) &&
                            renderMemoHistory(
                              findSortedMemoHistory(sortedMemoHistory, item.page_no) ?? INIT_SORTED_MEMO_HISTORY
                            )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* 메모칸 끝 */}
            </div>
          ) : (
            <p>No memos available</p>
          )}
        </div>
      ) : (
        <></>
      )}

      <Button
        style={{
          backgroundColor: !memoData || memoData.length === 0 ? "#dcdcdc" : isOpen ? "#939393" : "#D3D3D3",
          marginRight: "24px",
          color: !memoData || memoData.length === 0 ? "#b0b0b0" : "black",
        }}
        size="small"
        variant="contained"
        onClick={togglePanel}
        disabled={!memoData || memoData.length === 0}
      >
        View All Memos
      </Button>
    </>
  );
}

export default SideMemoPanel;
