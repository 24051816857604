import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import { ActionButton } from "components/Common/Button";
import SimpleSelect from "components/Common/Custom/SimpleSelect";
import PasswordInput from "components/Common/PasswordInput";
import { ChangeEvent, ReactElement, useMemo, useState } from "react";
import { BaseInfoApi, ErrorHandler } from "system/ApiService";
import { isEmail } from "system/Helper";
import { Baselanguage, UserInfo } from "system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    bottom: {
      width: "100%",
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    button: {
      // display: "flex",
      margin: "auto",
    },
  })
);

interface UserDetailProps {
  data: UserInfo;
  add?: boolean;
  onChange: (event: any) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeRepeatPassword?: (repeatPassword: string) => void;
  verifyId?: boolean;
  onCheckId?: (userId: string) => void;
}

function UserManagementDialogTemplate({
  data: user,
  add,
  onChange,
  handleChange,
  onChangeRepeatPassword,
  verifyId,
  onCheckId,
}: UserDetailProps): ReactElement {
  const classes = useStyles();
  const [baselanguageList, setBaselanguageList] = useState<Baselanguage[]>([]);
  const [repeatPassword, setRepeatPassword] = useState<string>("");

  const onRepeatPassword = (e: any) => {
    if (onChangeRepeatPassword) {
      setRepeatPassword(e.target.value);
      onChangeRepeatPassword(e.target.value);
    }
  };

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaselanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="user_id"
            name="user_id"
            label="ID"
            error={user.user_id === "" ? true : false}
            helperText={user.user_id === "" ? "ID는 필수 입력값입니다." : ""}
            value={user.user_id}
            className={classes.bottom}
            onChange={onChange}
            disabled={!add}
            required
          ></TextField>
        </Grid>
        <Grid item xs={6} className={classes.button}>
          {add && onCheckId && (
            <ActionButton onClick={() => onCheckId(user.user_id)} disabled={verifyId}>
              ID중복체크
            </ActionButton>
          )}
        </Grid>
        <Grid item xs={6}>
          {add && (
            <PasswordInput
              id="password"
              name="password"
              label="비밀번호"
              error={user.password === "" ? true : false}
              value={user.password}
              className={classes.bottom}
              onChange={onChange}
            ></PasswordInput>
          )}
        </Grid>
        <Grid item xs={6}>
          {add && (
            <PasswordInput
              id="repeat_password"
              name="repeat_password"
              label="비밀번호 확인"
              error={user.password === repeatPassword ? false : true}
              value={repeatPassword}
              className={classes.bottom}
              onChange={onRepeatPassword}
            ></PasswordInput>
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="user_name"
            name="user_name"
            label="이름"
            error={user.user_name === "" ? true : false}
            value={user.user_name}
            className={classes.bottom}
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="user_email"
            name="user_email"
            label="이메일"
            error={isEmail(user.user_email) ? false : true}
            helperText={isEmail(user.user_email) ? "" : "이메일 형식이 아닙니다."}
            value={user.user_email}
            className={classes.bottom}
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <SimpleSelect
            id="user_native_lang"
            name="user_native_lang"
            label="원본(기본) 언어"
            error={user.user_native_lang === "" ? true : false}
            className={classes.bottom}
            value={user.user_native_lang}
            onChange={onChange}
          >
            <MenuItem value={"KO"}>Korean</MenuItem>
            <MenuItem value={"EN_EN"}>English(Common)</MenuItem>
          </SimpleSelect>
        </Grid>
        <Grid item xs={6}>
          <SimpleSelect
            id="user_translate_lang"
            name="user_translate_lang"
            label="번역 언어"
            error={user.user_translate_lang === "" ? true : false}
            className={classes.bottom}
            value={user.user_translate_lang || ""}
            onChange={onChange}
            disabled={!add}
          >
            {baselanguageList.map((baselanguage: Baselanguage, idx) => {
              return (
                <MenuItem key={idx} value={baselanguage.lang_code}>{`${baselanguage.lang_name_english}`}</MenuItem>
              );
            })}
          </SimpleSelect>
        </Grid>
        <Grid item xs={6}>
          <SimpleSelect
            id="group_role"
            name="group_role"
            label="권한"
            className={classes.bottom}
            value={user.group_role || ""}
            error={user.group_role === 0 ? true : false}
            onChange={onChange}
            disabled={!add}
          >
            <MenuItem value={1}>System Admin</MenuItem>
            <MenuItem value={2}>HQ Manager</MenuItem>
            <MenuItem value={3}>Branch Manager</MenuItem>
            <MenuItem value={4}>Translator</MenuItem>
          </SimpleSelect>
        </Grid>
        <Grid item xs={6}>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={user.active_account} onChange={handleChange} name="active_account" />}
                label="계정 활성화"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default UserManagementDialogTemplate;
