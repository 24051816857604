import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0.5rem 0 0.5rem",
  },
  chipsDiv: {
    marginTop: ".3rem",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    maxHeight: "50vh",
    overflowY: "auto",
  },
  languageDiv: {
    margin: ".3rem",
    padding: "0.5rem",
    border: "1px solid #ccc",
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  selectedLanguageDiv: {
    margin: ".3rem",
    padding: "0.5rem",
    border: "1px solid #ccc",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "lightgrey", // 1번 클릭일 때 밝은 회색
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  chatGPTLanguageDiv: {
    margin: ".3rem",
    padding: "0.5rem",
    border: "1px solid #ccc",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "#74AA9C", // 2번 클릭일 때 #74AA9C
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  click3LanguageDiv: {
    margin: ".3rem",
    padding: "0.5rem",
    border: "1px solid #ccc",
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  selectedLanguageImage: {
    maxHeight: "50%", // 최대 높이를 50%로 설정
    width: "auto", // 이미지 너비는 자동으로 조정
    position: "absolute", // 이미지의 위치를 상대 위치에 대해 절대 위치로 설정
    top: "50%", // 이미지를 수직으로 가운데 정렬
    right: "10px", // 이미지 오른쪽에 10px 여백을 줌
    transform: "translateY(-50%)", // 이미지를 수직 가운데 정렬
  },
  errorText: {
    textAlign: "left",
    color: "red", // 에러 텍스트 색상을 지정하세요.
  },
  noDrag: {
    userDrag: "none",
  },
  /* chip 요소 스타일 */
  chip: {
    margin: ".5rem",
    padding: "0.5rem",
    flex: "0 1 auto", // 자동으로 너비를 조절하도록 설정
    maxWidth: "100%", // 최대 너비 설정
    boxSizing: "border-box", // 여백을 포함한 크기를 설정합니다.
    /* 기타 스타일 속성들... */
  },

  disabled: {
    opacity: 0.7, // 비활성화(disabled) 스타일
    pointerEvents: "none", // 비활성화(disabled) 스타일
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
}));

interface Option {
  lang_code: string;
  lang_name_english: string;
}

interface Props {
  value: string[];
  setValue: (value: string[]) => void;
  // setValue: (value: { selectedValue: string[]; clickCount: Record<string, number> }) => void;
  options: Option[];
  label?: string;
  error?: string;
  setError?: (error: string) => void;
  name?: string;
  disabled?: boolean;
  // clickCount: Record<string, number>; // 클릭 횟수 정보 타입 정의

  clickCount: { [key: string]: number };
  setClickCount: (clickedValue: string) => void;
  translateProjectLanguages: string[];
  // setClickCount: (value: { [key: string]: number }) => void;
}
const MultipleSelectChips: React.FC<Props> = ({
  value,
  setValue,
  options,
  error,
  setError,
  disabled,
  clickCount,
  setClickCount,
  translateProjectLanguages,
}) => {
  const classes = useStyles();
  // const [clickCount, setClickCount] = useState<{ [key: string]: number }>(INIT_CLICKCOUNT);

  // options가 변경될때 = 선택한 translate project가 다를때
  useEffect(() => {}, [value]);

  const handleClick = (clickedValue: string) => {
    if (disabled) return;
    if (setError) {
      setError("");
    }

    setClickCount(clickedValue);

    // 세번 클릭된 나라는 지운다
    if (value.find((e: any) => e === clickedValue)) {
      if (clickCount[clickedValue] > 1) {
        let arr = [...value];
        const index = value.findIndex((e: any) => e === clickedValue);
        arr.splice(index, 1);
        setValue(arr);
      }
    } else {
      setValue([...value, clickedValue]);
    }
  };

  return (
    <>
      <div className={classes.container}>
        {Boolean(error) && <div className={classes.errorText}>{error}</div>}
        <div className={classes.chipsDiv}>
          {options && options.length
            ? options.map((option: Option, i: number) => (
                <div
                  className={`${classes.languageDiv} element-no-drag ${
                    disabled || translateProjectLanguages.indexOf(option.lang_code) >= 0 ? classes.disabled : ""
                  } ${
                    clickCount[option.lang_code] === 2
                      ? classes.chatGPTLanguageDiv
                      : clickCount[option.lang_code] === 1 // || value.find((e: any) => e === option.lang_code)
                      ? classes.selectedLanguageDiv
                      : classes.click3LanguageDiv
                  }`}
                  key={i}
                  onClick={() => handleClick(option.lang_code)}
                >
                  {/* {console.log(option.lang_code, clickCount[option.lang_code])} */}
                  <span className={classes.noDrag}>{option.lang_name_english}</span>
                  {clickCount[option.lang_code] === 2 && (
                    <>
                      <img src="/chatgpt-logo.svg" alt="ChatGPT Logo" className={`${classes.selectedLanguageImage} `} />
                    </>
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

MultipleSelectChips.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MultipleSelectChips;
