import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorHandler, ProjectApi } from "system/ApiService";
import { TABLEICONS } from "system/Constants";
import { INIT_MAIN_PROJECT, MainProject, MaterialTableOptions, ModalType } from "system/types";
import MainProjectDialog from "./MainProjectDialog";
import { ActionButton } from "components/Common/Button";
import AddIcon from "@material-ui/icons/Add";
interface MainProjectTableProps {
  options: MaterialTableOptions;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: "right",
    },
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
  })
);

function MainProjectTable({ options }: MainProjectTableProps) {
  const classes = useStyles();
  const [data, setData] = useState<MainProject[]>([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<ModalType>("edit");
  const [main, setMain] = useState<MainProject>(INIT_MAIN_PROJECT);
  const { t } = useTranslation();

  const sortMethod = function (a: MainProject, b: MainProject) {
    if (a.id && b.id) {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    }
  };

  const onLoad = React.useCallback(() => {
    ProjectApi.GetMainProjectList()
      .then((res) => {
        let items = res.data;
        items.sort(sortMethod);
        setData(items);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const addMainProject = () => {
    setType("add");
    setOpen(true);
  };
  const onDeleteMainProject = (id: number) => {
    if (window.confirm("" + t("dialogue-confirm-delete"))) {
      ProjectApi.DeleteMainProject(id)
        .then((res) => {
          onLoad();
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  };

  const onOpenModal = (main: MainProject) => {
    setMain(main);
    setType("edit");
    setOpen(true);
  };

  const onClose = () => {
    setMain(INIT_MAIN_PROJECT);
    setOpen(false);
  };

  return (
    <div>
      <MaterialTable
        title=""
        icons={TABLEICONS}
        onRowClick={(event, rowData) => {
          if (!event) return;
          let target = event.target + "";
          if (target.includes("HTMLTableCellElement") && options.rowClick) {
            if (rowData) {
              options.onRowClickMainProject(rowData);
            }
          }
        }}
        columns={[
          {
            title: "" + t("project-name"),
            field: "project_name",
            cellStyle: { width: "24%" },
            disableClick: !options.rowClick,
          },
          {
            title: "" + t("product-code"),
            field: "product_code",
            cellStyle: { width: "24%" },
            disableClick: !options.rowClick,
            defaultGroupOrder: 1,
          },

          {
            title: "" + t("time-created"),
            field: "time_created",
            render: (rowData) => {
              const originalTimestamp = new Date(rowData.time_created);
              const timezoneOffset = rowData.time_created.split("+")[1];
              const formattedTimestamp = originalTimestamp
                .toISOString()
                .replace(/\.\d+/, "")
                .replace("T", " ")
                .replace("Z", "");
              return formattedTimestamp + " GMT±" + timezoneOffset;
            },
            cellStyle: { width: "24%" },
            disableClick: !options.rowClick,
          },
          {
            title: "",
            field: "",
            cellStyle: { width: "28%" },
            render: (rowData) => {
              return options.isClosed ? (
                <></>
              ) : (
                <div className={classes.buttonGroup}>
                  <IconButton className={classes.iconButton} onClick={() => onOpenModal(rowData)}>
                    <CreateOutlinedIcon />
                  </IconButton>
                  <IconButton className={classes.iconButton} onClick={() => onDeleteMainProject(rowData.id || 0)}>
                    <DeleteForeverOutlinedIcon />
                  </IconButton>
                </div>
              );
            },
            disableClick: !options.rowClick,
          },
        ]}
        data={data || []}
        options={{
          filtering: true,
          pageSize: 20,
          tableLayout: "auto",
        }}
        actions={[
          {
            icon: "add",
            tooltip: "",
            isFreeAction: true,
            onClick: (event) => {},
          },
        ]}
        components={{
          Action: (props) =>
            options.add ? (
              <ActionButton onClick={addMainProject} variant="contained" style={{ marginLeft: "10px" }}>
                <AddIcon />
                {t("add-project")}
              </ActionButton>
            ) : (
              <></>
            ),
        }}
      />
      <MainProjectDialog
        _main={main || INIT_MAIN_PROJECT}
        type={type}
        open={open}
        onRowClickMainProject={options.onRowClickMainProject}
        onClose={onClose}
        saveCallback={onLoad}
      ></MainProjectDialog>
    </div>
  );
}

export default MainProjectTable;
