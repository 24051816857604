import GlossariesStepMain from "components/BaseTranslation/Glossaries/GlossariesStepMain";
import { ReactElement } from "react";

function GlossariesMain(): ReactElement {
  return (
    <div>
      <GlossariesStepMain></GlossariesStepMain>
    </div>
  );
}
export default GlossariesMain;
