import { Collapse, Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MyRoutes } from "routes/Routes";

import { t } from "i18next";
import jwt_decode from "jwt-decode";
import { useRecoilState, useResetRecoilState } from "recoil";
import { UserApi } from "system/ApiService";
import { IsFirstVisitAtom, SubProjectAtom, TranslateProjectAtom } from "system/atom";

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      fontSize: "20px",
      textAlign: "center",
      backgroundColor: "#F0F2F5",
      color: "black",
      "& a:hover": {
        color: "black",
        backgroundColor: "#DCDFE3",
        fontWeight: "bolder",
      },
    },
    drawerHeader: {
      ...theme.mixins.toolbar,
      marginTop: "80px",
    },
    presentation: {
      height: "100%",
      position: "absolute",
      zIndex: 6,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    activeClass: {
      color: "black",
      backgroundColor: "#DCDFE3",
      fontWeight: "bolder",
    },
    // TODO : 번역기 디자인 조정중
    // popup: {
    //   position: "fixed",
    //   bottom: 0,
    //   left: 0,
    //   width: "11.41vw",
    //   // width: "100%",
    //   height: "50vh",
    //   // height: "35vh",
    //   zIndex: 1000, // 충분히 높은 값으로 설정하여 버튼이 상위에 오도록 함
    //   backgroundColor: theme.palette.background.paper,
    //   boxShadow: theme.shadows[5],
    //   // X 버튼을 위한 공간을 만들어줍니다.
    //   paddingTop: "30px",
    //   // paddingBottom: "30px",
    //   marginBottom: "30px",
    //   resize: "both",
    // },
    // iframeStyle: {
    //   transform: "scale(0.72)",
    //   transformOrigin: "top left",
    //   width: "220%",
    //   height: "140%",
    //   border: 0,
    // },
    // closeButton: {
    //   position: "absolute",
    //   top: 0,
    //   right: 0,
    //   cursor: "pointer",
    //   fontSize: "10px",
    //   zIndex: 1100,
    // },
    // popupChild: {
    //   objectFit: "cover",
    //   width: "100%",
    //   height: "100%",
    //   overflowX: "auto",
    //   overflowY: "hidden",
    // },
  })
);

const getInitialNestedListItem = () => {
  return {
    Project: true,
    ProjectManagement: true,
    BaseTextManagement: true,
    Translator: true,
  };
};

interface PersistentDrawerLeftProps {
  open: boolean;
}

export default function PersistentDrawerLeft({ open }: PersistentDrawerLeftProps) {
  const INIT_NESTED_LIST_ITEM = getInitialNestedListItem();
  const classes = useStyles();
  const [select, setSelect] = useState<{ [e: string]: boolean }>(INIT_NESTED_LIST_ITEM);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [groupRole, setGroupRole] = useState<number>(0); // 시스템관리자 : 1, 본사 인원 : 2

  // TODO : 번역기 디자인 조정중
  // const [openTranslator, setOpenTranslator] = useState(true);

  // const togglePopup = () => {
  //   setOpenTranslator(!openTranslator);
  // };
  const [translateProject, setTranslateProject] = useRecoilState(TranslateProjectAtom);

  const resetTranslateProjectAtom = useResetRecoilState(TranslateProjectAtom);
  const resetSubProjectAtom = useResetRecoilState(SubProjectAtom);
  const resetIsFirstVisitAtom = useResetRecoilState(IsFirstVisitAtom);

  const handleReset = () => {
    resetIsFirstVisitAtom();
    resetTranslateProjectAtom();
    resetSubProjectAtom();
  };

  const handleClick = (name: string) => {
    setSelect({ ...select, [name]: !select[name] });
  };
  const onLoad = React.useCallback(() => {
    const decoded: any = jwt_decode(UserApi.GetToken());
    setIsAdmin(decoded.sub.is_admin);
    setGroupRole(decoded.sub.group_role[0]);
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <CssBaseline />
      <div className={classes.presentation} role="presentation">
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Grid container style={{ height: "90vh" }}>
              <Grid item xs={12}>
                {isAdmin ? (
                  <List>
                    <ListItem onClick={() => handleClick("ProjectManagement")} button>
                      <ListItemText primary={t("project-management")} />
                      {select["ProjectManagement"] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={select["ProjectManagement"]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {/* On-Progress  */}
                        <ListItem
                          button
                          className={classes.nested}
                          component={NavLink}
                          to={MyRoutes.projectManagement}
                          activeClassName={classes.activeClass}
                          onClick={handleReset}
                        >
                          <ListItemText primary={t("create-edit")} />
                        </ListItem>

                        {/* OnGoing  */}
                        <ListItem
                          button
                          className={classes.nested}
                          component={NavLink}
                          to={MyRoutes.projectManagementOngoing}
                          activeClassName={classes.activeClass}
                          onClick={handleReset}
                        >
                          <ListItemText primary={t("ongoing-project")} />
                        </ListItem>

                        {/* Completed  */}
                        <ListItem
                          button
                          className={classes.nested}
                          component={NavLink}
                          to={MyRoutes.projectManagementComplete}
                          activeClassName={classes.activeClass}
                          onClick={handleReset}
                        >
                          <ListItemText primary={t("completed-project")} />
                        </ListItem>
                      </List>
                    </Collapse>

                    {/* User Management */}
                    {groupRole === 1 ? (
                      <ListItem
                        button
                        component={NavLink}
                        to={MyRoutes.userManagement}
                        activeClassName={classes.activeClass}
                        onClick={handleReset}
                      >
                        <ListItemText primary={t("user-management")} />
                      </ListItem>
                    ) : (
                      <></>
                    )}

                    {/* Base Information */}
                    <ListItem
                      button
                      component={NavLink}
                      to={MyRoutes.baseInformation}
                      activeClassName={classes.activeClass}
                      onClick={handleReset}
                    >
                      <ListItemText primary={t("base-information")} />
                    </ListItem>

                    <ListItem onClick={() => handleClick("BaseTextManagement")} button>
                      <ListItemText primary={t("base-translation-resource")} />
                      {select["BaseTextManagement"] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={select["BaseTextManagement"]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {/* Basetext */}
                        <ListItem
                          button
                          className={classes.nested}
                          component={NavLink}
                          to={MyRoutes.stepByStepBasetext}
                          activeClassName={classes.activeClass}
                          onClick={handleReset}
                        >
                          <ListItemText primary={t("Basetext DB")} />
                        </ListItem>
                        {/* Glossaries */}
                        {/* <ListItem
                          button
                          className={classes.nested}
                          component={NavLink}
                          to={MyRoutes.stepByStepGlossaries}
                          activeClassName={classes.activeClass}
                        >
                          <ListItemText primary={t("glossary")} />
                        </ListItem> */}
                      </List>
                    </Collapse>
                  </List>
                ) : (
                  <List>
                    <ListItem button component={NavLink} to={MyRoutes.translatorProject} onClick={handleReset}>
                      <ListItemText primary={t("translator-assignment")} />
                    </ListItem>
                  </List>
                )}
                {/* TODO : 번역기 디자인 조정중 */}
                {/* <div>
                  <Button
                    variant="outlined"
                    onClick={togglePopup}
                    // style={{ marginLeft: "8px", paddingLeft: "6px", paddingRight: "4px" }}
                  >
                    {openTranslator ? "Close Translator🌏" : "Open Translator🌏 "}
                  </Button>
                </div> */}
              </Grid>
            </Grid>
          </div>
          {/* TODO : 번역기 디자인 조정중 */}
          {/* <div>
            <div>
              {openTranslator && (
                <>
                  <div className={classes.popup}>
                    <IconButton
                      className={classes.closeButton}
                      style={{ width: "5px", height: "5px", padding: "15px" }}
                      onClick={togglePopup}
                    >
                      <CloseIcon />
                    </IconButton>
                    <div className={classes.popupChild}>
                      <iframe
                        className={classes.iframeStyle}
                        src="https://lingva.ml"
                        title="translator"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div> */}
        </Drawer>
      </div>
    </>
  );
}
