import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import jwt_decode from "jwt-decode";
import { UserApi } from "./ApiService";
import { DecodedToken } from "./types";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class _HttpClient {
  protected readonly instance: AxiosInstance;
  public constructor() {
    const API_URL = process.env.REACT_APP_API_URL || `http://192.168.15.229:45000/api/v1/`;

    const baseURL = API_URL;

    // baseURL을 기반으로 axios instance를 생성
    this.instance = axios.create({
      baseURL,
    });
    // todo: cors에러로 인해 잠시 주석처리
    // this.instance.defaults.withCredentials = true;
  }
}

export default class HttpClient extends _HttpClient {
  protected accessToken: string | null;
  protected refreshToken: string | null;
  protected multipartFormData: boolean;

  public constructor() {
    super();
    this.accessToken = null;
    this.refreshToken = null;
    this.multipartFormData = false;
    this.instance.interceptors.request.use(this.requestInterceptor, (error) => Promise.reject(error));
  }

  public getExpirationDateFromToken = (accessToken: string): Date => {
    const decodedToken: DecodedToken = jwt_decode(accessToken);
    // console.log(decodedToken, new Date(decodedToken.exp * 1000));
    // 'exp'는 초 단위 UNIX timestamp이므로, 밀리초로 변환하기 위해 1000을 곱합니다.
    return new Date(decodedToken.exp * 1000);
  };

  private getCookie = () => {
    var cookieValue = null;
    if (document.cookie) {
      let cookies = document.cookie.replace(" ", "").split(";");
      cookies.forEach((cookie) => {
        if (cookie.indexOf("refreshToken=") > -1) {
          cookieValue = cookie.replace("refreshToken=", "");
        }
      });
    }
    return cookieValue;
  };

  private requestInterceptor = async (config: AxiosRequestConfig) => {
    const now = new Date();
    const twentyMinutesAfter = new Date(now.getTime() + 20 * 60 * 1000); // 현재 시간에서 20분뒤 시간 계산
    if (config.url === "auth/refresh_access_token") {
      config.headers["Authorization"] = "";
      return config;
    } else if (this.accessToken && this.getExpirationDateFromToken(this.accessToken) < twentyMinutesAfter) {
      console.log("Refresh Started.");

      try {
        const tokenData = await UserApi.Refresh();
        UserApi.SetToken(tokenData.access_token);
        console.log("Refresh solved");
      } catch (e) {
        console.log(e);
      }
    }

    if (this.accessToken !== null && this.accessToken.length >= 1) {
      config.headers["Authorization"] = `Bearer ${this.accessToken}`;
    }

    if (this.multipartFormData) {
      config.headers["enctype"] = `multipart/form-data`;
    }

    config.headers["Access-Control-Allow-Credentials"] = `true`;

    return config;
  };

  // if (this.accessToken && this.getExpirationDateFromToken(`${this.accessToken}`) < twentyMinutesAfter) {
  //   console.log("Refresh Started.");
  //   let count = 0;
  //   UserApi.Refresh()
  //     .then((tokenData) => {
  //       console.log(tokenData);
  //       const decoded: any = jwt_decode(tokenData.access_token);
  //       UserApi.SetToken(tokenData.access_token);
  //       console.log("refresh solved");

  //       if (config.url === "auth/refresh_access_token") {
  //         config.headers["Authorization"] = "";
  //       } else if (this.accessToken !== null && this.accessToken.length >= 1) {
  //         config.headers["Authorization"] = `Bearer ${this.accessToken}`;
  //       }

  //       if (this.multipartFormData) {
  //         config.headers["enctype"] = `multipart/form-data`;
  //       }

  //       config.headers["Access-Control-Allow-Credentials"] = `true`;

  //       return config;
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     })
  //     .finally(() => {});
  // } else {
  //   if (config.url === "auth/refresh_access_token") {
  //     config.headers["Authorization"] = "";
  //   } else if (this.accessToken !== null && this.accessToken.length >= 1) {
  //     config.headers["Authorization"] = `Bearer ${this.accessToken}`;
  //   }

  //   if (this.multipartFormData) {
  //     config.headers["enctype"] = `multipart/form-data`;
  //   }

  //   config.headers["Access-Control-Allow-Credentials"] = `true`;

  //   return config;
  // }

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.instance
        .post<T>(url, data, config)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.instance
        .put<T>(url, data, config)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  delete<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.instance
        .delete<T>(url, {
          ...config,
          data: data,
        })
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.instance
        .get<T>(url, config)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  public setToken(newToken: string) {
    if (newToken.length >= 1) {
      this.accessToken = newToken;
    } else {
      throw new Error("ArgumentException token");
    }
  }

  public setRefreshToken(newToken: string) {
    if (newToken.length >= 1) {
      this.refreshToken = newToken;
    } else {
      throw new Error("ArgumentException token");
    }
  }

  public disableToken() {
    this.accessToken = null;
  }

  public setMultipartFormData() {
    this.multipartFormData = true;
  }
  public unSetMultipartFormData() {
    this.multipartFormData = false;
  }
}
