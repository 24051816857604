import StepByStepComplete from "components/Project/StepByStepComplete/StepByStepComplete";
import { ReactElement } from "react";

function ProjectManagementCompleteMain(): ReactElement {
  return (
    <div>
      <StepByStepComplete></StepByStepComplete>
    </div>
  );
}
export default ProjectManagementCompleteMain;
