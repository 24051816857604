import {
  Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import PublishIcon from "@material-ui/icons/Publish";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import "date-fns";
import { ErrorHandler, ProjectApi } from "system/ApiService";
import { MainProject, SourceTextOutput, SubProject } from "system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    inputClass: {
      marginTop: "10px",
      marginLeft: "15%",
    },
    textArea: {
      width: "100%",
      padding: "5px",
    },
    hidden: {
      display: "none",
    },
    button: {
      margin: "3px",
    },
  })
);

interface VoiceFileUploadProps {
  _main: MainProject;
  _sub: SubProject;
}

export function VoiceFileUpload({ _main, _sub }: VoiceFileUploadProps): ReactElement {
  const classes = useStyles();
  const [choose, setChoose] = useState<string>(_sub.source_lang_code);
  const [list, setList] = useState<SourceTextOutput[]>([]);
  const [target, setTarget] = useState<string[]>([]);
  const [pageNum, setPageNum] = useState<number>(1);
  const [wholePageNum, setWholePageNum] = useState<number>(1);
  const [file, setFile] = useState<File>();

  useMemo(() => {
    if (_sub.id) {
      ProjectApi.GetSourceTextBySubProject(_sub.id)
        .then((res) => {
          setWholePageNum(res.data.length);
          setList(res.data);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });

      ProjectApi.GetSubProjectDetail(_sub.id.toString()).then((res) => {
        setTarget(res.data.target_lang_code);
      });
    }
  }, [_sub.id]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChoose(event.target.value as string);
  };

  const onPageNumChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageNum(page);
    onLoad(page);
  };

  const onPageNumChangeText = (args: any) => {
    let value = parseInt(args.target.value);
    setPageNum(value);
    onLoad(value);
  };

  const onLoad = React.useCallback(
    (page: number) => {
      if (!_sub) return;
      if (!_sub.id) return;
      ProjectApi.GetListSource(_sub.id, _sub.source_lang_code, page)
        .then((res) => {
          console.log(res.data);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(e, msg);
        });
    },
    [_sub]
  );

  useEffect(() => {
    onLoad(pageNum);
  }, [onLoad, pageNum]);

  const handleClick = (e: any) => {
    // 파일 업로드 api 연결
    setFile(e.target.files);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} container justifyContent="space-between">
          <FormControl className={classes.formControl}>
            <InputLabel id="choose-select-label">원문 언어</InputLabel>
            <Select labelId="choose-select-label" id="choose-select" value={choose} onChange={handleChange}>
              <MenuItem value={"EN_EN"}>EN_EN</MenuItem>
              <MenuItem value={"KO"}>KO</MenuItem>
            </Select>
          </FormControl>
          <Pagination
            // size="small"
            count={wholePageNum}
            defaultValue={1}
            page={pageNum}
            showFirstButton
            showLastButton
            onChange={onPageNumChangePagination}
            className={classes.inputClass}
          ></Pagination>

          <TextField
            id="project_page_no"
            name="project_page_no"
            label="페이지 번호 입력"
            type="number"
            value={pageNum}
            onChange={onPageNumChangeText}
          ></TextField>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            {target.length > 0 &&
              target.map((code, index) => (
                <Grid item xs={3}>
                  <Typography variant="h5">{code}</Typography>
                  <label htmlFor="contained-button-file">{"upload된 file name?"}</label>
                  <Grid item>
                    <input accept=".db" id="contained-button-file" type="file" className={classes.hidden} />
                    <label htmlFor="contained-button-file">
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        component="span"
                        size="small"
                      >
                        <PublishIcon></PublishIcon>
                      </Button>
                    </label>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      component="span"
                      size="small"
                      onClick={handleClick}
                    >
                      <PlayArrowIcon></PlayArrowIcon>
                    </Button>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ backgroundColor: "#ffff" }}>
          {list.length > 0 && (
            <Typography className={classes.textArea} variant="h6">
              {list[pageNum - 1].source_sentence}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default VoiceFileUpload;
