import React, { ReactElement } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { useToastDispatch, useToastState } from "system/ToastContext";

function Toast(): ReactElement {
  const { open, severity, message } = useToastState();
  const dispatch = useToastDispatch();

  const alert = <Alert severity={severity}>{message}</Alert>;

  const onClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "close" });
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={1500}
      key={"snackbar"}
    >
      {alert}
    </Snackbar>
  );
}

export default Toast;
