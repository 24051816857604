import { Grid } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CancelButton } from "components/Common/Button";
import TitleDiv from "components/Common/TitleDiv";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { INIT_SOURCE_GLOSSARY, SourceGlossary } from "system/types";
import SourceGlossaryMain from "./SourceGlossaryMain";
import TargetGlossaryMain from "./TargetGlossaryMain";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
  })
);

function getSteps() {
  return ["Source Glossary", "Target Glossary"];
}

function GlossariesStepMain(): ReactElement {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [sourceGlossary, setSourceGlossary] = useState<SourceGlossary>(INIT_SOURCE_GLOSSARY);
  const { t } = useTranslation();

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <SourceGlossaryMain
            _setSourceGlossary={setSourceGlossary}
            _setActiveStep={setActiveStep}
          ></SourceGlossaryMain>
        );
      case 1:
        return <TargetGlossaryMain _sourceGlossary={sourceGlossary}></TargetGlossaryMain>;
      default:
        return "Unknown step";
    }
  }

  const handleBack = () => {
    // 뒤로가기 버튼 누를때 state 초기화
    if (activeStep === 0) {
      setSourceGlossary(INIT_SOURCE_GLOSSARY);
    } else if (activeStep >= 1) {
      // setTargetGlossary(INIT_TARGET_GLOSSARY);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleDiv title={"" + t("glossary-management")}></TitleDiv>
        </Grid>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <div>
            {activeStep === steps.length ? (
              <div className="text-center" style={{ minHeight: "740px" }}>
                {/* <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button> */}
              </div>
            ) : (
              <div>
                <div style={{ minHeight: "700px" }}>{getStepContent(activeStep)}</div>
                <div className="text-center">
                  <CancelButton hidden={activeStep === 0} onClick={handleBack}>
                    Back
                  </CancelButton>
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default GlossariesStepMain;
