import { Button, createStyles, Grid, makeStyles, MenuItem, Table, Theme } from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { ActionButton } from "components/Common/Button";
import SimpleSelect from "components/Common/Custom/SimpleSelect";
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PRIMARY } from "style/theme";
import { BaseInfoApi, ErrorHandler, ProjectApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { Baselanguage, DesignFile, MainProject, SubProject } from "system/types";
import { getTitleByField } from "system/util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      dispalay: "inline-block",
    },
    info: {
      textAlign: "center",
    },
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
    input: {
      width: "100%",
    },
    root: {
      margin: "10px 0px",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    hidden: {
      display: "none",
    },
    fileCheck: {
      width: "100%",
      margin: "10px 0px",
      padding: "10px 0px",
    },
    fileInputLabel: {
      marginRight: "10px",
    },
  })
);

interface Props {
  _main: MainProject;
  _sub: SubProject;
  _fileCheck: Dispatch<SetStateAction<boolean>>;
  _setActiveStep: Dispatch<SetStateAction<number>>;
}

function SourceUpload({ _main, _sub, _fileCheck, _setActiveStep }: Props): ReactElement {
  const classes = useStyles();
  const [subProjectList, setSubProjectList] = useState<SubProject[]>([]);
  const [subProejctId, setSubProjectId] = useState<number>(_sub.id);
  const [languageCode, setLanguageCode] = useState<string>("");

  // DB 및 Design 파일 보관용
  const [dbFile, setDBFile] = useState<File[]>([]);
  // 기존에 존재하는 Design 파일용
  const [designFile, setDesignFile] = useState<DesignFile[]>([]);
  // 새로 업로드하는 Design 파일용
  const [designRawFile, setDesignRawFile] = useState<File[]>([]);

  // 기존 DB 파일 존재여부 확인
  const [hasDBFile, setHasDBFile] = useState<boolean>(false);
  const [hasDesignFile, setHasDesignFile] = useState<boolean>(false);

  const [designFileUrl, setDesignFileUrl] = useState<string>("");

  const loadingDispatch = useLoadingDispatch();
  const { t } = useTranslation();

  useMemo(() => {
    if (_main && _main.id) {
      ProjectApi.GetSubProjectListByMainProjectId(_main.id)
        .then((res) => {
          setSubProjectList(res.data);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  }, [_main]);

  // onLoad 메서드의 호출 마다 실행
  useEffect(() => {
    // 파일이 있는지 확인
    loadingDispatch({ type: "LOADING" });
    ProjectApi.getMaxSourcePageNumber(subProejctId.toString(), languageCode)
      .then((res) => {
        const hasOnePage = res.data.max_page_no !== 0;
        setHasDBFile(hasOnePage);
        _fileCheck(hasOnePage);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => {
        loadingDispatch({ type: "COMPLETE" });
      });

    // 디자인 파일이 있는지 확인용으로 변경
    ProjectApi.getSubProjectDesignFileList(subProejctId.toString())
      .then((res) => {
        console.log(res, res.data.length, "design file check");

        // 파일이 있다면 다운로드 링크 GET
        if (res.data.length > 0) {
          // time_created 내림차순 정렬
          const sortObjectsByTimeCreated = (arr: DesignFile[]): DesignFile[] => {
            return arr
              .slice()
              .filter((item) => {
                return item.uploaded_design_version === "1.0.0";
              })
              .sort((a, b) => {
                const timeA = new Date(a.time_created).getTime();
                const timeB = new Date(b.time_created).getTime();
                return timeB - timeA;
              });
          };

          let sortedFileList = sortObjectsByTimeCreated(res.data);
          setDesignFile(sortedFileList);
          setHasDesignFile(sortedFileList.length !== 0);
          _fileCheck(sortedFileList.length !== 0);

          console.log(sortedFileList);

          loadingDispatch({ type: "LOADING" });
          ProjectApi.DownloadDesignFile(sortedFileList[0].id + "")
            .then((res) => {
              //window.location.href = res.data.download_url;
              setDesignFileUrl(res.download_url);
            })
            .catch((e) => {
              let msg = ErrorHandler(e);
              console.log(msg);
            })
            .finally(() => {
              loadingDispatch({ type: "COMPLETE" });
            });
        } else {
          setDesignFile(res.data);
          setHasDesignFile(res.data.length !== 0);
          _fileCheck(res.data.length !== 0);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => {
        loadingDispatch({ type: "COMPLETE" });
      });
  }, [subProejctId, languageCode]);

  const onChangeSubProject = (args: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (typeof args.target.value === "number") {
      setSubProjectId(args.target.value);
    }
  };
  const onChangeLanguageCode = (args: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (typeof args.target.value === "string") setLanguageCode(args.target.value);
  };
  const handleClickDBFile = (e: any) => {
    setDBFile(e.target.files);
  };
  const handleClickDesignFile = (e: any) => {
    setDesignRawFile(e.target.files);
  };

  const confirmDBFile = () => {
    if (languageCode === "") {
      alert(t("dialogue-select-source-language"));
      return;
    }
    if (!hasDBFile || !hasDesignFile) {
      if (
        !window.confirm(
          t("dialogue-check-file-subject-and-language-code") +
            "\n\n" +
            t("dialogue-project-name") +
            _sub.project_name +
            "\n" +
            t("dialogue-language-code") +
            languageCode
        )
      ) {
        return;
      }
      loadingDispatch({ type: "LOADING" });
      ProjectApi.FileUploadSubProject(dbFile[0], "" + subProejctId, languageCode)
        .then(() => {
          _setActiveStep(3);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          console.log(msg);
        })
        .finally(() => {
          loadingDispatch({ type: "COMPLETE" });
        });
    } else {
      alert("Existing file upload, upload not possible.");
    }
  };

  const confirmDesignFile = () => {
    if (!window.confirm(t("dialogue-project-name") + _sub.project_name)) {
      return;
    }
    loadingDispatch({ type: "LOADING" });
    ProjectApi.DesignFileUploadSubProject(designRawFile[0], "" + subProejctId, "1.0.0")
      .then(() => {
        _setActiveStep(3);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        console.log(msg);
      })
      .finally(() => {
        loadingDispatch({ type: "COMPLETE" });
      });
  };

  const downloadDesignFile = () => {
    console.log("download", designFile);
    window.location.href = designFileUrl;
  };

  const [baselanguageList, setBaselanguageList] = useState<Baselanguage[]>([]);
  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaselanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <th rowSpan={2}>{t("design-file-(zip)")}</th>
                <td>
                  {hasDesignFile ? (
                    <>
                      <label htmlFor="file-upload">
                        {designFile.length > 0 ? (
                          <div className={classes.fileInputLabel}>{designFile[0].uploaded_design_filename}</div>
                        ) : (
                          ""
                        )}
                      </label>
                      <Button
                        id="file-upload"
                        variant="contained"
                        color="primary"
                        onClick={downloadDesignFile}
                        className={classes.button}
                      >
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
                <td className={classes.info}></td>
              </tr>
              <tr>
                <td>
                  <div className={classes.root}>
                    <input
                      accept=".zip"
                      className={classes.hidden}
                      id="contained-button-design-file"
                      type="file"
                      onChange={handleClickDesignFile}
                    />
                    <label htmlFor="contained-button-design-file">
                      <div className={classes.fileInputLabel}>
                        {designRawFile && designRawFile[0] ? <span>{designRawFile[0].name}</span> : <></>}
                      </div>
                    </label>
                    <label htmlFor="contained-button-design-file">
                      <Button variant="contained" style={{ color: PRIMARY }} component="span">
                        <FolderOpenIcon></FolderOpenIcon>
                      </Button>
                    </label>
                  </div>
                </td>
                <td className={classes.info}>
                  <ActionButton
                    disabled={designRawFile.length === 0}
                    onClick={confirmDesignFile}
                    variant="contained"
                    className={classes.button}
                  >
                    {t("upload")}
                  </ActionButton>
                </td>
              </tr>
              <tr>
                <th style={{ minWidth: "120px" }}>{t("category-name")}</th>
                <td>
                  <SimpleSelect
                    id="sub_project_id"
                    name="sub_project_id"
                    label={"" + t("select-category-name")}
                    value={subProejctId}
                    onChange={onChangeSubProject}
                    className={classes.input}
                  >
                    {subProjectList.map((item) => {
                      return (
                        <MenuItem value={item.id}>
                          {item.project_type === 1 ? "User Manual" : ""}
                          {item.project_type === 2 ? "Result Sheet" : ""}
                          {item.project_type === 3 ? "LCD" : ""}
                          {item.project_type === 4 ? "Catalog" : ""}
                          {item.project_type === 5 ? "Voice" : ""}
                          {item.project_type === 6 ? "ETC" : ""} - {item.project_name}
                        </MenuItem>
                      );
                    })}
                  </SimpleSelect>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>{t("source-language")}</th>
                <td>
                  <SimpleSelect
                    id="language_code"
                    name="language_code"
                    label={"" + t("select-source-language")}
                    value={languageCode}
                    onChange={onChangeLanguageCode}
                    className={classes.input}
                  >
                    <MenuItem value={""}>
                      <em>{t("select-source-language")}</em>
                    </MenuItem>
                    {/* <MenuItem value={"EN_EN"}>English(Common)</MenuItem>
                    <MenuItem value={"KO"}>Korean</MenuItem> */}
                    <MenuItem
                      value={
                        subProjectList.find((subProject) => subProject.id === subProejctId)?.source_lang_code ||
                        "default-value"
                      }
                    >
                      {getTitleByField(
                        subProjectList.find((subProject) => subProject.id === subProejctId)?.source_lang_code || ""
                      )}
                    </MenuItem>

                    {/* {baselanguageList.map((baselanguage: Baselanguage, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          value={baselanguage.lang_code}
                        >{`${baselanguage.lang_name_english}`}</MenuItem>
                      );
                    })} */}
                  </SimpleSelect>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>{t("db-file")}</th>
                <td>
                  {hasDBFile ? (
                    <div className={classes.fileCheck}>{t("dialogue-file-already-exists")}</div>
                  ) : (
                    <div className={classes.root}>
                      <input
                        accept=".db"
                        className={classes.hidden}
                        id="contained-button-file"
                        type="file"
                        onChange={handleClickDBFile}
                      />
                      <label htmlFor="contained-button-file">
                        <Button variant="contained" style={{ color: PRIMARY }} component="span">
                          <FolderOpenIcon></FolderOpenIcon>
                        </Button>
                      </label>
                      <label htmlFor="contained-button-file">
                        <div className={classes.fileInputLabel}>
                          {dbFile && dbFile[0] ? <span>{dbFile[0].name} </span> : <></>}
                        </div>
                      </label>
                    </div>
                  )}
                </td>
                <td className={classes.info}>
                  {!hasDBFile && (
                    <ActionButton
                      disabled={dbFile.length === 0}
                      onClick={confirmDBFile}
                      variant="contained"
                      className={classes.button}
                    >
                      {t("upload")}
                    </ActionButton>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Grid>
        <Grid item xs={3}></Grid>
        {/* <Grid item xs={12} className={classes.info}>
          <div></div>
          <h4>*파일 업로드시 자동으로 이동합니다.</h4>
        </Grid> */}
      </Grid>
    </>
  );
}

export default SourceUpload;
