import {
  Baselanguage,
  DesignFile,
  Group,
  ImageText,
  ImageTextData,
  ImageTextDataManual,
  ImageTextTarget,
  MainProject,
  MaterialTableOptions,
  Memo,
  Product,
  Project,
  ProjectCompleteHistory,
  SearchBasetextHistory,
  SearchTextCode,
  SearchTranstext,
  SimpleTargetText,
  SourceBasetext,
  SourceGlossary,
  SourceTargetMatching,
  SourceTextOutput,
  SubProject,
  TargetBasetext,
  TargetGlossary,
  TargetTextData,
  TranslateProject,
  UserInfo,
  SelectedItem,
  SelectedSentence,
} from "system/types/types";

// *************************************************************
//                        User
// *************************************************************
export const INIT_GROUP: Group = {
  group_role: 0,
  group_name: "",
  trans_lang_code: "",
  is_admin: 0,
  is_translator: 0,
  id: 0,
};

export const INIT_USER_INFO: UserInfo = {
  id: 0,
  admin: false,
  active_account: true,
  locale: "",
  user_name: "",
  user_email: "",
  user_tel: "",
  user_native_lang: "",
  register_date: "",
  user_id: "",
  password: "",
  user_translate_lang: "",
  group_role: 0,
};

// *************************************************************
//                        Glossary
// *************************************************************
export const INIT_SOURCE_GLOSSARY: SourceGlossary = {
  id: 0,
  time_created: "",
  time_updated: "",
  lang_code: "",
  glossary_text: "",
};

export const INIT_TARGET_GLOSSARY: TargetGlossary = {
  id: 0,
  time_created: "",
  time_updated: "",
  lang_code: "",
  glossary_text: "",
  src_glossary_id: 0,
};

// *************************************************************
//                        Basetext
// *************************************************************
export const INIT_SOURCE_BASETEXT: SourceBasetext = {
  id: 0,
  time_created: "",
  time_updated: "",
  lang_code: "",
  basetext_text: "",
};

export const INIT_TARGET_BASETEXT: TargetBasetext = {
  id: 0,
  time_created: "",
  time_updated: "",
  lang_code: "",
  basetext_text: "",
  src_basetext_id: 0,
};

export const INIT_SEARCH_TRANSTEXT: SearchTranstext = {
  managed_text: "",
  lang_code: "",
  search_lang_code: "",
};

export const INIT_SEARCH_TEXTCODE: SearchTextCode = {
  managed_text: "",
  lang_code: "",
};

export const INIT_SEARCH_BASETEXT_HISTORY: SearchBasetextHistory = {
  text_code: "",
  lang_code: "",
};

// export const INIT_BASETEXT_HISTORY: BasetextHistory = {};
// export const INIT_BASETEXT_HISTORY: BasetextHistory = {
//   id: 0,
//   basetext_id: 0,
//   time_created: "",
//   text_code: "",
//   lang_code: "",
//   history_managed_text: "",
//   sentence_hash: "",
//   history_data: [],
// };

// *************************************************************
//                        Basetext
// *************************************************************
export const INIT_TARGET_TEXT_DATA: TargetTextData = {
  source_id: 0,
  source_sentence: "",
  target_id: 0,
  target_sentence: "",
  is_rejected: false,
  is_changed: false,
  basetext_sentence: "",
  chatgpt_sentence: "",
  basetextHistory_sentence: [],
  is_onWrite: false,
  hasTargetSentence: false,
  isExcept: false,
  isUseOrigin: false,
};

// *************************************************************
//         ImageText : 관리자용 원문 데이터 수신용 DTO 초기값
// *************************************************************
export const INIT_IMAGETEXT: ImageText = {
  dir_order_no: 0,
  encoded_image_string: "",
  image_text_data: [],
};

// *************************************************************
//  ImageTextTarget : 번역가용 원문/번역문 데이터 수신용 DTO 초기값
// *************************************************************
// (신) dir_order_no 적용한 이후의 DTO
export const INIT_IMAGETEXTTARGET: ImageTextTarget = {
  dir_order_no: 0,
  encoded_image_string: "",
  image_text_data: [],
};
// (구) dir_order_no 적용하기 이전의 DTO
// export const INIT_IMAGETEXTTARGET: ImageTextTarget = {
//   dir_order_no: 0,
//   image_data: "",
//   text_data: [],
// };
export const INIT_IMAGETEXTDATA: ImageTextData = {
  is_rejected: false,
  source_id: 0,
  source_sentence: "",
  target_id: 0,
  target_sentence: "",
  is_changed: false,
  // 기준문장
  basetext_sentence: "",
  // chatGPT 문장
  chatgpt_sentence: "",
  // 기존 번역 문장
  basetextHistory_sentence: [],
  // 저장되지 않은 변경문장 존재여부 저장
  is_onWrite: false,
  // 번역문장 존재여부 저장
  hasTargetSentence: false,
  isExcept: false,
  isUseOrigin: false,
};

export const INIT_IMAGETEXTDATA_MANUAL: ImageTextDataManual = {
  is_rejected: false,
  source_id: 0,
  source_sentence: "",
  target_id: 0,
  target_sentence: "",
  is_changed: false,
  // 기준문장
  basetext: [],
  // chatGPT 문장
  chatgpt_text: [],
  // 기존 번역 문장
  basetext_history: [],
  // 저장되지 않은 변경문장 존재여부 저장
  is_onWrite: false,
  // 번역문장 존재여부 저장
  hasTargetSentence: false,
  isExcept: false,
  isUseOrigin: false,
};

// *************************************************************
//                        BaseInformation(Baselanguage, Product)
// *************************************************************
export const INIT_BASE_LANGUAGE: Baselanguage = {
  lang_code: "",
  lang_name_korean: "",
  lang_name_english: "",
};

export const INIT_PRODUCT: Product = { product_name: "", product_code: "" };

// *************************************************************
//                        Project
// *************************************************************
export const INIT_PROJECT: Project = {
  projectName: "",
  dueDate: new Date(),
  sourceLanguage: "",
  targetLanguage: [],
};

export const INIT_MAIN_PROJECT: MainProject = {
  id: 0,
  project_name: "",
  product_code: "",
  project_managergroup_id: 0,
  project_duedate: "",
  // 새로 추가
  project_status: "",
  is_closed: false,
  time_created: "",
};

export const INIT_SUB_PROJECT: SubProject = {
  id: 0,
  project_name: "",
  project_type: 0,
  source_lang_code: "",
  target_lang_code: [],
  main_project_id: 0,
  project_duedate: "",
  // 새로 추가
  project_status: "",
  is_closed: false,
  time_created: "",
};

export const INIT_TRANSLATE_PROJECT: TranslateProject = {
  id: 0,
  is_closed: false,
  is_closed_main_project: false,
  is_closed_sub_project: false,
  product_code: "",
  project_duedate: "",
  project_name_main_project: "",
  project_name_sub_project: "",
  project_status: "",
  project_type: 0,
  source_lang_code: "",
  sub_project_id: 0,
  target_lang_code: "",
  translator_group_id: 0,
  source_text_array: [],
  target_text_array: [],
  time_created: "",
  is_chatgpt_only: false,
};

export const INIT_SOURCETEXT_OUTPUT: SourceTextOutput = {
  id: 0,
  is_except: false,
  time_created: "",
  time_updated: "",
  source_sentence: "",
  source_lang_code: "",
  source_page_no: 0,
  sub_project_id: 0,
  // todo: 1로 초기화되어있었음
  sourceimage_id: 0,
};

export const INIT_DESIGN_FILE: DesignFile = {
  id: 0,
  time_created: "",
  time_updated: "",
  sub_project_id: 0,
  uploaded_design_filename: "",
  uploaded_design_version: "",
};

export const INIT_SOURCE_TARGET_MATCHING: SourceTargetMatching = {
  page_no: 0,
  source_sentence_id: 0,
  source_sentence_langcode: "",
  source_sentence: "",
  target_sentence_langcode: "",
  target_sentences: [],
  is_translated: false,
};

export const INIT_SIMPLE_TARGET_TEXT: SimpleTargetText = {
  target_sentence_id: 0,
  target_sentence: "",
};

export const INIT_MEMO: Memo = {
  id: 0,
  time_created: "",
  time_updated: "",
  translate_project_id: 0,
  siteuser_id: 0,
  memo_type: 0,
  page_no: 0,
  memo_contents: "string",
  memo_disable: true,
};

export const INIT_CLICKCOUNT = {
  SR: 0,
  DE: 0,
  CN: 0,
  HI: 0,
  EL: 0,
  FR_FR: 0,
  KO: 0,
  MT: 0,
  NO: 0,
  EN_US: 0,
  AR: 0,
  BR: 0,
  EN_EN: 0,
  BG: 0,
  FR_US: 0,
  HR: 0,
  CS: 0,
  DA: 0,
  NL: 0,
  ZZ_ZZ: 0,
  ET: 0,
  FI: 0,
  HU: 0,
  IW: 0,
  ES_MX: 0,
  MS: 0,
  LT: 0,
  LV: 0,
  JA: 0,
  IT: 0,
  PL: 0,
  GA: 0,
  PT: 0,
  RO: 0,
  RU: 0,
  SL: 0,
  SK: 0,
  ES_SP: 0,
  TW: 0,
  SV: 0,
  UK: 0,
  VI: 0,
  TR: 0,
  TH: 0,
  TA: 0,
  TE: 0,
};

// *************************************************************
//                        Project Complete History
// *************************************************************

export const INIT_PROJECT_COMPLETE_HISTORY: ProjectCompleteHistory = {
  id: 0,
  time_created: "",
  time_updated: "",
  translate_project_id: 0,
  siteuser_id: 0,
  user_id: "",
  user_name: "",
  complete_type: 0,
};

// *************************************************************
//                        Options
// *************************************************************
export const INIT_MATERIALTABLEOPTIONS: MaterialTableOptions = {
  add: false,
  edit: false,
  delete: false,
  rowClick: false,
  select: false,
  isClosed: false,
  onRowClick: () => {},
  onRowClickMainProject: () => {},
  onRowClickSubProject: () => {},
  onRowClickTranslateProject: () => {},
};

// *************************************************************
//                        번역가 화면에서 사용되는 객체
// *************************************************************
export const INIT_SELECTED_ITEM: SelectedItem = {
  newData: INIT_IMAGETEXTDATA_MANUAL,
  index: 0,
  subIndex: 0,
  lastTwoSentences: [],
  projectReOpenHistory: INIT_PROJECT_COMPLETE_HISTORY,
  isClosed: false,
  isFinished: false,
};

export const INIT_SELECTED_SENTENCE: SelectedSentence = {
  index: 0,
  subIndex: 0,
  sentence: "",
};
