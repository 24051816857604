import { Grid, Step, StepButton, Stepper } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { CancelButton, SaveButton } from "components/Common/Button";
import TitleDiv from "components/Common/TitleDiv";
import React, { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { MainProjectAtom, SubProjectAtom, TranslateProjectAtom } from "system/atom";
import {
  INIT_MAIN_PROJECT,
  INIT_MATERIALTABLEOPTIONS,
  INIT_SUB_PROJECT,
  INIT_TRANSLATE_PROJECT,
  MainProject,
  MaterialTableOptions,
  SubProject,
  TranslateProject,
} from "system/types";
import TranslateComponent from "../SourceText/TranslateComponent";
import TranslateProjectManualComponent from "../SourceText/TranslateProjectManualComponent";
import SourceUpload from "../SourceUpload/SourceUpload";
import SourceVoiceUpload from "../SourceUpload/SourceVoiceUpload";
import SubProjectTable from "../SubProject/SubProjectTable";
import TranslateProjectTable from "../TranslateProject/TranslateProjectTable";
import VoiceFileUpload from "../Voice/VoiceFileUpload";
import StepByStepMainProject from "./StepByStepMainProject";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
  })
);
function StepByStep(): ReactElement {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [main, setMain] = useState<MainProject>(INIT_MAIN_PROJECT);
  const [sub, setSub] = useState<SubProject>(INIT_SUB_PROJECT);

  const [mainProject, setMainProject] = useRecoilState(MainProjectAtom);
  const [subProject, setSubProject] = useRecoilState(SubProjectAtom);
  const [translateProject, setTranslateProject] = useRecoilState(TranslateProjectAtom);
  const [translate, setTranslate] = useState<TranslateProject>(INIT_TRANSLATE_PROJECT);
  const [fileCheck, setFilekCheck] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const steps = useMemo(() => getSteps(sub.project_type), [sub.project_type, i18n.language]);

  function getSteps(type: number) {
    const selectProject = t("select-project");
    const selectCategory = t("select-category");
    const addScript = t("add-script");
    const selectAssignment = t("select-assignment");
    const fileUpload = t("upload-file");
    const checkExtractedSentences = t("check-extracted-sentences");

    if (type === 5) {
      return [selectProject, selectCategory, addScript, selectAssignment, fileUpload];
    } else {
      return [selectProject, selectCategory, fileUpload, checkExtractedSentences, selectAssignment];
    }
  }
  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };
  const options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    add: true,
    select: true,
    onRowClickMainProject: (param: MainProject) => {
      setMain(param);
      // recoil로 변환
      setMainProject(param);
      setActiveStep(1);
    },
    onRowClickSubProject: (param: SubProject) => {
      setSub(param);
      setSubProject(param);
      setActiveStep(2);
    },
    onRowClickTranslateProject: (param: TranslateProject) => {
      setTranslate(param);
      setTranslateProject(param);
      console.log("translateProject setted", param);
    },
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <StepByStepMainProject
            _main={main}
            onRowClickMainProject={options.onRowClickMainProject}
          ></StepByStepMainProject>
        );
      case 1:
        return (
          <>
            <SubProjectTable options={options} _main={main}></SubProjectTable>
          </>
        );
      case 2:
        return (
          <>
            {sub.project_type === 5 ? (
              <>
                <SourceVoiceUpload _main={main} _sub={sub}></SourceVoiceUpload>
              </>
            ) : (
              <SourceUpload
                _main={main}
                _sub={sub}
                _fileCheck={setFilekCheck}
                _setActiveStep={setActiveStep}
              ></SourceUpload>
            )}
          </>
        );
      case 3:
        return (
          <>
            {sub.project_type === 5 ? (
              // Voice의 경우
              <>
                <VoiceFileUpload _main={main} _sub={sub}></VoiceFileUpload>
              </>
            ) : sub.project_type === 1 || sub.project_type === 4 ? (
              // User Manual, Catalog의 경우
              <>
                <TranslateProjectManualComponent _main={main} _sub={sub}></TranslateProjectManualComponent>
              </>
            ) : (
              // 나머지(LCD, 결과지)의 경우
              <>
                <TranslateComponent _main={main} _sub={sub}></TranslateComponent>
              </>
            )}
          </>
        );
      case 4:
        return (
          <>
            {/* <MainProjectTemplate templateType="show" main={main}></MainProjectTemplate>
            <SubProjectTemplate templateType="show" _main={main} _sub={sub}></SubProjectTemplate> */}
            <hr />
            <TranslateProjectTable
              options={{ ...options, rowClick: false }}
              _sub={sub}
              _setSub={setSub}
            ></TranslateProjectTable>
          </>
        );
      default:
        return "Unknown step";
    }
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 0 && main.id === 0) {
      alert("제품 과제를 먼저 선택 또는 생성해주세요.");
      return;
    } else if (activeStep === 1 && sub.id === 0) {
      alert("카테고리 과제를 먼저 선택 또는 생성해주세요.");
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setMain(INIT_MAIN_PROJECT);
    } else if (activeStep === 2) {
      setSub(INIT_SUB_PROJECT);
      setSubProject(INIT_SUB_PROJECT);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsClickedOK(false);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step: number) => () => {
    if (step === 1) {
      setSub(INIT_SUB_PROJECT);
      setSubProject(INIT_SUB_PROJECT);
    }
    setActiveStep(step);
  };

  const [isClickedOK, setIsClickedOK] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <TitleDiv title="신규/진행중 과제"></TitleDiv> */}
          <TitleDiv title={t("create-edit")}></TitleDiv>
        </Grid>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepButton {...labelProps} onClick={handleStep(index)}>
                    <span style={{ fontWeight: index <= activeStep ? "bold" : "", fontSize: "medium" }}>{label}</span>
                    {index === 0 && main.project_name !== "" ? (
                      <div style={{ color: "grey" }}>
                        <em>
                          <b>{" - " + main.project_name}</b>
                        </em>
                      </div>
                    ) : (
                      <em></em>
                    )}
                    {index === 1 && sub.project_name !== "" ? (
                      <div style={{ color: "grey" }}>
                        <em>
                          <b>{" - " + sub.project_name}</b>
                        </em>
                      </div>
                    ) : (
                      <em></em>
                    )}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <div>
            {activeStep === steps.length ? (
              <div className="text-center" style={{ minHeight: "740px", marginTop: "100px" }}></div>
            ) : (
              <div>
                <div style={{ minHeight: "700px" }}>{getStepContent(activeStep)}</div>
                <br />
                <div className="text-center">
                  <CancelButton hidden={activeStep === 0} onClick={handleBack} className={classes.button}>
                    Back
                  </CancelButton>
                  {activeStep !== steps.length - 1 ? (
                    // <SaveButton disabled={!fileCheck} hidden={activeStep === 0} onClick={handleNext}>
                    <SaveButton disabled={false} hidden={activeStep === 0} onClick={handleNext}>
                      {"Next(" + (activeStep + 1) + "/" + steps.length + ")"}
                    </SaveButton>
                  ) : (
                    <SaveButton
                      disabled={false}
                      onClick={() => {
                        setIsClickedOK(true);
                        alert("Project has been successfully created and will be visible to translators.");
                      }}
                    >
                      OK
                    </SaveButton>
                  )}
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default StepByStep;
