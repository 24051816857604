import { Grid, Snackbar } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { ActionButton } from "components/Common/Button";
import MemoModalContent from "components/Common/MemoModalContent";
import SourceTargetMatchingModalContent from "components/Common/SourceTargetMatchingModalContent";
import TitleDiv from "components/Common/TitleDiv";
import MaterialTable from "material-table";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { MyRoutes } from "routes/Routes";
import { BaseInfoApi, ErrorHandler, ProjectApi } from "system/ApiService";
import { PROJECT_TYPE, TABLEICONS } from "system/Constants";
import { useLoadingDispatch } from "system/LoadingContext";
import { MainProjectAtom, SubProjectAtom, TranslateProjectAtom } from "system/atom";
import {
  Baselanguage,
  INIT_MATERIALTABLEOPTIONS,
  INIT_TRANSLATE_PROJECT,
  MainProject,
  MaterialTableOptions,
  SubProject,
  TranslateProject,
} from "system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
  })
);

function StepByStepComplete(): ReactElement {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const [main, setMain] = useRecoilState(MainProjectAtom);
  const [sub, setSub] = useRecoilState(SubProjectAtom);
  const [translate, setTranslate] = useRecoilState(TranslateProjectAtom);

  const { t, i18n } = useTranslation();
  const steps = useMemo(() => getSteps(sub.project_type), [sub.project_type, i18n.language]);

  const history = useHistory();

  const [translateProjectList, setTranslateProjectList] = useState<TranslateProject[]>([]);
  const [baselanguageList, setBaselanguageList] = useState<Baselanguage[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const memoModal = useModal();

  const [open, setOpen] = React.useState(false);

  const loading = useLoadingDispatch();
  // main, sub 정보도 포함되어있는 translate project
  const [sourceTargetTranslateProject, setSourceTargetTranslateProject] =
    useState<TranslateProject>(INIT_TRANSLATE_PROJECT);

  function getSteps(type: number) {
    const selectProject = t("select-project");
    const selectCategory = t("select-category");
    const addScript = t("add-script");
    const selectAssignment = t("select-assignment");
    const fileUpload = t("upload-file");
    const checkExtractedSentences = t("check-extracted-sentences");

    if (type === 5) {
      return [selectProject, selectCategory, selectAssignment, fileUpload];
    } else {
      return [selectProject, selectCategory, selectAssignment, checkExtractedSentences];
    }
  }

  const options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    isClosed: true,

    onRowClickMainProject: (param: MainProject) => {
      setMain(param);
      // setMainProject(param);
      setActiveStep(1);
    },
    onRowClickSubProject: (param: SubProject) => {
      setSub(param);
      // setSubProject(param);
      setActiveStep(2);
    },
    onRowClickTranslateProject: (param: TranslateProject) => {
      setTranslate(param);
      // setTranslateProject(param);
      setActiveStep(3);
    },
  };

  const filterClosedTranslateProjects = (
    mainProjects: MainProject[],
    subProjects: SubProject[],
    translateProjects: TranslateProject[]
  ) => {
    translateProjects.forEach((row) => {
      switch (row.project_status) {
        case "Completed":
        case "Complete":
          row.project_status = "Completed";
          break;
        case "In progress":
          row.project_status = "In progress";
          break;
        default:
          if (row.is_closed) {
            row.project_status = "Translator completed";
          }
          break;
      }
    });
    return translateProjects
      .filter((tp) => tp.is_closed) // 닫힌 TranslateProject만 필터링
      .map((tp) => {
        const relatedSubProject = subProjects.find((sp) => tp.sub_project_id && sp.id === tp.sub_project_id);
        const relatedMainProject = mainProjects.find(
          (mp) => relatedSubProject && mp.product_code && mp.id === relatedSubProject.main_project_id
        );

        return {
          ...tp,
          project_name_main_project: relatedMainProject?.project_name,
          project_name_sub_project: relatedSubProject?.project_name,
          is_closed_main_project: relatedMainProject?.is_closed,
          is_closed_sub_project: relatedSubProject?.is_closed,
          product_code: relatedMainProject?.product_code,

          project_type: relatedSubProject?.project_type,

          source_text_array: [],
          target_text_array: [],
          time_created: "",
        };
      });
  };

  const downloadBasetextExcel = (translate_project_id: number) => {
    console.log("data");

    loading({ type: "LOADING" });
    ProjectApi.DownloadBasetextXLSX(translate_project_id)
      .then((res) => {
        window.location.href = res.data.download_url;
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      })
      .finally(() => {
        loading({ type: "COMPLETE" });
      });
  };

  // 완료프로젝트 정보 조회를 위한 코드
  const getAllTranslateProject = () => {
    // 예제test

    let a: MainProject[];
    let b: SubProject[];
    let c: TranslateProject[];

    ProjectApi.GetMainProjectList()
      .then((res) => {
        a = res.data;
        ProjectApi.GetSubProjectList()
          .then((res) => {
            b = res.data;
            ProjectApi.GetTranslateProjectList()
              .then((res) => {
                c = res.data;

                const createdData = filterClosedTranslateProjects(a, b, c).filter((x) => {
                  return x.product_code && x.project_name_main_project && x.project_name_sub_project;
                });
                setTranslateProjectList(createdData);
              })
              .catch((e) => {
                let msg = ErrorHandler(e);
                console.log(msg);
              });
          })
          .catch((e) => {
            let msg = ErrorHandler(e);
            console.log(msg);
          });
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  };

  useEffect(() => {
    getAllTranslateProject();
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaselanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  const baseLangLookup: Object = baselanguageList.reduce((x, y) => ({ ...x, [y.lang_code]: y.lang_name_english }), {});

  const INIT_COLUMNS = [
    {
      title: "" + t("ID"),
      field: "id",
      cellStyle: { width: "10%" },
    },
    {
      title: "" + t("project-name"),
      field: "project_name_main_project",
      cellStyle: { width: "10%" },
      defaultGroupOrder: 2,
    },
    {
      title: "" + t("category-name"),
      field: "project_name_sub_project",
      cellStyle: { width: "10%" },
      defaultGroupOrder: 4,
    },
    {
      title: "" + t("product-code"),
      field: "product_code",
      cellStyle: { width: "10%" },
      defaultGroupOrder: 1,
    },
    {
      title: "" + t("project-type"),
      field: "project_type",
      cellStyle: { width: "10%" },
      lookup: PROJECT_TYPE,
      defaultGroupOrder: 3,
    },
    {
      title: "" + t("target-language"),
      field: "target_lang_code",
      cellStyle: { width: "10%" },
      lookup: baseLangLookup,
    },
    { title: "" + t("duedate"), field: "project_duedate", cellStyle: { width: "10%" } },
    {
      title: "" + t("closed-status"),
      field: "is_closed",
      cellStyle: { width: "10%" },
      lookup: { true: "Closed" },
    },
    {
      title: "" + t("project-status"),
      field: "project_status",
      cellStyle: { width: "10%" },
      lookup: {
        Completed: "Completed",
        "Translator completed": "Translator completed",
      },
      emptyValue: "-",
    },

    {
      title: "" + t("is chatgpt only"),
      field: "is_chatgpt_only",
      cellStyle: { width: "10%" },
      lookup: { true: "chatgpt", false: "-" },
    },

    {
      title: "",
      field: "",
      cellStyle: { width: "5%" },
      render: (rowData: TranslateProject) => {
        return (
          <div className="text-end">
            <ActionButton
              style={{ padding: "5px" }}
              onClick={(event) => {
                event.stopPropagation();
                setSourceTargetTranslateProject(() => {
                  return rowData;
                });

                memoModal.open();
              }}
              title={"Clicking on MEMO shows project’s full page memos."}
            >
              MEMO
            </ActionButton>
          </div>
        );
      },
    },

    {
      title: "",
      field: "",
      cellStyle: { width: "5%" },
      render: (rowData: TranslateProject) => {
        return (
          <div className="text-end">
            <ActionButton
              style={{ padding: "5px" }}
              onClick={(event) => {
                event.stopPropagation();
                downloadBasetextExcel(rowData.id ? rowData.id : -1);
              }}
              title={"Download data that matches translated sentences in Basetext with the original sentences."}
            >
              {rowData.is_chatgpt_only ? "Export ChatGPT" : "Export Basetext"}
            </ActionButton>
          </div>
        );
      },
    },

    {
      title: "",
      field: "",
      cellStyle: { width: "5%" },
      render: (rowData: TranslateProject) => {
        return (
          <div className="text-end">
            <ActionButton
              style={{ padding: "5px" }}
              onClick={(event) => {
                event.stopPropagation();
                setSourceTargetTranslateProject(() => {
                  return rowData;
                });

                setIsModalOpen(true);
              }}
              title={"Clicking on PREVIEW shows project’s full translations."}
            >
              SUMMARY
            </ActionButton>
          </div>
        );
      },
    },
    {
      title: "",
      field: "",
      cellStyle: { width: "5%" },
      render: (rowData: TranslateProject) => {
        return (
          <div className="text-end">
            <ActionButton style={{ padding: "5px" }} onClick={() => {}}>
              SHOW
            </ActionButton>
          </div>
        );
      },
    },
  ];
  const [hiddenColumns, setHiddenColumns] = React.useState(["id"]);

  const visibleColumns = React.useMemo(
    () => INIT_COLUMNS.filter((column) => !hiddenColumns.includes(column.field)),
    [hiddenColumns, INIT_COLUMNS]
  );

  function useModal() {
    const [isOpen, setIsOpen] = useState(false);

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);
    const toggle = () => setIsOpen((prev) => !prev);

    return { isOpen, open, close, toggle };
  }
  const handleDataCheck = (hasData: boolean) => {
    if (!hasData) {
      setOpen(true); // hasData가 false일 때 Snackbar를 띄웁니다.
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleDiv title={t("completed-project")}></TitleDiv>
        </Grid>
        <Grid item xs={12}>
          {/* <button onClick={() => toggleColumnVisibility("id")}>Toggle id Column</button> */}
          <button
            onClick={() =>
              setHiddenColumns((prev) => (prev.includes("id") ? prev.filter((x) => x !== "id") : [...prev, "id"]))
            }
          >
            Toggle ID Column
          </button>
          <MaterialTable
            title=""
            icons={TABLEICONS}
            onRowClick={(event, rowData) => {
              if (!rowData) return;
              history.push({
                pathname: MyRoutes.translatorProject + "/" + rowData.id,
                state: {
                  type: rowData.project_type,
                  closed: rowData.is_closed,
                  // todo : sub와 main의 이름을 여기서 저장중 - 상세페이지에서는 recoil state로 받도록 함
                  main_name: rowData.project_name_main_project,
                  sub_name: rowData.project_name_sub_project,
                },
              });
            }}
            columns={visibleColumns}
            data={translateProjectList || []}
            options={{
              filtering: true,
              padding: "dense",
              grouping: true,
              columnsButton: true,
              pageSize: 20,
              rowStyle: (rowData, index) => {
                return {
                  backgroundColor: rowData.is_chatgpt_only ? "#85BBAC" : "white",
                };
              },
            }}
            localization={{
              pagination: {
                labelRowsPerPage: "페이지 당 행 수:",
              },
            }}
          />
        </Grid>
      </Grid>
      <SourceTargetMatchingModalContent
        _translateProject={sourceTargetTranslateProject}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      ></SourceTargetMatchingModalContent>
      <MemoModalContent
        onDataCheck={handleDataCheck}
        _translateProject={sourceTargetTranslateProject}
        isOpen={memoModal.isOpen}
        onClose={() => {
          memoModal.close();
        }}
      ></MemoModalContent>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Memo is empty.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default StepByStepComplete;
