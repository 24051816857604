import { createStyles, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { ActionButton } from "components/Common/Button";
import MaterialTable from "material-table";
import React, { useEffect, useMemo, useState } from "react";
import { BaseInfoApi, ErrorHandler, GlossaryApi } from "system/ApiService";
import { TABLEICONS } from "system/Constants";
import { Baselanguage, INIT_SOURCE_GLOSSARY, MaterialTableOptions, ModalType, SourceGlossary } from "system/types";
import SourceGlossaryDialog from "./SourceGlossaryDialog";

interface SourceGlossaryTableProps {
  options: MaterialTableOptions;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: "right",
      marginLeft: "10px",
    },
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
  })
);
function SourceGlossaryTable({ options }: SourceGlossaryTableProps) {
  const classes = useStyles();
  const [data, setData] = useState<SourceGlossary[]>([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<ModalType>("edit");
  const [sourceGlossary, setSourceGlossary] = useState<SourceGlossary>(INIT_SOURCE_GLOSSARY);
  const [baselanguageList, setBaselanguageList] = useState<Baselanguage[]>([]);
  const baseLangLookup: Object = baselanguageList.reduce((x, y) => ({ ...x, [y.lang_code]: y.lang_name_english }), {});

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaselanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  const onLoad = React.useCallback(() => {
    GlossaryApi.GetSourceGlossaryList()
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const addSourceGlossary = () => {
    setSourceGlossary(INIT_SOURCE_GLOSSARY);
    setType("add");
    setOpen(true);
  };

  const onClose = () => {
    setSourceGlossary(INIT_SOURCE_GLOSSARY);
    setOpen(false);
  };

  const onDeleteSourceGlossary = (id: number) => {
    if (window.confirm("Are you sure to delete '" + id + "' ?")) {
      GlossaryApi.DeleteSourceGlossary(id)
        .then((res) => {
          onLoad();
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  };

  const onOpenModal = (sourceGlossary: SourceGlossary) => {
    setSourceGlossary(sourceGlossary);
    setType("edit");
    setOpen(true);
  };

  return (
    <Grid style={{ minWidth: "1000px" }}>
      <MaterialTable
        title=""
        icons={TABLEICONS}
        onRowClick={(event, rowData) => {
          if (!event) return;
          let target = event.target + "";
          if (!target.includes("SVG") && options.rowClick) {
            options.onRowClick(rowData);
          }
        }}
        columns={[
          { title: "Source Glossary", field: "glossary_text", cellStyle: { width: "45%" } },
          { title: "Language", field: "lang_code", cellStyle: { width: "15%" }, lookup: baseLangLookup },
          { title: "Created", field: "time_created", cellStyle: { width: "15%" } },
          { title: "Updated", field: "time_updated", cellStyle: { width: "15%" } },
          {
            title: "",
            field: "",
            cellStyle: { width: "10%" },
            render: (rowData) => {
              return options.select ? (
                <div className="text-end">
                  <ActionButton
                    style={{ padding: "5px" }}
                    onClick={() => {
                      options.onRowClick();
                    }}
                  >
                    SHOW
                  </ActionButton>
                </div>
              ) : (
                <div className={classes.buttonGroup}>
                  <IconButton className={classes.iconButton} onClick={() => onOpenModal(rowData || 0)}>
                    <CreateOutlinedIcon />
                  </IconButton>

                  <IconButton className={classes.iconButton} onClick={() => onDeleteSourceGlossary(rowData.id || 0)}>
                    <DeleteForeverOutlinedIcon />
                  </IconButton>
                </div>
              );
            },
          },
        ]}
        data={data || []}
        options={{
          filtering: true,
          padding: "dense",
          pageSize: 10,
          // rowStyle: (rowData) => {
          // let selected = sourceGlossary && sourceGlossary === rowData;
          // return options.rowClick
          //   ? {
          //       backgroundColor: selected ? SECONDARY : "#FFF",
          //     }
          //   : {};
          // },
        }}
        actions={[
          {
            icon: "add",
            tooltip: "",
            isFreeAction: true,
            onClick: (event) => {},
          },
        ]}
        components={{
          Action: (props) =>
            options.add ? (
              <ActionButton onClick={addSourceGlossary} variant="contained" className={classes.button}>
                <AddIcon />
                Add Source Glossary
              </ActionButton>
            ) : (
              <></>
            ),
        }}
      />

      <SourceGlossaryDialog
        _sourceGlossary={sourceGlossary || INIT_SOURCE_GLOSSARY}
        type={type}
        open={open}
        onClose={onClose}
        saveCallback={onLoad}
      ></SourceGlossaryDialog>
    </Grid>
  );
}

export default SourceGlossaryTable;
