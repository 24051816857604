import { createStyles, Grid, makeStyles, MenuItem, TextField, Theme } from "@material-ui/core";
import { CancelButton, SaveButton } from "components/Common/Button";
import SimpleSelect from "components/Common/Custom/SimpleSelect";
import { useEffect, useMemo, useState } from "react";
import { BaseInfoApi, ErrorHandler, GroupApi, ProjectApi } from "system/ApiService";
import { Group, INIT_MAIN_PROJECT, MainProject, Product, TemplateType } from "system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    bottom: {
      width: "100%",
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    button: {
      width: "100%",
      padding: theme.spacing(1.5),
      fontSize: "1.0rem",
    },
  })
);

interface MainProjectProps {
  main: MainProject;
  templateType: TemplateType;
  onClose?: () => void;
  onRowClickMainProject?: (param: MainProject) => void;
  saveCallback?: () => void;
}

function MainProjectTemplate({ main, templateType, onClose, onRowClickMainProject, saveCallback }: MainProjectProps) {
  const classes = useStyles();
  const [product, setProduct] = useState<Product[]>([]);
  const [groupList, setGroupList] = useState<Group[]>([]);
  const [data, setData] = useState<MainProject>(INIT_MAIN_PROJECT);

  useEffect(() => {
    setData(main);
  }, [main]);

  useMemo(() => {
    BaseInfoApi.GetProductList()
      .then((res) => {
        setProduct(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        console.log(msg);
      });

    GroupApi.GetGroupList()
      .then((res) => {
        setGroupList(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        console.log(msg);
      });
  }, []);

  const onChangeText = (args: any) => {
    var value = args.target.value;
    setData({ ...data, [args.target.name]: value });
  };

  const onChangeDate = (param: any) => {
    let date = param;
    if (date.toDate) {
      date = date.toDate();
    }
    if (date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let dateString = year + "." + (month < 10 ? "0" + month : month) + "." + (day < 10 ? "0" + day : day);
      setData({ ...data, project_duedate: dateString });
    }
  };

  const onSave = () => {
    let flag = false;

    // validation
    if (data.project_name !== "" && data.product_code !== "") {
      flag = true;
    }

    // 제품과제는 별도의 그룹이 관리하지 않으므로, hq_manager(9)로 고정
    data.project_managergroup_id = 9;

    if (data.project_duedate === "") {
      let date = new Date();
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let dateString = year + "." + month + "." + day;
      setData({ ...data, project_duedate: dateString });
      data.project_duedate = dateString;
      flag = true;
    }

    if (flag) {
      if (data.id !== 0) {
        ProjectApi.UpdateMainProject(data)
          .then(() => {
            onClose && onClose();
            saveCallback && saveCallback();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            console.log(msg);
          });
      } else {
        ProjectApi.AddMainProject(data)
          .then((res) => {
            onRowClickMainProject && onRowClickMainProject(res.data);
            setData(INIT_MAIN_PROJECT);
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            console.log(msg);
          });
      }
    } else {
      alert("빈칸을 모두 채워주세요.");
    }
  };

  return (
    <>
      {templateType === "show" ? (
        <Grid container>
          <Grid item xs={12}>
            <b>제품 정보</b> / <b>과제명 :</b> {main.project_name}, <b>제품명 :</b> {main.product_code}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="project_name"
              name="project_name"
              label="제품 과제명 입력"
              value={data.project_name}
              className={classes.bottom}
              onChange={onChangeText}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <SimpleSelect
              id="product_code"
              name="product_code"
              label="제품명 선택"
              value={data.product_code}
              className={classes.bottom}
              onChange={onChangeText}
            >
              {product.map((item: Product) => {
                return <MenuItem value={item.product_code}>{`${item.product_name}`}</MenuItem>;
              })}
            </SimpleSelect>
          </Grid>
          {/* <Grid item xs={6}>
            <KeyboardDatePicker
              id="project_duedate"
              name="project_duedate"
              format="yyyy.MM.DD"
              label="번역 과제 마감기한 입력"
              value={data.project_duedate || new Date()}
              onChange={onChangeDate}
              className={classes.bottom}
            />
          </Grid> */}
          <Grid className="text-right" item xs={12}>
            <CancelButton onClick={onClose}>CANCEL</CancelButton>
            <SaveButton onClick={onSave}>SAVE</SaveButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default MainProjectTemplate;
