import { Grid } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import MaterialTable from "material-table";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseInfoApi, ErrorHandler, GroupApi } from "system/ApiService";
import { ADMIN_TYPE, TABLEICONS } from "system/Constants";
import { Baselanguage, Group } from "system/types";

function GroupManagementTable(): ReactElement {
  const [data, setData] = useState<Group[]>([]);
  const [baselanguageList, setBaselanguageList] = useState<Baselanguage[]>([]);
  const { t } = useTranslation();
  const baseLangLookup: Object = baselanguageList.reduce((x, y) => ({ ...x, [y.lang_code]: y.lang_name_english }), {});

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaselanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  const onLoad = React.useCallback(() => {
    GroupApi.GetGroupList()
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <MaterialTable
            title=""
            icons={TABLEICONS}
            columns={[
              {
                title: "" + t("group-name"),
                field: "group_name",
                cellStyle: { width: "30%" },
                lookup: ADMIN_TYPE,
              },
              {
                title: "" + t("language-code"),
                field: "trans_lang_code",
                cellStyle: { width: "15%" },
                lookup: baseLangLookup,
              },
              // {
              //   title: "" + t("source-language"),
              //   cellStyle: { width: "15%" },
              //   render: (rowData) => {
              //     return (
              //       <div>
              //         {
              //           // TODO 원본언어 유연하게 설정하도록 수정 필요
              //           // rowData.trans_lang_code === "CN" ||
              //           //rowData.trans_lang_code === "JA" ||
              //           rowData.trans_lang_code === "EN_EN" || rowData.trans_lang_code === "KO"
              //             ? "Korean"
              //             : "English(Common)"
              //         }
              //       </div>
              //     );
              //   },
              // },
              {
                title: "" + t("admin-status"),
                field: "is_admin",
                cellStyle: { width: "20%" },
                lookup: { 1: "true", 0: "false" },
                render: (rowData) => {
                  return <div>{rowData.is_admin === 1 ? "O" : "-"}</div>;
                },
              },
              {
                title: "" + t("translator-status"),
                field: "is_translator",
                cellStyle: { width: "20%" },
                lookup: { 1: "true", 0: "false" },
                render: (rowData) => {
                  return <div>{rowData.is_translator === 1 ? "O" : "-"}</div>;
                },
              },
            ]}
            data={data || []}
            options={{
              filtering: true,
              padding: "dense",
              pageSize: 10,
            }}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default GroupManagementTable;
