import UserProfileTable from "components/UserProfile/UserProfileTable";

function UserProfileMain() {
  return (
    <div>
      <UserProfileTable></UserProfileTable>
    </div>
  );
}

export default UserProfileMain;
