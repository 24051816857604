import { createStyles, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { ActionButton } from "components/Common/Button";
import MaterialTable from "material-table";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseInfoApi, ErrorHandler } from "system/ApiService";
import { TABLEICONS } from "system/Constants";
import { Baselanguage, ModalType } from "system/types";
import BaseLanguageDialog from "./BaseLanguageDialog";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: "right",
      marginLeft: "10px",
    },
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
  })
);

function BaseLanguageTable(): ReactElement {
  const classes = useStyles();
  const [data, setData] = useState<Baselanguage[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState<string>("");
  const [type, setType] = useState<ModalType>("edit");
  const { t } = useTranslation();

  const onLoad = React.useCallback(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onDeleteBaselanguage = (id: string) => {
    if (window.confirm("Are you sure to delete '" + id + "' ?")) {
      BaseInfoApi.DeleteBaselanguage(id)
        .then((res) => {
          onLoad();
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onOpenModal = (id: string) => {
    setId(id);
    setType("edit");
    setIsModalOpen(true);
  };

  const onCloseModal = () => setIsModalOpen(false);

  const goToAddBaselanguage = () => {
    setId("");
    setType("add");
    setIsModalOpen(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <MaterialTable
              title=""
              icons={TABLEICONS}
              columns={[
                {
                  title: "" + t("language-code"),
                  field: "lang_code",
                  cellStyle: { width: "15%" },
                  defaultSort: "asc",
                },
                { title: "" + t("english-name"), field: "lang_name_english", cellStyle: { width: "20%" } },
                { title: "" + t("korean-name"), field: "lang_name_korean", cellStyle: { width: "20%" } },
                // {
                //   title: "" + t("source-language"),
                //   cellStyle: { width: "20px" },
                //   render: (rowData) => {
                //     return (
                //       <div>
                //         {
                //           // TODO 원본언어 유연하게 설정하도록 수정 필요
                //           // rowData.lang_code === "CN" ||
                //           //rowData.lang_code === "JA" ||
                //           rowData.lang_code === "EN_EN" || rowData.lang_code === "KO" ? "Korean" : "English(Common)"
                //         }
                //       </div>
                //     );
                //   },
                // },
                {
                  title: "",
                  field: "",
                  cellStyle: { width: "200px" },
                  render: (rowData) => {
                    return (
                      <div className={classes.buttonGroup}>
                        <IconButton
                          className={classes.iconButton}
                          aria-label="update-baselanguage"
                          onClick={() => onOpenModal(rowData.lang_code)}
                        >
                          <CreateOutlinedIcon />
                        </IconButton>

                        <IconButton
                          className={classes.iconButton}
                          aria-label="delete-baselanguage"
                          onClick={() => onDeleteBaselanguage(rowData.lang_code)}
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      </div>
                    );
                  },
                },
              ]}
              data={data || []}
              actions={[
                {
                  icon: "add",
                  tooltip: "",
                  isFreeAction: true,
                  onClick: (event) => {},
                },
              ]}
              components={{
                Action: (props) => (
                  <ActionButton onClick={goToAddBaselanguage} variant="contained" className={classes.button}>
                    <AddIcon />
                    {t("add-base-language")}
                  </ActionButton>
                ),
              }}
              options={{
                filtering: true,
                padding: "dense",
                pageSize: 10,
                sorting: true,
              }}
            />
            <BaseLanguageDialog
              open={isModalOpen}
              id={id}
              type={type}
              saveCallback={onLoad}
              onClose={onCloseModal}
            ></BaseLanguageDialog>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default BaseLanguageTable;
